.opportunities-survey__success-screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 100%;
  padding: 20px 16px;
}

.opportunities-survey__success-icon {
  margin-bottom: 10px;
}

.opportunities-survey__success-screen h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--ion-color-dark);
}

.opportunities-survey__success-screen ion-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--ion-color-medium-shade);
}

.opportunities-survey__success-footer {
  margin-top: auto;
  width: 100%;
}
