.take-tast-content {
    margin-left: 16px;
    margin-right: 16px;
}
.take-tast-content ion-item{
    --padding-start: 0px;
}
.event-test-info {
  padding-bottom: 16px;
  /* border-bottom: 1px solid var(--ion-color-light-shade); */
}
.event-test-info__title h1 {
  font-weight: bold;
  --color: var(--ion-color-dark);
  margin-bottom: 16px;
}
.event-test-info__text {
  color: var(--ion-color-medium-shade);
}
.event-test-info__text span {
  color: var(--ion-color-dark);
  font-weight: bold;
}
.event-test-info__card {
  border-radius: 8px;
  margin-left: 0px;
  margin-right: 0px;
  /* box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important; */
}
.event-test-info__card__video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.event-test-info__card__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.event-test-info__card__status ion-badge {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-dark);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
}
.event-test-info__card__status ion-badge span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 3px;
}

.event-test-info__card__show img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}
.event-test-info__card__show ion-text {
  font-size: 18px;
  color: var(--ion-color-dark);
}
.event-test-info__card__name {
  margin-top: 10px;
  margin-bottom: 10px;
}
.event-test-info__card__name h2 {
  font-weight: bold;
  color: var(--ion-color-dark);
  font-size: 20px;
}
.event-test-info__test-attemp h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--ion-color-dark);
}
.event-test-info__test-attemp ion-text {
  color: var(--ion-color-medium-shade);
  font-size: 14px;
}

.event-test-info__test-attemp h4 {
  color: var(--ion-color-primary);
  font-weight: bold;
}
.take-test-question{
    padding-top: 15px;
    padding-bottom: 15px;
}
/* .take-test-question__opt1,
.take-test-question__opt2,
.take-test-question__opt3
{
    padding-top: 13px;
    padding-bottom: 13px;
  border-bottom: 1px solid var(--ion-color-light-shade);
  width: 100%;
} */

.take-test-question__submit-btn{
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .event-test-info__test-failed {
    color: #FA3D28;
    font-weight: bold;
  }

  .lay__talent-signup--goldsgym1 .lay__talent-signup--container--goldsgym1 .form-group__label,
  .lay__talent-signup--goldsgym1 .lay__talent-signup--container--goldsgym1 .take-test-question__label {
    color: #ffd600 !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 26px;
    font-family: 'BebasNeue-Regular';
  }