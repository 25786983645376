.add-vaccine_form {
  height: 100%;
}
.add-vaccine {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  /* text-align: center; */
}

.add-vaccine__top-title > h1 {
  font-weight: bold;
  margin: 0px 0px 16px 0px;
  text-align: left;
}

.file_upload_label {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  font-weight: 500;
  margin: 16px 0px 40px 0px;
  text-align: left;
}
.add-vaccine__footer {
  margin-top: auto;
}
.add-vaccine__footer ion-button {
  margin-bottom: 20px;
}
.add-vaccine__file-upload {
  max-width: 400px;
  width: 100%;
  position: relative;
  /* margin-left: 16px; */
}
.add-vaccine__file-upload
  > .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner::after {
  content: url("../../../../assets/images/my-calendar/file-upload.png");
  font-size: 14px;
  position: relative;
  top: 45%;
  color: var(--ion-color-secondary-shade);
}
.add-vaccine__file-upload
  > .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner
  i {
  display: none;
}
.add-vaccine__file-upload
  .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner {
  border: 2px dashed #90a0bc;
}
.add-vaccine__file-upload
  > .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input {
  margin: 0px 0px 0px 10px !important;
}
.add-vaccine__file-upload__camera_img {
  width: 100%;
  height: 351px;
  object-fit: cover;
  text-align: center;
}
.add-vaccine__file-uploa__close_icon {
  position: absolute;
  right:0px;
  top: 0px;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
