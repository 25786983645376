.notification-dashboard-card-view {
  margin: 20px 16px;
  padding: 0px 20px;
  border-radius: 12px;
}
.notification-dashboard-card-view__items {
  --border-color: var(--ion-color-light-tint);
  margin-bottom: 12px;
  --inner-padding-end: 0;
  --padding-start: 0;
}

.notification-dashboard-card-view__label {
  font-weight: bold;
  color: var(--ion-color-dark-shade);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-overflow:    ellipsis;   /* IE, Safari (WebKit) */
  overflow:hidden;              /* don't show excess chars */
  white-space:nowrap;
  width: 250px;
}

.notification-dashboard_selection_icon {
  font-size: 14px;
}
.notification-dashboard-card-view__values {
  color: var(--ion-color-medium-shade);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  width: 100%;
}

.no-border {
  --border-color: white;
}



