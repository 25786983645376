.application-confirm__content{
  height: 100%;
  display: flex;
  align-items: center;
}
.opportunities-survey__application-confirm {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  
  padding: 0px 16px;
  width: 100%;
}

.opportunities-survey__success-icon {
  margin-bottom: 10px;
}

.opportunities-survey__application-confirm h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
  margin-bottom: 10px;
}

.opportunities-survey__application-confirm ion-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color:var(--ion-color-medium-shade);
  margin-bottom: 20px;
}
.opportunities-survey__application-confirm ion-text label{
  font-size: 18px;
  font-weight: 500;
}

.opportunities-survey__application-confirm.opportunities-survey__application-confirm--white h2,
.opportunities-survey__application-confirm.opportunities-survey__application-confirm--white ion-text{
  color:white;
  
}

.opportunities-survey__refer-friend {
  width: 250px;
  --border-radius: 12px;
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
.opportunities-survey__refer-friend-text {
  width: 250px;
}

 .opportunities-survey__view-opportunities {

}

ion-modal.application_confirm{
  --ion-background-color: transparent;
}

.application_confirm-modal-background {
 /* Adjust the path according to your project structure */
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}



ion-modal.application_confirm ion-toolbar {
  --background: transparent;
}

.application_confirm ion-button {
  --border-color: white !important;
  color: white;
}

.application-confirm__content__referral-buttons {
  flex-direction: column;
}