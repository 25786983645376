:root {
	--ion-font-family: var(--baseFontFamily);
}

.onboarding__wrapper {
    padding: 10px;
}



/* form input style :start */

.form-group {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
}
.form-group__label {
  font-size: 14px ;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--ion-color-medium-shade) !important;
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="tel"],
.form-group input[type="number"],
input[type="tel"].form-group__input-styles,
input[type="text"].form-group__input-styles {
  padding: 10px;
  width: 100%;
  color: var(--ion-color-medium-tint);
}

.form-group__input-styles {
  border: 1px solid #ddd;
  border-radius: 12px;
  --padding-start: 20px;
  --padding-end: 6px;
  --placeholder-opacity:0.3;
  /* margin-top: 5px; */
}

.form-group__input-styles-error {
  border: 1px solid red;
  border-radius: 12px;
  --padding-start: 20px;
}
.form-group__checkbox-styles{
  --border-radius: calc(var(--size) * .200);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-medium-tint);
  --size: 21px;
 }

.form-group__button {
	--border-radius:12px;
  --padding-bottom:26px;
  --padding-top:26px;
  margin-left: 0px;
  margin-right: 0px;
}

.form-group__label--required::after {
  content: "*";
  color: var(--ion-color-primary);
}

.form-input-styles-error {
  border: 1px solid red;
  border-radius: 12px;
  --padding-start: 20px;
  --padding-end: 6px;
}
/* form input style :end */

.header-toolbar-title {
  text-align: center !important;
  margin-top: 5px;
}

.section-container {
  padding: 30px 20px;
  height: calc(100% - 67px);
}
/* 
ion-card {
  box-shadow: 1px 2px 4px rgba(240, 240, 240, 0.25), 0px 2px 20px rgba(169, 169, 169, 0.24);
} */

.homepage .tab {
  border: none;
}

.align_header {
  padding: 0;
}

ion-item {
  --border-color: white;
  --highlight-color-focused: #ffffff;
}
.alert-checkbox-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-md{
  white-space: inherit !important;
}
.popover-content.sc-ion-popover-ios,
.alert-wrapper.sc-ion-alert-md,
.alert-wrapper.sc-ion-alert-ios {
	width: 300px !important;
	max-width: 300px !important;
}
.slider-card_spinner,
.centered-spinner {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
	height: 100%;
	align-items: center;
}
.slider-card_spinner ion-spinner {
  width: 70px;
  height: 70px;
}

.assignment-view__action_btn{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  
}
.assignment-view__action_btn ion-button {
  --padding-start: 1.2em;
  --padding-end: 1.2em;
  width: 48%
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}

.orange-border,
.gray-border,
.transparent-white-text-border {
  margin: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  color: #FE6102;
  background-color: #ffeeea;
  font-size: 14px;
}

.orange-border {
  color: #FE6102;
  background-color: #ffeeea;
}

.gray-border {
  color: #242424;
  background-color: #F5F6F8;
}

.transparent-white-text-border {
  color: white;
  background-color: #3C3C3C80;
}

.orange-border__content,
.gray-border__content,
.transparent-white-text-border__content {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
}

p.orange-border__content,
p.gray-border__content,
p.transparent-white-text-border {
  margin-top: 8px;
  margin-bottom: 8px;
}

.plyr:fullscreen.plyr--fullscreen-enabled .plyr__video-wrapper{
  width: 100% !important;
  height: auto !important;
  background: black !important;
  
}

.plyr:fullscreen.plyr--fullscreen-enabled .plyr__video-wrapper video{
  border-radius: 0 !important;
}

.plyr__controls {
  border-radius: 12px !important;
}

.points-background {
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  color: #FE6102;
  background-color: #ffeeea;
}

.selections-required {
  color: #FA3D28;
  margin-left: 7px;
}

.field-required::after {
  content: "*";
  color: var(--ion-color-primary);
  margin-left: 4px;
}

.auto-book-placeholder {
  background: #ffeeea;
  color: var(--ion-color-primary);
  border-radius: 3px;
  --padding-bottom: 6px;
  --padding-end: 6px;
  --padding-start: 6px;
  --padding-top: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: unset;
  column-gap: 3px;
  width: fit-content;
}

.auto-book-placeholder span {
  line-height: 12px;
}

.auto-book-placeholder img {
  height: 13px !important;
  width: auto !important;
}

.orange-border__content--left {
  text-align: left !important;
}

.orange-border ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 20px;
}

.orange-border li:not(:last-child) { 
  margin-bottom: 5px;
}

.talent-intro-video-container {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.talent-intro-video-container video {
  object-fit: fill;
  height: auto;
  max-height: 100%;
  max-width: none;
  border-radius: 12px;
}

@media screen and (max-width: 320px){
  .talent-intro-video-container video {
    min-height: 133px;
    min-width: 120px;
  }
}

@media screen and (max-width: 295px){
  .talent-intro-video-container video {
    min-height: 120px;
    min-width: 108px;
  }
}


.talent-intro-video-container .plyr.plyr--video {
  background: transparent;
} 

.talent-intro-video-container .plyr--video .plyr__video-wrapper {
  background: transparent;
}

.talent-intro-video-container .plyr.plyr--video .plyr__controls {
  background: transparent;
}

.talent-intro-video-container .plyr.plyr--video .plyr__control--overlaid {
  background-color: transparent !important;
}

.plyr--full-ui input[type="range"] {
  color: var(--ion-color-primary);
}

.plyr__control:hover {
  background: var(--ion-color-primary) !important;
}

/**
* Ion Select Options - display the long text properly, not truncated/ partial/ cut
*/ 
.alert-tappable.alert-radio {
  height: auto !important;
  contain: content !important;
}