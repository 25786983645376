.changepwd_view .header_item {
  background-color: var(--ion-color-primary) !important;
  color: white;
  padding: 20px 0px;
}

.changepwd_view .toolbar-container {
  background: var(--ion-color-primary) !important;
  color: white;
}

.create-password__guidelines {
  margin-top: 10px;
}
.password__guideline {
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-medium);
  --padding-bottom: 7px;
  --padding-end: 7px;
  --padding-start: 7px;
  --padding-top: 7px;
  margin: 3px 10px;
}

.password_view {
  padding: 0px 20px;
}