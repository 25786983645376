.assign-recap-success__header__back {
  color: black;
}

.assign-recap-success__header {
  background-color: var(--ion-color-primary);
}

.assign-recap-success-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.assign-recap-success-container__title {
  font-family: var(--baseFontFamily);
  font-size: 33px;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin: 20px 20px;
 }

.assign-recap-success-container__card-container {
  text-align: center;
  border: 1px solid #d1d1d1b8;
  border-radius: 20px;
  padding: 25px 10px;
  margin: 20px 20px;
}

.assign-recap-success-container__sub-title {
  font-size: 15px;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
}

.assign-recap-success-container__rating {
  margin-top: 20px;
}

.assign-recap-success-container__fastart-icon {
  font-size: 20px;
  margin: 0px;
  margin-right: 5px;
  zoom: 1.5;
  color: var(--ion-color-primary) !important;
}

.assign-recap-success-container__rating-highlight {
  color: var(--ion-color-primary) !important;
}

.assign-section-footer {
  margin-top: auto;
  padding: 0px 20px 40px 20px;
}