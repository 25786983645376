.markets-sec {
  padding: 20px 16px;
  position: relative;
  overflow-y:auto;
  height: 100%;
}

.markets-sec__miles {
  color: var(--ion-color-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  padding: 0;
}
.markets-sec__miles_range {
  --knob-background: var(--ion-color-primary);
  --bar-border-radius: 6px;
  --bar-height: 8px;
  --bar-background: #fe61024a;
  margin: 0px;
  padding: 0px;
}
.markets-sec__submit-btn {
  position: absolute;
  bottom: 20px;
  left: 16px;
  right: 16px;
}
