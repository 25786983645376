.refer-friend {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.refer-friend__top-text{
 text-align: center;
 margin-top: 40px;
}
.refer-friend__top-text ion-label{
    font-size: 16px;
    font-weight: bold;
    color: var(--ion-color-primary);
}
.refer-friend__image{
    display: flex;
  justify-content: center;
  margin-top: 40px;
}
.refer-friend__title {
    text-align: center;
  }
  
  .refer-friend__title h1 {
    font-weight: bold;
    color: var(--ion-color-dark);
  }
  .refer-friend__description{
    text-align: center;
    font-size: 0.9rem;
    color: var(--ion-color-medium-shade);
    margin: 20px 15px;
    line-height: 22px;
  }
  .refer-friend__buttons {
    bottom: 10px;
    position: absolute;
    width: 100%;
    left: 0px;
  }
  
  .refer-friend__buttons ion-button {
    margin: 0px 20px 10px 20px;
  }
  
  /* iPhone 5 Retina regardless of IOS version */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .refer-friend__buttons {
      bottom: 0px;
      position: static;
      width: 100%;
    }
  }
  