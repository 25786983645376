.fileuploader-theme-avatar {
	position: relative;
	width: 250px;
	height: 250px;
	padding: 0;
	margin: 0;
	background: none;
}

.fileuploader-theme-avatar .fileuploader-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
	overflow: hidden;
}
.fileuploader-theme-avatar .fileuploader-wrapper,
.fileuploader-theme-avatar .fileuploader-items .fileuploader-item .fileuploader-item-image {
	background: #f9f9fc;
	width: 250px;
	height: 250px;
}

.fileuploader-theme-avatar .fileuploader-items {
	height: 100%;
}
.fileuploader-theme-avatar .fileuploader-items .fileuploader-item {
	height: 100%;
	padding: 0;
	margin: 0;
}
.fileuploader-theme-avatar .fileuploader-items .fileuploader-item.is-image-waiting .fileuploader-item-image img {
	display: none;
}

.fileuploader-theme-avatar .fileuploader-droparea {
	position: absolute;
	display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(43, 56, 71, 0.6);
    border-radius: 50%;
    cursor: pointer;
	transition: all 0.3s ease;
	
	z-index: -1;
	opacity: 0;
	transform: scale(1.3);
}
.fileuploader-theme-avatar .fileuploader-droparea .fileuploader-icon-main {
	font-size: 32px;
    color: #fff;
}
.fileuploader-theme-avatar .fileuploader-dragging .fileuploader-droparea {
	opacity: 1;
	transform: scale(1);
	z-index: 1;
}

.fileuploader-theme-avatar .progressbar3 {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fileuploader-theme-avatar .progressbar3 span {
	position: relative;
    display: inline-block;
	font-size: 16px;
    font-weight: 700;
    color: #1f344a;
	z-index: 1;
}
.fileuploader-theme-avatar .progressbar3 [data-action] {
	cursor: pointer;
}
.fileuploader-theme-avatar .progressbar3 svg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.fileuploader-theme-avatar .progressbar3 svg circle {
	stroke: #5b7bfe;
    stroke-width: 4px;
    fill: transparent;
	transition: 0.3s stroke-dashoffset, 0.3s transform;
	transform: rotate(-90deg);
  	transform-origin: 50% 50%;
}
.fileuploader-theme-avatar .progressbar3.is-reset svg circle {
	transition: none;
}
.fileuploader-theme-avatar .progressbar3 svg .progress-dash {
	stroke: #dae2fd;
}
.fileuploader-theme-avatar .fileuploader-item.upload-complete .progressbar3 svg {
	transform: scale(0.5);
}
.fileuploader-theme-avatar .fileuploader-item.upload-complete .progressbar3 span {
	font-size: 16px;
	color: #fff;
}
.fileuploader-theme-avatar .fileuploader-item.upload-complete .progressbar3 svg .progress-dash {
	stroke-width: 0;
    transform: scale(1.3);
}
.fileuploader-theme-avatar .fileuploader-item.upload-successful .progressbar3 svg circle {
	stroke: #43D084;
	fill: #43D084;
}
.fileuploader-theme-avatar .fileuploader-item.upload-successful .progressbar3 svg .progress-dash {
    fill: rgba(67, 208, 132, 0.6);
}
.fileuploader-theme-avatar .fileuploader-item.upload-failed .progressbar3 svg circle {
	stroke: #FE7676;
	fill: #FE7676;
}
.fileuploader-theme-avatar .fileuploader-item.upload-failed .progressbar3 svg .progress-dash {
    fill: rgba(254, 118, 118, 0.6);
}

.fileuploader-theme-avatar .fileuploader-menu {
	position: relative;
z-index: 10;
background-color: rgba(0, 0, 0, 0.5);
padding: 10px;
  padding-top: 10px;
padding-top: 10px;
padding-top: 8px;
width: 223px;
height: 79px;
border-radius: 100% 0% 48% 51% / 0% 0% 100% 100%;
border-top: 0;
font-size: 12px;
font-weight: bold;
bottom: 82px;
left: 14px;
cursor: pointer;
text-align: center;
box-sizing: border-box;
}

.fileuploader-theme-avatar .fileuploader-menu .fileuploader-menu-open {
	background-color: transparent;
	background: transparent;
	border-color: transparent;
}

.fileuploader-theme-avatar .fileuploader-menu .fileuploader-icon-menu::before {
	content: "Edit";
	font-family: "Open Sans Regular", sans-serif !important;
	
	/* background-color: rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.5); */
	color: #ffffff;
}

.fileuploader-theme-avatar .fileuploader-menu-open {
	padding: 2px 4px;
	border-radius: 4px;
	background: #fff;
	color: #5b7bfe;
	font-size: 20px;
	margin-top: 20px;
	line-height: 0;
	/* box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2); */
	cursor: pointer;
}
.fileuploader-theme-avatar .fileuploader-menu ul {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background: #fff;
	list-style: none;
	margin: 0;
	padding: 0;
	margin-top: -1px;
	box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 4px 4px;
}
.fileuploader-theme-avatar .fileuploader-menu.is-shown .fileuploader-menu-open {
	transform: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.fileuploader-theme-avatar .fileuploader-menu.is-shown ul {
	display: block;
}
.fileuploader-theme-avatar .fileuploader-menu ul li {
	margin: 0;
	padding: 0;
}
.fileuploader-theme-avatar .fileuploader-menu ul li a {
	display: block;
	padding: 10px 12px;
	min-width: 120px;
	color: #1f344a;
	cursor: pointer;
	text-decoration: none;
}
.fileuploader-theme-avatar .fileuploader-menu ul li a i {
	margin-right: 6px;
}
.fileuploader-theme-avatar .fileuploader-menu ul li a:hover {
	background: rgba(0, 0, 0, 0.03)
}
.fileuploader-theme-avatar .fileuploader-menu ul li a:active {
	background: rgba(0, 0, 0, 0.05)
}

.fileuploader-theme-avatar:hover .fileuploader-menu {
	transform: translateY(0);
	opacity: 1;
}

.fileuploader-popup-preview.is-for-avatar .fileuploader-cropper .fileuploader-cropper-area .area-image,
.fileuploader-popup-preview.is-for-avatar .fileuploader-cropper .fileuploader-cropper-area .area-move:after {
	border-radius: 50%;
}

.fileuploader {
	/* width: 160px !important;
	height: 160px !important; */
}

.fileuploader-theme-avatar .fileuploader-items .fileuploader-item .fileuploader-item-image canvas, 
.fileuploader-theme-avatar .fileuploader-items .fileuploader-item .fileuploader-item-image img {
	width: unset !important;
	height: unset !important;
	border-radius: 50%;
}

.fileuploader-items .fileuploader-item .fileuploader-item-icon i, .fileuploader-items .fileuploader-item .fileuploader-item-image canvas, .fileuploader-items .fileuploader-item .fileuploader-item-image img, .fileuploader-items .fileuploader-item .fileuploader-item-image.fileuploader-loading:after, .fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area .point:after, .fileuploader-popup .fileuploader-popup-move:after, .fileuploader-popup.loading:after {
    left: 50% !important;
    top: 50% !important;
}

.fileuploader-theme-avatar .fileuploader-items .fileuploader-item.is-image-waiting .fileuploader-item-image img {
    display: block !important;
}

.fileuploader-theme-avatar .fileuploader-wrapper, .fileuploader-theme-avatar .fileuploader-items .fileuploader-item .fileuploader-item-image {
	display: block !important;
}


  