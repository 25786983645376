.delete-account-container {
  padding: 0px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.delete-account-container_back_btn{
  --color:black !important;
}
.delete-account-container__title {
  color: #FA3D28;
  font-size: 26px;
  font-weight: 600;
  margin: 0px 0px 10px 0px;
}



.delete-account-container__content {
  color: var(--ion-color-medium-tint);
  line-height: 24px;
  text-align: left;
  margin: 5px;
  font-size: 16px;
}



.delete-account-container__txt-area {
  margin: 20px 0px;
}

.delete-account__btn-submit {
  margin-top: auto;
}