.setup-account-card {
  padding: 0px;
  margin: 0px;
}
.setup-account-card__title {
  --color: var(--ion-color-dark);
  font-size: 21px;
  font-weight: bold;
  margin: 0px;
}
.setup-account__content__list {
  padding: 5px;
  margin: 10px 0px;
  border-radius: 12px;
}

.account_steup_btn {
  background: #fff0e6;
  color: var(--ion-color-primary);
  padding: 5px 8px;
  border-radius: 6px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 44px;
}

.account_steup_btn_active {
  cursor: default;
  pointer-events: none;
  background: var(--ion-color-primary);
  color: white;
}
.setup-account__content__list .btn_account_steup__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.account_steup__btn_text{
  font-size: 16px;
  text-align: left;
  line-height: 35px;

}
.setup-account__content__list ion-grid {
  margin: 0px;
  padding: 0px;
}
.account_steup__icon_left {
  font-size: 20px;
  margin-right: 8px;
  margin-top: 8px;
}
.account_steup__icon_right {
  font-size: 16px;
  margin-left: auto;
  margin-right: 8px;
  margin-top: 8px;
}
@media screen and (max-width: 320px){
  .account_steup__btn_text{
    font-size: 14px;
    text-align: left;
   line-height: 1;
   margin-top: 8px;
  }
  .account_steup__icon_right {
    margin-top: 10px;
  }
}
@media screen and (max-width: 290px){
  .account_steup__btn_text{
   margin-top: 5px;
  }
  .extra_margin_top{
    margin-top: 9px;
  }
 
}

.account_steup__icon__p-card {
  width: 20px;
  height: 20px;
}