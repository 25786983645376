.become-model {
  display: flex;
  flex-direction: column;
  min-height: 80%;
  justify-content: center;
}
.become-model__image {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.become-model__title {
  text-align: center;
}

.become-model__title h1 {
  font-weight: bold;
  line-height: 1.5;
  color: var(--ion-color-dark);
} 
.become-model__title h1 span {
  color: var(--ion-color-primary);
}
.become-model__description {
  text-align: center;
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  margin: 20px 15px;
  line-height: 22px;
}
.become-model__description span {
  font-weight: bold;
  color: var(--ion-color-medium);
}
.become-model__additional-info-text > p {
  text-align: center;
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: bold;
}
.become-model__additional-info {
  text-align: center;
}

.become-model__additional-info ul {
  list-style: none;
  display: inline-block;
  text-align: left;
}
.become-model__additional-info li {
  margin-bottom: 10px;
  color: var(--ion-color-dark);
}

.become-model__additional-info img {
  margin-right: 10px;
}

.become-model__buttons {
  margin-bottom: 10px;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.become-model__buttons>ion-button{
  margin: 0px 20px 20px 20px;
}

.model-form__formion-card{
  margin-left: 0px;
}
.model-form__form{
  margin: 0px 25px;
  height: 100%;
}

.model-form__align{
  text-align: left;
  margin: 0px;
}
.model-form__form .form-group__label{
  margin-bottom: 12px;
  color: var(--ion-color-medium-shade);
}
.model-form__form ion-card{
  margin: 10px 0px;
  
}
.model-form__form ion-button{
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.model-form__section-1,
.model-form__section-2,
.model-form__section-3,
.model-form__section-4{
  display: grid;
  height: 100%;
}
.model-form__next--btn{
  margin-top: auto;
  /* padding: 20px 10px; */
}

.model-form__next--btn .form-group__button {
    /* width: 100%;
    background-color: var(--ion-color-primary);
    color: white;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px; */
}
.model-form__section-1 ion-card,
.model-form__section-4 ion-card{
  margin-left: 0px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid rgba(20, 0, 0, 0.12);
  border-radius: 12px;
  margin-bottom: 9px;
  width: 100%;
}
.model-form-form__card__label{
  font-size: 16px ;
  font-weight: 500;
  color: var(--ion-color-medium-shade) !important;
}
.model-form__checkbox--label{
  font-weight: bold;
  color: var(--ion-color-warning-contrast) !important;
}

.model-form__footer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.model-form__footer::before{
  background-image: none !important;
}