/* bottom buttons */
ion-tab-bar {
    display: none;
}

/* lay__ = layout */
.lay__logo {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 30px;
}

/* left top */
.lay__lt {
    position: fixed;
    top: 90px;
    left: 0px;
}

.lay__lt img {
    height: 130px;
}

/* right top */
.lay__rt {
    position: fixed;
    top: 20px;
    right: 0px;
}

.lay__rt img {
    height: 130px;
}


.lay__bottom-bar {
    position: fixed;
    bottom: 5px;
    left: 0px;
    width: 100%;
}

.lay__bottom-bar img {
    height: 11px;
    width: 100%;
}

/* left bottom */
.lay__lb {
    position: fixed;
    bottom: -5px;
    left: 0px;
    z-index: 999;
}

.lay__lb img {
    height: 80px;
}

/* center bottom*/
.lay__cb {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.lay__cb img {
    height: 230px;
}

/* right bottom*/
.lay__rb {
    position: fixed;
    bottom: -5px;
    right: 0px;
    z-index: 999;
}

.lay__rb img {
    height: 130px;
}

.lay__talent-signup {
    border: 2px solid white;
    border-radius: 10px;
    margin: 10px;
    height: auto;
    min-height: calc(100% - 20px);
    padding-left: 25px;
    padding-right: 25px;
}

.lay__talent-signup--container--sparklingice1 {
    --background: #58C5CC;
    color: white;
    font-family: 'Alt Gothic ATF';
}

.lay__talent-signup--container--sparklingice1.talent-signup-step-2 {
    --background: #fbb218;
}

.lay__talent-signup--container--sparklingice1.step-share-selfie {
    --background: #fbb218;
}

.lay__talent-signup--container--sparklingice1.step-upload-photo {
    --background: #1fc19a;
}

.lay__talent-signup--container--sparklingice1 .talent-signup h1 {
    color: white;
}


.lay__talent-signup--container--sparklingice1 ion-button.actionlink__cta,
.lay__talent-signup--container--sparklingice1 ion-button.actionlink__cta:hover {
    text-transform: none;
    --background: none !important;
    --background-hover: none !important;
    --background-focused: none !important;
    --background-activated: none !important;
    color: #ED0E69;
    font-family: 'Alt Gothic ATF';
    text-decoration: underline;
}

.lay__talent-signup--container--sparklingice1 .lay__logo {
    color: white;
    height: 18px;
    top: 2px;
}

.lay__talent-signup--container--sparklingice1 .form-group__label,
.lay__talent-signup--container--sparklingice1 .take-test-question__label {
    color: white !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 26px;
    font-family: 'BebasNeue-Regular';
}

.lay__talent-signup--container--sparklingice1 .form-group__input-styles,
.lay__talent-signup--container--sparklingice1 .login-input-styles-error,
.lay__talent-signup--container--sparklingice1 .form-input-styles-error {
    border-radius: 0px;
    background-color: #58C5CC;
}

.lay__talent-signup--container--sparklingice1.talent-signup-step-2 .form-group__input-styles,
.lay__talent-signup--container--sparklingice1.talent-signup-step-2 .login-input-styles-error,
.lay__talent-signup--container--sparklingice1.talent-signup-step-2 .form-input-styles-error {
    background-color: #fbb218;
}


.lay__talent-signup--container--sparklingice1 .sn__content2 {
    color: #ED0E69;
    text-transform: uppercase;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 30px;
}

.lay__talent-signup--container--sparklingice1 ion-button,
.lay__talent-signup--container--sparklingice1 ion-button:hover {
    --background: #ED0E69 !important;
    --background-hover: #ED0E69 !important;
    --background-focused: #ED0E69 !important;
    --background-activated: #ED0E69 !important;
    width: 200px;
    /* margin: 0 auto; */
    margin-left: auto;
    margin-right: auto;
    font-family: 'BebasNeue-Regular';
    font-size: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    letter-spacing: 2px;
    --border-radius: 7px;
}

.lay__talent-signup--container--sparklingice1 .error-message {
    color: #ED0E69;
}

.lay__talent-signup--container--sparklingice1 a {
    color: white;
    text-decoration: underline;
}

.lay__talent-signup--container--sparklingice1 .form-group input[type="text"],
.lay__talent-signup--container--sparklingice1 .form-group input[type="password"],
.lay__talent-signup--container--sparklingice1 .form-group input[type="tel"],
.lay__talent-signup--container--sparklingice1 .form-group input[type="number"],
.lay__talent-signup--container--sparklingice1 input[type="tel"].form-group__input-styles,
.lay__talent-signup--container--sparklingice1i nput[type="text"].form-group__input-styles {
    color: white;
}

.lay__talent-signup--container--sparklingice1 svg.checkbox-icon {
    border-color: #ED0E69 !important;
    background: #ED0E69 !important;
}

.lay__talent-signup--container--sparklingice1 ion-checkbox {
    --background: #58C5CC;
    --border-color: white;
    --background-checked: #ED0E69 !important;
    --border-color-checked: #ED0E69 !important;
}

.lay__talent-signup--container--sparklingice1 .verification-code__mobile-number {
    color: #ED0E69;
}

.lay__talent-signup--container--sparklingice1 .verification-code__option {
    color: #ED0E69;
}

.lay__talent-signup--container--sparklingice1 .verification-code__option img {
    display: none;
}

.lay__talent-signup--container--sparklingice1 .take-test-question {
    border-top: 0px;
}

.lay__talent-signup--container--sparklingice1 .take-test-question .form-group__input-styles {
    border: 1px solid #ED0E69;
}

.lay__talent-signup--container--sparklingice1 .take-test-question .take-test-question__label {
    font-family: 'Alt Gothic ATF';
    font-size: 22px;
    text-transform: unset;
    z-index: 10;
    position: relative;
}

.lay__talent-signup--sparklingice1.step-upload-photo--final-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.lay__talent-signup--container--sparklingice1 .section-footer,
.lay__talent-signup--container--sparklingice1 .take-tast-content {
    z-index: 10;
}

.lay__talent-signup--container--sparklingice1 .mobile__terms {
    flex-direction: column;
    align-items: center;
}

.lay__talent-signup--container--sparklingice1 .mobile__terms ion-label {
    color: white;
    padding-left: 0px;
    font-size: 17px;
    text-align: justify;
}

.lay__talent-signup--container--sparklingice1 .mobile__terms ion-checkbox {
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.lay__talent-signup--container--sparklingice1 ion-button.upload-photo__submit-btn,
.lay__talent-signup--container--sparklingice1 ion-button.upload-photo__submit-btn:hover {
    --background: #ece614 !important;
    --background-hover: #ece614 !important;
    --background-focused: #ece614 !important;
    --background-activated: #ece614 !important;
    color: #1fc19a;
}

.lay__talent-signup--container--sparklingice1 .image-received {
    color: #ece614;
    font-size: 40px;
}

.lay__talent-signup--container--sparklingice1 .talent-signup__section-title {
    max-width: 300px;
    margin: 0 auto;
}

/* sn__ = signup */
.sn__id1 {
    text-align: center;

    h1 {
        font-size: 16px;
    }
}

.fileuploader--sparklingice .fileuploader-thumbnails-input-inner {
    background: none !important;
    border: 0px !important;
    opacity: 1 !important;
}

.fileuploader-theme-thumbnails .fileuploader--sparklingice .fileuploader-items-list .fileuploader-item-image {
    background: none !important;
}

.fileuploader-theme-thumbnails .fileuploader--sparklingice.fileuploader-items .fileuploader-item-inner {
    background: none !important;
}

/* .fileuploader-theme-thumbnails .fileuploader-items .fileuploader-item-inner {
    background: none !important;
} */

.fileuploader-theme-thumbnails {
    margin-bottom: 0px !important;
}

.fileuploader-theme-thumbnails .fileuploader--sparklingice .fileuploader-thumbnails-input {
    margin-top: 0px;
}

#psup__form {
    /* w/o this, on wider resolutions the submit button overlaps the top content */
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.consumer-uploaded-photo-container {
    display: flex;
    justify-content: center;
    width: 247px;
    margin-left: auto;
    margin-right: auto;
}

.consumer-uploaded-photo {
    position: absolute;
    /* transform: rotate(4deg); */
    margin-top: 47px;
    object-fit: cover;
    object-position: top;
    width: 209px;
    max-height: 233px;
    margin-left: -7px;
}