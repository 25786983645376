.opportunity-tabs {
  display: flex;
  column-gap: 6px;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 2px 3px -1px rgba(169, 169, 169, 0.24);
  margin: 0px;
  padding: 0px;
}
.opportunity-tabs__tab {
  color: var(--ion-color-medium-tint);
  text-transform: capitalize;
}
.opportunity-tabs__active {
  color: var(--ion-color-primary) !important;
  border-bottom: 2px solid var(--ion-color-primary);
  font-weight: bold;
  font-size: 15px;
}
.opportunity-content {
  margin: 20px 16px;
  justify-content: center;
}
.opportunity__filters {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.opportunity__filter {
  display: flex;
  flex-direction: row;
  border: 1.5px solid var(--ion-color-light-shade);
  border-radius: 8px;
  width: 100%;
  /* margin-right: 10px; */
}
.opportunity__filter__icon {
  font-size: 19px;
  margin-top: 9px;
  margin-right: 4px;
  margin-left: 7px;
  color: var(--ion-color-dark-tint);
}
.opportunity__filter__lable {
  margin-top: 7px;
  color: var(--ion-color-dark-tint);
}

.opportunity__filter__select {
  width: 60%;
  color: var(--ion-color-dark-tint);
  --padding-start: 5px;
  --padding-top: 8px;
  --padding-bottom: 8px;
}

.opportunity__filter__btn {
  color: var(--ion-color-dark-tint);
  border: 1.5px solid var(--ion-color-light-shade);
  --padding-start: 7px;
  --padding-end: 7px;
  border-radius: 8px;
  height: 39px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.opportunity__event-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .opportunity__event-list {
    display: inline-grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;
    grid-row-gap: 45px;
    grid-column-gap: 23px;
  }
  .opportunity__filter {
    width: 100%;
  }
}
