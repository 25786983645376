.reward-categories-container {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.my_reward_list {
  padding: 20px 16px;
}
.my_reward_list__card {
  max-width:400px;
  width: 100%;
  height: 100%;
  min-height: 300px;
  border-radius: 12px;
  margin: 0px 0px 20px 0px;
  position: relative;
  display: flex;
}

.my_reward_list__card_badge {
  background-color: var(--ion-color-primary);
  height: 28px;
  /* width: 110px; */
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  color: var(--ion-color-primary-contrast);
  line-height: 16px;
  font-size: 13px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
}

.my_reward_list__card_left {
    line-height: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    column-gap: 5px;
    color: black;
    z-index: 20;
}

.my_reward_list__card_featured_brand {
  line-height: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  column-gap: 5px;
  color: var(--ion-color-primary);
  z-index: 20;
}

.my_reward_list__card_extra {
  position: absolute;
  bottom: 17px;
  left: 10px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.my_reward_list__card_featured_brand img {
  width: 14px;
}

.my_reward_list__card_img__sold-out {
  position: absolute;
  top: 35%;
  left: 0;
  pointer-events: none; /* Prevents the overlay from blocking interactions with the product */
}

.my_reward_list__card_left img {
  width: 14px;
}

.my_reward_list__card_img-container--sold-out > *:not(.my_reward_list__card_img__sold-out) {
  opacity: 0.5 !important;
}

.my_reward_list__card--sold-out .my_reward_list__card_content {
  opacity: 0.5 !important;
}

.my_reward_list__card_favorite {
  height: 28px;
  /* width: 110px; */
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  color: var(--ion-color-primary-contrast);
  line-height: 16px;
  font-size: 13px;
  position: absolute;
  z-index: 20;
}

.my_reward_list__card_favorite {
    top: 10px;
    right: 10px;
}


.my_reward_list__card_text--small-width {
  display: none;
}

.my_reward_list__card_text--normal {
  display: inline;
}
  
@media screen and (max-width: 320px) {
  .my_reward_list__card_badge {
    height: 22px;
    padding: 4px 4px 4px 4px;
  }

  .my_reward_list__card_favorite {
    height: 22px;
    padding: 4px 4px 4px 4px;
  }

    
  .my_reward_list__card_text--small-width {
    display: inline;
  }

  .my_reward_list__card_text--normal {
    display: none;
  }

}

.my_reward_list__card > * {
  flex: 1;
}

.my_reward_list__card_img-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1.7;
  min-width: 60%;
}

.my_reward_list__card_img {
  max-width: 100%;
  max-height: 100%;
}

.my_reward_list__card_img__parent {
  width: 70%;
  height: 70%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.my_reward_list__card_content__msrp {
  color: black;
  font-weight: bold !important;
}

.my_reward_list__card_content__attributes {
  margin-bottom: 10px;
}


.my_reward_list__card_content__attributes__color-radio {
  border-color: #0064D9;
}

.my_reward_list__card_title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: var(--ion-color-dark);
  margin-bottom: 5px;
}
.my_reward_list__card_lesser_title {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: var(--ion-color-dark);
  margin-bottom: 4px;
}
.my_reward_list__card_text {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--ion-color-medium-shade);
}




.my_reward_list__card_btn {
  --border-radius:12px;
  --padding-bottom:7px;
  --padding-top:7px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 14px;
}
/* RedeemRewardModal css start  */

/* ClaimRewardSuccessModal css :start  */

/* ClaimRewardSuccessModal css :end  */
.redeem_reward_modal {

}
.redeem_reward_modal__toolbar {
  
}
.redeem_reward_modal__toolbar h1 {
  color: var(--ion-color-primary-contrast);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-top: 33px;
  position: relative;
  text-align: center;
}
.redeem_reward_modal__toolbar ion-icon {
  color: var(--ion-color-primary-contrast);
  font-size: 22px;
  position: relative;
  top: -12px;
  right: 5px;
}
.redeem_reward__content {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.redeem_reward_modal .redeem_reward__content__img {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.redeem_reward__name {
  margin-top: 15px;
}
.redeem_reward__address,
.redeem_reward__email,
.redeem_reward__name,
.redeem_reward_modal .my_reward_list__card_content__attributes__attribute {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  padding-left: 20px;
}

.redeem_reward__address ion-label,
.redeem_reward__email ion-label,
.redeem_reward__name ion-label,
.redeem_reward__attributes ion-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-color-medium-shade) !important;
  width: 100%;
  margin-bottom: 5px;
}
.redeem_reward__address ion-text,
.redeem_reward__email ion-text,
.redeem_reward__attributes ion-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
}
.redeem_reward__edit img {
  margin-left: 5px;
}
.redeem_reward__submit {
  margin-top: auto;
  --border-radius: 12px;
  --padding-bottom: 26px;
  --padding-top: 26px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.edit_info__container{
  padding: 20px 16px;
  height: 100%;
}
.edit_info__container > form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.edit_info__container__btn{
  margin-top: auto;
}
/* RedeemRewardModal css end  */

/* ClaimRewardSuccessModal css :start  */
.claim_reward_modal {
  --width: 350px;
  --height: 300px;
  --border-radius: 12px;
 
}
.claim_reward_content {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/common/claim_reward_success.png"),
  linear-gradient(var(--ion-color-primary), var(--ion-color-primary));
}
.claim_reward_content >ion-icon{
  font-size: 22px;
  color: var(--ion-color-primary-contrast);
  float: right;
  position: relative;
  top: 10px;
  right: 10px;
}
.claim_reward_content__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.claim_reward_content__body img {
  height: 80px;
  width: 80px;
  margin: auto;
  margin-top: 33px;
}
.claim_reward_content__body h1 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: var(--ion-color-primary-contrast);
}
.claim_reward_content__body ion-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
  padding: 10px;
}
/* ClaimRewardSuccessModal css :end  */
.claimed_reward_list{
  height: auto;
}

.my_reward_list__card_content__attributes__color__item__type {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.redeem_reward_modal .my_reward_list__card_content__attributes__color__item__type {
  width: 30px;
  height: 30px;
}

.my_reward_list__card_content__attributes__color {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 5px;
  column-gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.redeem_reward_modal .my_reward_list__card_content__attributes__color {
  margin-top: 5px;
}

.my_reward_list__card_content__attributes__color__item {
  text-align: center;
}

.my_reward_list__card_content__attributes__color__item__type__checked img {
  position: relative;
  z-index: 99;
  top: 1px;
} 

.my_reward_list__card_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 40%;
  padding-left: 0px;
}


.my_reward_list__card_content__attributes__value__item__type,
.my_reward_list__card_content__attributes__value__item__type--checked  {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  width: 100%;
}

.my_reward_list__card_content__attributes__value__item__type--checked {
  background-color: var(--ion-color-primary);
  color: white;
  border: 1px solid var(--ion-color-primary) !important;
}

.my_reward_list__card_content__attributes__value {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 5px;
  column-gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.my_reward_list .my_reward_list__card_content__attributes__value__item__type {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.redeem_reward_modal .my_reward_list__card_content__attributes__value__item__type,
.redeem_reward_modal .my_reward_list__card_content__attributes__value__item__type--checked {
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  line-height: 30px;
}

.redeem_reward_modal .my_reward_list__card_content__attributes__value,
.redeem_reward_modal .my_reward_list__card_content__attributes__color {
  column-gap: 10px;
  row-gap: 10px;
  padding-right: 20px;
}

.redeem_reward_modal .my_reward_list__card_content__attributes__value,
.redeem_reward_modal .my_reward_list__card_content__attributes__color {
  margin-top: 5px;
  margin-bottom: 0;
}

.my_reward_list__card_content__attributes__value__item {
  min-width: 30px;
  text-align: center;
  width: 100%;
}

@media (max-width: 375px) {
  .my_reward_list__card_content {
    padding: 5px;
    padding-left: 0px;
  }
}

.redeem_reward__content .my_reward_list__card_content__attributes {
  margin-bottom: 0px;
}

.redeem_reward__name {
  padding-bottom: 0px;
}

.my_reward_list__card_content__attributes__select {
  width: 100%;
}


/* ################## */
