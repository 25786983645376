.scoreboard_container {
  padding: 20px 16px;
  margin: auto;
}

.scoreboard_status {
  max-width: 400px;
  border-radius: 12px;
  margin: auto;
}
.scoreboard_status ion-card {
  border-radius: 12px;
  margin: 0px 0px 10px 0px;
}

.scoreboard_status_title {
  font-weight: 600;
  font-size: 18px;
  margin-right: 5px;
  color: var(--ion-color-dark);
}
.scoreboard_status_title ion-icon {
  position: relative;
  top: 3px;
  margin-left: 6px;
}
.scoreboard_status_interval {
  font-size: 13px;
  color: var(--ion-color-medium-shade);
}
.scoreboard_status__info_icon {
  font-size: 22px;
}
.scoreboard_status__golden_badge {
  float: right;
  height: 60px;
  width: 60px;
}

.scoreboard_status__elite_badge {
  width: 40px;
height: 40px;
margin-right: 5px;
}

.scoreboard_container__elite-perks {
  border-radius: 12px;
margin: auto;
}

.scoreboard_container__elite-perks ion-card {
  border-radius: 12px;
  margin: 0px 0px 10px 0px;
}


.scoreboard_container__elite-perks__label {
  display: flex;
  align-items: center;
  align-content: center;
}

.scoreboard_container__elite-perks__learn-more {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  color: var(--ion-color-primary);
}

.scoreboard_container__elite-perks__learn-more__extend {
  margin-left: 5px;
  width: 13px;
  height: 13px;
}

.scoreboard_container__elite-perks__learn-more__collapse {
  width: 20px;
  height: 20px;
}

.scoreboard_container__elite-perks__learn-more {
  line-height: 13px;
}

.scoreboard_container__elite-perks__details__icon { 
  width: 10px;
  height: 10px;
}

.scoreboard_container__elite-perks__details__perk span {
  color: var(--ion-color-dark);
}


.scoreboard_container__elite-perks__details__perk {
  margin-top: 5px;
}
.scoreboard_status_progressbar {
  position: relative;
  display: flex;
  justify-content: center;
}
.scoreboard_status_progressbar__percentage {
  position: absolute;
  top: 43px;
  font-size: 26px;
  font-weight: 700;
  color: var(--ion-color-dark);
}
.scoreboard_status_progressbar__elite {
  background-color: var(--ion-color-primary);
  color: white;
  height: 25px;
  padding: 3px 10px;
  width: 70px;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  top: 140px;
  font-size: 13px;
  margin-left: 100px !important;
}
svg path {
  will-change: auto;
  stroke-width: 12px;
  stroke-miterlimit: round;
  transition: stroke-dashoffset 850ms ease-in-out;
}

path.white {
  stroke-linecap: round;
  stroke-width: 6;
  stroke: #ffffff;
  stroke-dasharray: 0 350;
  stroke-dashoffset: 350;
  stroke-width: 10px;

  animation: dash 5s linear forwards;
}

.scoreboard_status__talent_badge {
  border-top: 1px solid var(--ion-color-light-tint);
}
.scoreboard_status__talent_badge img {
  float: left;
}
.about_talent_grid {
  max-width: 400px;
  margin: 10px auto;
}
.about_talent_grid > ion-grid {
  margin: 0;
  padding: 0;
}
.about_talent_grid_col_left {
  padding-left: 0px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.about_talent_grid_col_right {
  padding-right: 0px;
  padding-left: 8px;
  padding-bottom: 8px;
}
.performance_card__grid {
  padding-top: 10px;
}
.performance_card {
  margin: 0;
  padding: 0 0 0 10px;
  height: 168px;
  max-width: 200px;
  border-radius: 12px;
}
.performance_card__img {
  padding: 0;
}
.performance_card__icon {
  padding-right: 5px;
}
.performance_card__icon ion-icon {
  font-size: 20px;
  text-align: right;
  float: right;
}
.performance_card__title h1 {
  font-size: 18px;
  font-weight: 600;
  margin: 5px 0px 5px 0px;
  color: var(--ion-color-dark);
}
.performance_card__img h1 {
  font-size: 28px;
  font-weight: 800;
  margin: 5px 0px 5px 0px;
  color: var(--ion-color-dark);
}
.performance_card__score h1 {
  font-size: 22px;
  font-weight: 800;
  margin: 5px 0px 5px 0px;
  color: var(--ion-color-dark);
}
.performance_card__score label {
  font-size: 13px;
  color: var(--ion-color-dark);
  margin-left: 5px;
  font-weight: 500;
  position: relative;
  top: 13px;
}
.performance_card__bottom_img {
  position: absolute;
  bottom: 10px;
}
.performance_card__bottom_img img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.performance_card__bottom_img span {
  font-size: 12px;
  text-align: left;
  height: 20px;
  line-height: 25px;
}
/* my point css start  */
.my_point__content {
  padding: 20px 16px;
}
.my_point__card {
  margin: 0px 0px 20px 0px;
  padding-top: 12px;
  border-radius: 12px;
}
.my_point__card__grid {
  margin: 0;
  padding: 0;
}
.my_point__card__grid ion-col {
  margin: 0;
  padding: 0;
}

.my_point__card__grid ion-row {
  margin-bottom: 5px;
}

.my_point__card__grid__row1 {
  display: flex;
  justify-content: left;
}
.my_point__card__name {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin: 0;
}
.my_point__card__time,
.my_point__card__date {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-medium-shade);
}
.my_point__card__point {
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: #FE6102;
  background-color: #ffeeea;
  font-size: 14px;
  width: fit-content;
}

.my_point__card__status {
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: #20b9fc;
  background-color: #e6f7ff;
  font-size: 14px;
  width: fit-content;
}
.scoreboard_tooltip{
  --backdrop-opacity:0.7;
}

.scoreboard_tooltip__action-item-container{
  display: flex;
  justify-content: center;
}

.scoreboard_tooltip__action-item-container__close_button{
  display: flex;
  justify-content: center;
}
/* my point css end  */
@media screen and (max-width: 320px) {
  .performance_card__img img {
    width: 40px;
    height: 40px;
  }
  .performance_card__title h1 {
    font-size: 16px;
  }
  .performance_card__score h1 {
    font-size: 20px;
  }
  .performance_card__bottom_img {
    bottom: 16px;
  }
  .performance_card__bottom_img span {
    line-height: 15px;
    height: 15px;
  }
  .my_point__card__name {
    margin-left: 5px;
    font-size: 14px;
  }
  .my_point__card__point {
    text-align: left;
    font-size: 14px;
  }
  .my_point__card__time,
  .my_point__card__date {
    margin-left: 5px;
  }
}
@media screen and (max-width: 290px) {
  .performance_card__img img {
    width: 32px;
    height: 32px;
  }
  .performance_card__title h1 {
    font-size: 14px;
  }

  .performance_card__score h1 {
    font-size: 16px;
  }
  .performance_card__bottom_img {
    bottom: 20px;
  }
  .performance_card__score label {
    top: 8px;
  }
  .my_point__card__name {
    margin-left: 5px;
    font-size: 12px;
  }
  .my_point__card__point {
    text-align: left;
    font-size: 12px;
  }
  .my_point__card__time,
  .my_point__card__date {
    margin-left: 5px;
    font-size: 11px;
  }
}

.scoreboard_tooltip__close { 
  position: absolute;
  right: 9px;
  width: 20px !important;
  height: 20px !important;
}

.scoreboard_tooltip__heading{
  margin-top: 0;
  margin-bottom: 20px;
}

.my_point__card__grid__photo {
  width: 48px;
height: 48px;
}

.my_point__card__grid__photo--customer > img{
  border: 1px solid #243542;
}

.my_point__card__grid__photo--intro-video > img {
  border: 1px solid black;
}

@media screen and (max-width: 297px){
  .scoreboard_status__talent_badge__remaining-container__remaining {
    font-size: 12px;
  }
 }

 .scoreboard_status__talent_badge__remaining-container {
  position: relative;
  text-align: center;
  height: 40px;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  width: 15px;
  justify-content: center;
 }

.scoreboard_status__talent_badge img {
  width: 40px;
  height: 40px;
}

.scoreboard_status__talent_badge {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px;
}

.my_point__card__grid__photo--placeholder ion-icon{
  font-size: 58.5px;
}

.my_point__card__grid__photo--actual {
  width: 100%;
  display:flex;
  justify-content: center;
}

.my_point__card__grid__photo__container{ 
  text-align: center;
}
