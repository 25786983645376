.header_title {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title_items {
  display: inline;
}

.header_text {
  position: relative;
  display: table-row;
}

.chat {
  justify-content: left;
}

.userImg {
  border-radius: 50%;
  width: 40px;
}

.header-item__round-arrow-icon {
  filter: invert(0.9999999999);
  padding: 0px 10px;
  width: 45px;
  height: 25px;
}

.subheader_title {
  font-size: 12px;
  font-weight: 100;
  width: 100%;
  display: flex;
  justify-content: left;
  color: #F5F5F5;
  letter-spacing: 0.5px;
}
.header-view__search_btn{
 height: 48px;
}
.header-view_SearchBar{
  --border-radius: 10px;
  margin-top: 3px;
}
.header-view_SearchBar .searchbar-input{
  height: 36px;
}
.header-view_SearchBar .searchbar-search-icon.sc-ion-searchbar-md{
  top: 8px;
}

.header-item__info-icon {
  padding-right: 12px;
  padding-left: 12px;
}