.fileuploader-theme-thumbnails .fileuploader-item .type-holder,
.fileuploader-icon-download,
.fileuploader-theme-thumbnails .fileuploader-item .content-holder
{
    display: none;
}

.fileuploader-theme-thumbnails .thumbnail-holder {
    /* border: 1px solid grey; */
}

.fileuploader.fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item .fileuploader-item-inner {
    /* border-color: rgb(188, 190, 192);
    border: 1px solid;
    border-radius: 0px;
    z-index: 1; */
}

.fileuploader.fileuploader-theme-thumbnails  .fileuploader-item-image {
    border-radius: 0px;
}

.fileuploader.fileuploader-theme-thumbnails .actions-holder {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
}

.fileuploader.fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item .fileuploader-item-inner .actions-holder .fileuploader-action-remove i {
    background-color: #FE6102;
    box-shadow: none;
    border-radius: 50%;
}