

.cus_notication{
  padding: 10px 16px;
}
.cus_notication__card{
  margin: 12px 0px;
  border-radius: 12px;
}
.notication__card__gird__left_col .noti_icon_img{
  background-color: #F5F5F7;
  padding: 11px;
  border-radius: 6px;
}

.app_noti_icon_img {
  width: 46px;
  height: 46px;
}

.notication__card__gird__right_col ion-text{
font-size: 14px;
font-weight: 400;
text-align: left;
color: var(--ion-color-dark);
/* margin-bottom: 6px; */
}
.notication__card__gird__right_col ion-button {
	height: 40px;
	margin-top: 12px;
	margin-bottom: 12px;
	margin-right: 12px;
}
.cus_notication__card__gird, .notication__card__gird__right_col{
  padding-bottom: 0;
}

.notification__positive-feedback__closed {
  float: right;
}

.notification__content {
  width: 93%;
}

.notification__content orangetext{
  color: var(--ion-color-primary);
}

.notication__card__gird__right_col__failed_test {
  color: #FA3D28;
}

.notication__card__gird__right_col__title {
  margin-bottom: 5px;
}

.app-notif__no-push-allowed-to-ask {
  margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 7px;

  color: #FE6102;
  background-color:#ffeeea;
  font-size: 14px;
  margin-bottom: 5px;

}

.app-notif__no-push-allowed-to-ask__flex {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

@media (max-width: 320px) {
  .app-notif__no-push-allowed-to-ask {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
  }
}

.app-notif__no-push-allowed-to-ask img {
  width: 30px;
  height: 30px;
}