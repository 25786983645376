.talent-badges-content__card__name {
  font-weight: bold;
}

.talent-badges-content__card__grid__photo{ 
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.talent-badges-content__card__grid__photo img{ 
  width: 50px;
  height: 50px;
}
