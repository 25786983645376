.manage-intro-video {
  padding: 20px 20px 0px 20px;
  width: 100%;
  
}

.manage-intro-video h3 {
  margin-top: 0px;
  font-weight: bold;
}

.manage-intro-video__info {
  color: var(--ion-color-medium-shade);
}

.manage-intro-video__submit {
  margin: auto 20px 20px 20px;
}

.manage-intro-video-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.manage-intro-video__current-video-notice {
  color: #FA3D28;
  font-family: var(--baseFontFamily);
}

.talent-intro-video-container ion-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  z-index: 999;
  width: 25px;
  height: 25px;
}

.talent-intro-video-container > div {
  position: relative;
}

.talent-intro-video-container__status {
  text-align: center;
}