.faqs-profilemanagement-header__header-item {
  background-color: rgb(248, 248, 248);
  color: black;
  padding: 20px 0px;
  justify-content: space-between;
  
}

.faqs-profilemanagement-header-item__header-toolbar-title {
  font-weight: 500;
  text-align: center;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}

.faqs-profilemanagement-container__item{
  padding:0px 20px;
}

.faqs-profilemanagement-container__title{
  font-size: 20px;
  margin: 10px 5px;
}

.faqs-profilemanagement-container__content{
  color: gray;
  line-height: 1.5;
  margin: 5px;
  margin-left: 12px;
}

.faqs-profilemanagement-container__contact{
  color: #FA3D28;
  margin: 0px;
    margin-left: 10px;
}

.faqs-profilemanagement-container__title__accordion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
}

.faqs-profilemanagement-container__title .faqs-profilemanagement-container__btn_text{
  font-size: 16px;
  text-align: left;
}

.faqs-profilemanagement-container__title .faqs-profilemanagement-container__icon_right {
  font-size: 16px;
  width: 16px;
  height: 16px;
  min-width: 16px;
min-height: 16px;
}

.faqs-profilemanagement-container__title .faqs-profilemanagement-container_btn {
  background: #fff0e6;
  color: var(--ion-color-primary);
  padding: 10px 10px;
  border-radius: 6px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
  margin-left: 0px;
}

.faqs-profilemanagement-container__title .faqs-profilemanagement-container_btn_active {
  cursor: default;
  pointer-events: none;
  background: var(--ion-color-primary);
  color: white;
}