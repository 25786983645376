.app-rating-container {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
}
.app-rating-view__header-item {
  background-color: var(--ion-color-primary);
  color: white;
  padding: 20px 0px;
}

.app-rating-container__title-view {
  text-align: center;
}

.app-rating-container__title {
  font-size: 26px;
  font-weight: 700;
  color: #000000;
  margin-top: 0;
  margin-bottom: 10px;
}

.app-rating-container__card-container {
  text-align: center;
  border: 1px solid #d1d1d1b8;
  border-radius: 12px;
  padding: 25px 10px;
  margin: 20px 0px;
}

.app-rating-container__sub-title {
  color: #000000cc;
  font-size: 15px;
  padding: 0px;
  margin: 0px;
}

.app-rating-container__rating {
  margin-top: 20px;
}

.app-rating-container__fastart-icon {
  font-size: 20px;
  margin: 0px;
  margin-right: 5px;
  zoom: 1.5;
  color: var(--ion-color-primary) !important;
}

.app-rating-container__rating-highlight {
  color: var(--ion-color-primary) !important;
}

.rating-footer {
  margin: 20px 10px;
  width: 95%;
}

.app-rating-container__txt-area {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 15px 20px;
  border: 1px solid #908888;
  border-radius: 12px;
  box-shadow: rgb(214 214 214) 0 0 11px;
  background-color: white;
}

.app-rating-container__feedback {
  font-size: 14px;
  text-align: left;
  color: var(--ion-color-medium-tint);
  margin-bottom: 20px;
}

.app-rating-container__socialShare {
  margin-left: 25px;
}

.app-rating-container__socialShare a {
  padding-right: 10px;
}
.app-rating_submit_btn {
  margin-top: auto;
}
.app-rating__social_paltforms_backdrop {
  background: #161a2c40;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

}
.app-rating__social_share_modal{
  height: 40%;
   width: 100%;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px 2px 20px 0px rgba(169, 169, 169, 0.24);
  background-color: white;
}
.app-rating__social_paltforms {
 background: transparent;
 
}
.app-rating__social_paltforms__img{
  display: flex;
  flex-wrap: wrap;
  justify-content:left;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  column-gap: 8px;
}
.app-rating__social_paltforms__img label{
  font-size: 14px;
  margin-top: 8px;
   color: #000000;
}

.social_platform__img{
  border-radius: 50%;
  background-color: #F5F5F7;
  height: 74px;
  width: 74px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
.app-rating__social_paltforms h2{
font-size: 20px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0px;
text-align: center;
color: #000000;
display: block;
width: 100%;
margin-top: 22px;
margin-bottom: 20px;

}
.social_plateform__bbb{
  width: 52px !important;
  height: 52px !important;
}

.app-rating__social_paltforms img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #F5F5F7;
}

.app-rating__social_paltforms__closed{
  position: absolute;
  right: 9px;
  top: 9px;
  width: 20px !important;
  height: 20px !important;
}