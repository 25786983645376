.manully_add_referral{
    padding: 20px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.manully_add_referral__title{
font-size: 26px;
font-weight: 600;
letter-spacing: 0em;
text-align: left;
color: var(--ion-color-dark);
margin-top: 0px;
margin-bottom: 16px;
}
.manully_add_referral__text{
font-size: 14px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
color: var(--ion-color-medium-tint);
margin-bottom: 24px;
}
.multi_field__input {
	width: 100%;
	height: 45px;
	border: 1px solid #E9EBF0;
	border-radius: 12px;
	box-shadow: 0px 2px 4px rgba(55, 18, 0, 0.04);
    padding-left: 16px;
    margin-bottom: 8px;
    margin-top: 24px;
    background-color: white;
}
.manully_add_referral__add_more{
    --color: var(--ion-color-primary);
font-size: 14px;
font-weight: 500;
letter-spacing: 0px;
text-align: right;
--padding-start:0;
width: 150px;
margin-bottom: 16px;

}
.manully_add_referral__add_more img{
    margin-right: 8px;
}
.manully_add_referral__submit_btn{
    margin-top: auto;
}
.new-referral__text {
    margin-bottom: 10px;
}
.new-referral__items__list__no-contact {
    margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 12px;
  
  color: #FE6102;
  background-color:#ffeeea;
  font-size: 14px;
  
}

.new-referral__items__list__no-contact__flex {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

@media (max-width: 320px) {
    .new-referral__items__list__no-contact {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.new-referral__items__list__no-contact img {
    width: 30px;
    height: 30px;
}

.new-referral__title__earn-points {
    display: flex;
    align-items: center;
    color: #FE6102 !important;
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
}
  
.new_referral__text{
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: var(--baseFontColor);
}