.homepage__content{
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.homeContainer {
  --padding-top: 20px;
}
.homeContainer a {
  text-decoration: none;
  font-size: 14px;
}
.cardContainer {
  margin-bottom: 15px;
}
.cardHeader {
  margin-bottom: 5px;
}
.cardBody {
  background-color: var(--ion-color-light-tint);
  padding: 15px 10px 15px 10px;
}
.payMybillsInfo {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 5px;
}
.myBillDueAmount {
  max-width: 50%;
  padding-bottom: 0px;
  background-color: var(--ion-color-primary-contrast);
  margin: 0px 15px 0px 0px;
  border-radius: 0px;
}
.myBillDueAmountCard {
  padding: 3px 20px 3px 20px;
  border-radius: 0px;
  line-height: 4px;
  font-weight: 600;
}
.myBillDueAmountCard :first-child {
  color: var(--ion-color-primary);
  font-size: 12px;
}
.myBillDueAmountCard :last-child {
  font-size: 16px;
  font-weight: bold;
}
.myBillDueDate {
  max-width: 50%;
  padding-bottom: 0px;
  background-color: var(--ion-color-primary-contrast);
  margin: 0px;
  border-radius: 0px;
}
.myBillDueDateCard {
  padding: 3px 25px 3px 25px;
  border-radius: 0px;
  line-height: 4px;
  font-weight: 600;
}
.myBillDueDateCard :first-child {
  color: var(--ion-color-primary);
  font-size: 12px;
}
.myBillDueDateCard :last-child {
  font-size: 16px;
  font-weight: bold;
}

.payMybills {
  display: inline-grid;
  justify-content: center;
  width: 100%;
}
.payMybills :last-child {
  text-decoration: none;
}
.cardTitle {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cardTitle h6 {
  margin-top: 4px;
  margin-left: 12px;
  color: #231f20;
  font-family: "Open Sans SemiBold";
  font-size: 1.2rem;
}
.cardTitle ion-icon {
  font-size: 30px;
}
.btn-light {
  background: #ffca28;
  color: black;
}

.label {
  height: auto;
}

.spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15vh;
}

.align {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.homeContainer ion-header ion-select {
	color: White;
}


.input-css {
  background-color: rgb(240, 240, 240);
  border: 1px solid rgb(210, 210, 210);
  border-radius: 9px;
  font-size: 0.9em !important;
  margin-top: 10px;
  padding-left: 10px !important;
}


.cardHome {
  width: 100%;
}

.edit {
  display: flex;
  justify-content: flex-end;
}

.btn-transition-home {
  --background: linear-gradient(to right, #f57c00 14%, #ffca00 96%);
}


.ToolbarHome {
  --background: orange !important;
}

.logoTitle {
  color: white;
  font-size: 1.9rem;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: var(--baseFontFamily);
}
.logoSpan {
  display: inline-flex;
}

.IonCardTextBold {
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

.heartIconProducts {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 12px;
}
.iconSizeProd {
  color: var(--ion-color-secondary-tint);
}
.notificatonCard {
  background-color: var(--ion-color-secondary-light);
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.notificatonCard .notificatonCard__title {
  color: #231f20;
  font-weight: bold;
  padding-bottom: 2px;
}
.calendarNotification {
  margin: 0px 0px 12px 0px;
  border-radius: 0px;
}
.calendarNotificationTitle a {
  font-weight: bold;
}
.calendarNotification .time {
  color: #6d6e71;
  font-weight: bold;
}
.forumNotification {
  margin: 0px 0px 12px 0px;
  border-radius: 0px;
}
.forumNotification ion-card-content {
  padding-top: 0px;
  font-size: 14px;
}

.forumNotification h3 {
  color: var(--ion-color-primary);
  font-weight: bold;
}
.newsNotification {
  margin: 0px 0px 12px 0px;
  border-radius: 0px;
}
.newsNotification ion-card-content {
  padding-top: 0px;
  font-size: 14px;
}

.newsNotification h3 {
  color: var(--ion-color-primary);
  font-weight: bold;
}
.newImage {
  width: 100%;
  height: 60%;
  overflow: hidden;
}
.userProfile ion-avatar {
  width: 50px;
  height: 50px;
}
.userProfile h2 {
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  margin-top: 100px;
}
.no-data-placehoder {
  background-color: var(--ion-color-light-tint);
  padding: 20px;
  text-align: center;
  font-size: 1.2rem;
}
.no-data-placehoder ion-icon {
  font-size: 32px;
}

@media (max-width: 768px) {
  .home__col1 {
    display: none;
  }
}

@media (min-width: 768px) {
  .home__onmobile {
    display: none;
  }
}

.slide_title {
  color: black;
  font-size: 21px !important;
  font-weight: bold;
  margin-left: 15px !important;
  margin: 0px;
}

.card_view {
  padding: 0px;
  margin: 0px;
  border: none;
  box-shadow: none;
  margin-top: 20px;
}

.view_all_item {
  color: var(--ion-color-primary);
  font-size: 13px !important;
  padding: 0px;
  margin: 5px 0px 0px 0px;
  float: right;
  margin-right: 15px;
}

.card_slider .card_content_slider {
  margin-top: 0px !important;
}

.card_content_slider {
  padding: 0px !important;
  margin: 0px 5px;
}

.card_img {
  width: 40px;
  margin: 5px;
}

.card_imgs {
  display: inline-flex;
}

.homepage__content__instabook-icon {
  height: 12px !important;
  margin-left: 2px;
}

.homepage__content ion-slides {
  height: 100%;
}

.swiper-pagination {
  position: relative;
  padding-top: 10px;
}
