.manage-intro-video__upload-btn {
  margin-bottom: 80px;
}

.manage-intro-video .file-upload-add-more {
    width: 100%;
    margin-top:8px
}
.manage-intro-video .file-upload-add-more .fileuploader {

  height: 50px;
  background: white;
  border-radius: 12px;
  border: 1px solid var(--ion-color-light-shade);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important; 
}
.manage-intro-video .file-upload-add-more .fileuploader-input{
    display: flex;
    justify-content: center;
    margin-top: 6px;
}

.manage-intro-video
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-caption {
  margin-right: 0px;
  align-self: center !important;
  box-shadow: none;
  border: 0px;
  padding: 0px 5px;
}

.manage-intro-video
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button {
  background: white;
  background-position-x: 0%;
  background-position-y: 0%;
  background-position: center;
  color: #136c9e;
  box-shadow: none;
  padding: 0px 5px !important;
}

.manage-intro-video
  .fileuploader-items
  .fileuploader-item
  .fileuploader-action-remove {
  color: var(--ion-color-primary);
}

.manage-intro-video
  .fileuploader-items
  .fileuploader-item
  .fileuploader-action.fileuploader-action-remove:hover {
  background-color: transparent;

}

.manage-intro-video
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button
  span {
  display: none;
}
.manage-intro-video
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button::after {
  content: "Upload Video";
  color: var(--ion-color-dark);
  font-weight: bold;
}

.manage-intro-video
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-caption
  span {
  display: none;
}
.manage-intro-video
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-caption::after {
  content: url("../../../assets/images/common/cloud-upload.png");
  align-self: center !important;
  padding: 0px !important;
  border: 0px;
}

.manage-intro-video .fileuploader-items .fileuploader-items-list {
  margin: 8px 0px 0px 0px  !important;
  
}



.manage-intro-video .fileuploader-items .fileuploader-item {

    position: relative;
    margin: 0;
    padding: 10px 5px 10px 0px;
    border-bottom: 1px solid #ebeef1;
    animation-duration: .6s;
    /* width: 130px; */

}
.manage-intro-video .fileuploader-items .fileuploader-item .column-title{
  flex: 1 1;
  padding-left: 8px;
  padding-right: 0px;
  color: #74809d;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.manage-intro-video .fileuploader-items .fileuploader-item .column-thumbnail{
  position: relative;
    width: 32px;
    height: 32px;
}
.manage-intro-video .fileuploader-items .fileuploader-item .column-title div {
	width: 100%;
	/* font-weight: 700; */
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 11px;
}
.manage-intro-video .fileuploader-items .fileuploader-item .column-actions {
	margin: 0px;
}

