.scoreboard_reward > ion-item {
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
    margin-bottom: 10px;
  }
  .scoreboard_reward__title {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    color: var(--ion-color-dark);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    height: 30px;
    border-radius: nullpx;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .scoreboard_reward__title ion-icon {
    vertical-align: middle;
    margin-left: 5px;
  }

  .scoreboard_reward__all-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    color: var(--ion-color-primary);
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
  