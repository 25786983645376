.noti-type-container{
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y:auto;
}
.noti-type {
  margin: 20px 16px;
  /* padding: 0px 20px; */
  border-radius: 12px;
}
.noti-type__text {
  height: 80px;
  border-bottom: 1px solid var(--ion-color-light-tint);
  padding: 16px;
}
.noti-type__text ion-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.noti-type__type {
  --border-color: var(--ion-color-light-tint);
  margin-bottom: 12px;
  --inner-padding-end: 0;
  --padding-start: 12px;
}
.noti-type__type__label {
  font-weight: bold;
  color: var(--ion-color-dark-shade);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-overflow: ellipsis; /* IE, Safari (WebKit) */
  overflow: hidden; /* don't show excess chars */
  white-space: nowrap;
  width: 250px;
}

.no-border {
  --border-color: white;
}

.noti-type__submit_btn {
  position: absolute;
  bottom: 20px;
  left: 16px;
  right: 16px;
}
