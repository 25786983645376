.scoreboard_my_point{
	margin-bottom: 15px;
}

.scoreboard_my_point > ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  margin-bottom: 10px;
}

.scoreboard_my_point__title {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  color: var(--ion-color-dark);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  height: 30px;
  border-radius: nullpx;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scoreboard_my_point__title ion-icon {
  vertical-align: middle;
  margin-left: 5px;
}

.scoreboard_my_point__all-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  color: var(--ion-color-primary);
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
}
.scoreboard_my_point__all-item ion-icon{
  font-size: 12px;
}
.scoreboard_my_point__card{
	margin: 0;
	padding: 0px 8px;
	border-radius: 12px;
}
.scoreboard_my_point__card__point {
    text-align: center;
    margin-top: 25px;
  }
  .scoreboard_my_point__card__point ion-text {
    font-size: 14px;
  }
  .scoreboard_my_point__card__point h1 {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin: 0px 0px 10px 0px;
    color: var(--ion-color-primary);
  }
  .scoreboard_my_point__gird{
	padding: 0px 0px 20px 0px
}
.scoreboard_my_point__line{
	border-bottom: 1px solid var(--ion-color-light-tint);
}
.scoreboard_my_point__col_left,
.scoreboard_my_point__col_right{
	text-align: center;
}

.scoreboard_my_point__col_left{
	border-right: 1px solid var(--ion-color-light-tint);
}
.scoreboard_my_point__col_left ion-text,
.scoreboard_my_point__col_right ion-text{
	text-align: center;
	font-size: 13px;
}
.scoreboard_my_point__col_left h2,
.scoreboard_my_point__col_right h2{
	text-align: center;
font-size: 24px;
font-weight: 600;
color: var(--ion-color-dark);
margin: 0;
}