.empty-screen {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    margin-top: 50%;
  }
  .empty-screen__img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    background-color: #F5F5F7;
    border-radius: 50%;

  }
  .empty-screen__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    color: var(--ion-color-dark);
    margin-bottom: 10px;
    margin-top: 0;
  }
  .empty-screen__text {
    color: var(--ion-color-medium-shade);
  }