.w9-form-sec {
  margin: 20px 13px;
}
.w9-form-sec__title h6 {
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 8px;
}
.w9-form-sec__title p {
  font-size: 13px;
  color: var(--ion-color-medium-shade);
  line-height: 20px;
  font-weight: 500;
  margin-top: 0px;
}

.w9-form-sec__certifcate--1,
.w9-form-sec__certifcate--2,
.w9-form-sec__certifcate--3,
.w9-form-sec__certifcate--4{
display: flex;
flex-direction: row;
column-gap: 10px;
}
.w9-form-sec__submit--btn{
    margin: 20px 0px 40px 0px;
}
.form-group__button{
    margin-left: 0px;
    margin-right: 0px;
}
.w9-form-sec .w9-form-sec__error-message-checkbox-text {
  padding-top: 0px;
  padding-bottom: 15px;
  text-align: end;
}
