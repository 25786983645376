.new_internal_referral{
    padding: 0px 16px 20px 16px;
    height: 100%;
}

.new_internal_referral__submit_btn{
   margin: 0px 16px 20px 16px ;
}
.new_internal_referral__no-contact {
    margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  color: #FE6102;
  background-color:#ffeeea;
  font-size: 14px;
  column-gap: 10px;
}

@media (max-width: 320px) {
    .new_internal_referral__no-contact {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.new_internal_referral__no-contact img {
    width: 30px;
    height: 30px;
}

.new-referral__items__list__my-crew {
    text-align: right;
}

.new_internal_referral--incl-opps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}