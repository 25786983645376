.contactus-popover {
  --backdrop-opacity:0.7;
  
}

.contactus-popover .popover-content {
  width: 90vw;
  border-radius: 12px !important;
}

.contactus-popover__heading {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
}

.contactus-popover__close { 
  position: absolute;
  right: 9px;
  width: 20px !important;
  height: 20px !important;
}

.contactus-popover ion-textarea {
  border: 1px solid #ddd;
  border-radius: 12px;
  --placeholder-opacity: 0.3;
  padding-left: 10px;
  width: 100%;
  font-size: 16px;
  margin-top: 3px;
  --padding-start: 0px;
}

.contactus-popover__input {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.contactus-popover__input ion-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-color-medium-shade) !important;
  width: 100%;
}

.contactus-popover__input ion-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
}

.contactus-popover__submit {
  margin-top: auto;
  --border-radius: 12px;
  --padding-bottom: 26px;
  --padding-top: 26px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}