.customer_referral {
  padding: 20px 16px;
}
.customer_referral__card {
  margin: 12px 0px;
  border-radius: 12px;
}
.customer_referral__grid {
  padding: 11px 11px 0px 11px;
}
.customer_referral__img {
  height: 48px;
  width: 48px;
}
.customer_referral__titile {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin: 0;
}
.customer_referral__badge {
  --background: #fff0e6;
  --color: var(--ion-color-primary);
  font-weight: normal;
  font-size: 14px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  margin-right: 5px;
  margin-bottom: 8px;
  margin-top: 8px;
  border-radius: 6px;
}
.customer_referral__badge__orange {
  --background: #fff0e6;
  --color: var(--ion-color-primary);
}
.customer_referral__badge__blue {
  --background: #e6f7ff;
  --color: #20b9fc;
}
.customer_referral__email{
  padding-top: 4px;
  padding-bottom: 12px;
}
@media screen and (max-width: 320px) {
    .customer_referral__titile {
        text-align: center;
      }
      .customer_referral__badges{
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
      }
  }