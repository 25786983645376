.event-list__event__card {
  margin: 20px 0px;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
}
.event-list__event__card__img {
  position: relative;
  max-height: 175px;
  min-height: 175px;
  overflow: hidden;
  display: block;
}
.event-list__event__card__img img {
  width: 400px;
}
.event-list__event_badges {
	position: absolute;
	top: 15px;
	left: 13px;
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	/* width: 326px; */
}
 
.event-list__event__card__mile,
.event-list__event__card__wait,
.event-list__event__card__model,
.event-list__event__card__confirmation-status {
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-dark-shade);
  font-weight: normal;
  --padding-bottom: 6px;
  --padding-end: 6px;
  --padding-start: 6px;
  --padding-top: 6px;
  margin-right: 5px;
  margin-bottom: 8px;
  height: fit-content;
 
}

.event-list__event_badges .auto-book-placeholder {
  margin-right: 5px;
  margin-bottom: 8px;
}


.event-list__event__card__date {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
  font-weight: normal;
  --padding-bottom: 8px;
  --padding-end: 14px;
  --padding-start: 10px;
  --padding-top: 8px;
  position: absolute;
  top: -18px;
  border-radius: 8px;
  border: 3px solid var(--ion-color-light);
}

.event-list__event__card__title h3 {
  font-weight: bold;
  font-size: 16px;
  padding-inline-start: 0px;
  padding-bottom: 5px;
  margin-top: 15px;
  color: var(--ion-color-dark);
}
.event-list__event__card__address {
  margin-top: 10px;
}
.event-list__event__card__est {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.event-list__event__card__est ion-text {
  color: var(--ion-color-primary);
  font-weight: bold;
  margin-top: 10px;
}
.event-list__event__card__est label {
  background: #ffe6c3;
  color: var(--ion-color-primary);
  font-weight: normal;
  padding: 5px 10px;
  border-radius: 10px;
  border: 3px solid var(--ion-color-light);
  display: inline-flex;
}
.event-list__event__card__est label img {
  margin-right: 7px;
}
@media screen and (max-width: 320px){
 
}

@media screen and (max-width: 290px){
 
}

.event-list__event__card .assignment-view__take_test{
  margin-top: 10px;
}

.event-list__event__card .assignment-view__action_btn ion-button {
  width: 48%;
}


.event-list__event__card__toolbar-bottom {
  position: absolute;
  bottom: 12px;
  right: 10px;
  z-index: 99;
  background: transparent;
}

.event-list__event__card__toolbar-bottom__label {
  background: white;
  color: var(--ion-color-primary);
  padding: 5px;
  font-weight: bold;
  border: 2px solid var(--ion-color-primary);
  float: right;
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary);
  --padding-bottom: 8px;
  --padding-end: 14px;
  --padding-start: 10px;
  --padding-top: 8px;
  border-radius: 8px;
}

.slider_view__points {
  background-color: #ffeeea;
  border: 3px solid var(--ion-color-light) !important;
  border-radius: 6px;
  color: var(--ion-color-primary) !important;
  position: absolute;
  top: -15px;
  height: 28px;
  line-height: 10px;
  padding: 6px 10px 6px 10px;
  right: 16px;
  text-transform: uppercase;
}

ion-badge.event-list__event__card__confirmation-status  span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 3px;
}