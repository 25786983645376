.mycrew_container {
  padding: 12px 16px 20px 16px;
  max-width: 480px;
  width: 100%;
}
.mycrew_card {
  margin: 0px 0px 12px 0px;
  border-radius: 12px;
  border: 1px solid #e9ebf0;
  box-shadow: 0px 2px 4px rgba(55, 18, 0, 0.04);
  width: 100%;
}
.mycrew_card__grid {
  padding: 11px;
}
.mycrew_card__img {
  width: 48px;
  height: 48px;
}
.mycrew_card__col_mid {
  padding-left: 12px;

  position: relative;
}
.mycrew_card__title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin-top: 0;
  margin-bottom: 4px;
}
.mycrew_card__label {
  color: var(--ion-color-primary);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}
.mycrew_card__add_btn {
  position: absolute;
  right: 0px;
  top: 10px;
  font-size: 14px;
  font-weight: normal;
  width: 43px;
  height: 28px;
}
.mycrew_card__remove_btn {
  position: absolute;
  right: 0px;
  top: 10px;
  font-size: 14px;
  font-weight: normal;
  width: 70px;
  height: 28px;
  --border-color: #e9ebf0;
  --color: #505662;
}
.mycrew_searchbar {
  --background: white;
  box-shadow: 1px 2px 4px rgba(240, 240, 240, 0.25), 0px 2px 20px rgba(169, 169, 169, 0.24);
  border: 1px solid #e9ebf0;
  border-radius: 6px;
  height: 45px;
  width: 100%;
  margin-bottom: 12px;
}

.mycrew_container ion-title {
  padding-left: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  margin-bottom: 8px;
  margin-top: 12px;
}

.mycrew_card--found-talent {
  margin-top: 15px;
}

.mycrew_container__search-talent {
  display: flex;
}

.mycrew_container__search-talent__search_btn {
  height: 48px;
}