/* Fonts Declaration */
@font-face {
    font-family: 'Century Gothic';
    src: url('CenturyGothic.ttf')  format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Century Gothic';
    src: url('CenturyGothicItalic.TTF')  format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Century Gothic';
    src: url('CenturyGothicBold.TTF')  format('truetype');
    font-weight: 700;
    font-style: bold;
}
@font-face {
    font-family: 'Century Gothic';
    src: url('CenturyGothicBoldItalic.TTF')  format('truetype');
    font-weight: 700;
    font-style: italic;
}