.talent-rate {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  position: relative;
}

.talent_rate__form {
  height: 100%;
}

.talent-rate__label {
  color: var(--ion-color-medium-shade) !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.talent-rate__hr_rate {
  color: var(--ion-color-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: block;
  padding: 0;
}
.talent-rate__range_picker {
  --knob-background: var(--ion-color-primary);
  --bar-border-radius: 6px;
  --bar-height: 8px;
  --bar-background: #fe61024a;
  margin: 0px;
  padding: 0px;
}

.talent-rate__hr_rates {
  color: var(--ion-color-medium-tint);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.talent-rate__submit_btn{
  position: absolute;
  bottom: 20px;
  right: 16px;
  left: 16px;
}