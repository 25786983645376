.payment-calendar {
  padding: 0px;
  margin: 0;
  border-radius: 0;
  height: 40px;
  display: flex;
  width: 100%;
}
.payment-calendar .calender_icon {
  font-size: 24px;
  margin-left: 16px;
  margin-top: 8px;
}
.payment-calendar .chevron_icon {
  font-size: 20px;
  margin-top: 10px;
  margin-right: 16px;
}
.payment-calendar_from_to {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.payment-calendar__calendar_to,
.payment-calendar__calendar_from {
  width: 40%;
  font-size: 16px;
  padding: 0px;
  margin: 9px 0px 0px 10px;
  color: var(--ion-color-dark);
}
.total-revenu-container {
  padding: 0 16px;
}
.total-revenu__title {
  --inner-padding-end: 0px;
  --inner-padding-star: 0px;
  --padding-start: 0;
  --padding-end: 0;
  margin-top: 16px;
}
.total-revenu__title__view_all_grey {
  color: #8E93A2;
  font-size: 14px;
  display: flex;
  align-content: center;
  margin-left: 0;
}
.total-revenu__title__view_all {
  color: var(--ion-color-primary);
  font-size: 14px;
  display: flex;
  align-content: center;
  margin-left: 0;
}
.total-revenu__title__view_all ion-icon {
  font-size: 11px;
  margin-top: 4px;
}
.total-revenu__title__view_all_grey ion-icon {
  font-size: 11px;
  margin-top: 4px;
}

.total-revenu__title ion-title {
  padding: 0;
  font-weight: bold;
  color: var(--ion-color-dark);
  font-size: 22px;
}
.total-revenu__details {
  padding: 0;
  margin: 0px 0px 16px 0px;
  border-radius: 12px;
}
.total-revenu__details__earned {
  text-align: center;
  padding: 13px 16px;
}
.total-revenu__details__earned ion-card-title {
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0px;
  color: var(--ion-color-primary);
}

.total-revenu__details__paid,
.total-revenu__details__pending {
  text-align: center;
}
.total-revenu__details__paid ion-title,
.total-revenu__details__pending ion-title {
  color: var(--ion-color-dark);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
}
.total-revenu__details__pending {
  border-left: 2px solid #f5f5f7;
}
.paid-revenu,
.pending-revenu {
  display: flex;
  column-gap: 16px;
  justify-content: center;
}
.pending-revenu ion-card,
.paid-revenu ion-card {
  padding: 0;
  margin: 0px;
  border-radius: 12px;
  width: 100%;
  height: 80px;
  text-align: center;
}
.paid-revenu ion-card ion-title,
.pending-revenu ion-card ion-title {
  color: var(--ion-color-dark);
  font-size: 24px;
  font-weight: 600;
  margin-top: 14px;
  letter-spacing: 0px;
  text-align: center;
}

/* empty state  component css  */
.empty-state {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  margin-top: 50%;
}
.empty-state__img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.empty-state__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
  margin-bottom: 10px;
}
.empty-state__text {
  color: var(--ion-color-medium-shade);
}
.pending__na_value{
  margin-top: 30px;
}
@media screen and (max-width: 320px){
  .pending-revenu ion-card,
.paid-revenu ion-card {
  width: 130px;
  height: 75px;
}
.total-revenu__title ion-title{
  font-size: 18px;
}
 
 }
 @media screen and (max-width: 295px){
  .pending-revenu ion-card,
  .paid-revenu ion-card {
    width: 115px;
    height: 70px;
  }
 
}

.total-revenu__title__container ion-icon {
  vertical-align: middle;
  margin-left: 5px;
}

.total-revenu__details__under-development {
  text-align: center;
  font-size: 13px;
  color: var(--ion-color-medium-shade);
  font-weight: normal;
  line-height: normal;
  margin-top: 10px;
}