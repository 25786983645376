.faqs-card-view {
  margin: 20px 16px;
  padding: 10px 16px;
  border-radius: 12px;
}
.faqs-card-view__items {
  --border-color: var(--ion-color-light-tint);
  margin-bottom: 12px;
  --inner-padding-end: 0;
  --padding-start: 0;
}

.faqs-card-view__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark-shades);
}
.faqs_selection_icon {
  font-size: 14px;
}
.no-border {
  color: var(--ion-color-dark-shades);
}
