.new_referral{
    padding: 20px 16px;
    height: 100%;
}
.new_referral form{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.new_referral__title{
font-size: 26px;
font-weight: 600;
text-align: left;
color: var(--ion-color-dark);
margin-top: 0;
margin-bottom: 16px;
}
.new_referral__text{
font-size: 14px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
color: var(--baseFontColor);

}
.new_referral__submit_btn{
    margin-top: auto;
}

.new-referral__title__earn-points {
    margin: 0px;
    display: flex;
    align-items: center;
    color: #FE6102 !important;
    margin-top: 10px;
    width: fit-content;
  }