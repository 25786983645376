.app-update-popover .popover-content { 
  border-radius: 0px;
  border: 3px solid black;
}

.app-update-popover__content {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  padding: "16px";
}

.app-update-popover__content__exclamation-mark-container {
  text-align: center;
}
.app-update-popover__content__exclamation-mark-container img {
  width: 75px;
  height: 75px;
}

.app-update-popover__content__random_image_container img {
  max-width: 100%;
  max-height: 200px;
}

.app-update-popover__content {
  text-align:  center;
  padding-left: 16px;
  padding-right: 16px;
}

.app-update-popover__content__download-image--googleplay {
  width: 134px;
  height: 51px;
}

.app-update-popover__content__download-image--appstore {
  width: 134px;
  height: 44.5px;
}

.app-update-popover__content__download-link {
  display: flex;
  justify-content: center;
}

.app-update-popover__content h1 {
  font-weight: bold;
  margin-top: 0px;
  font-family: 'baloo-thambi-regular';
}

.app-update-popover {
  --backdrop-opacity: 0.7;
}