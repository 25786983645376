.w2-form-sec {
  margin: 20px 13px;
}
.w2-form-sec__certifcate--4 {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin: 15px 0px;
}
.w2-form-sec__submit--btn {
  margin: 20px 0px 40px 0px;
}
.w2form__instruction-title h1 {
  text-align: center;
  font-weight: bold;
  line-height: 0;
  letter-spacing: 10px;
}
.w2form__instruction-title div {
  text-align: center;
  font-size: 14px;
  margin-left: 38px;
  font-weight: 500;
}
.w2form__instruction-title div span {
  color: var(--ion-color-primary);
}
.instruction__imgs {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
.instruction__img {
  border-radius: 50%;
  height: 110px;
  width: 110px;
  background-color:var(--ion-color-primary);
}
.instruction__img {
  height: 70px;
  width: 70px;
}
.instruction__img img {
  height: 50px;
  width: 50px;
  position: relative;
  top: 10px;
  left: 10px;
}
.instruction__imgs-labels{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  text-align: center;
}
.instruction__imgs--label{
  font-size: 13px;
  font-weight: bold;
}
.w2-form__error {
  text-align: center;
  color: #FA3D28;
  font-size: 13px;
}

.instruction--1,
.instruction--2,
.instruction--3,
.instruction--4,
.instruction--5 {
  font-size: 13px;
  color: var(--ion-color-medium-shade);
  line-height: 20px;
  font-weight: 500;
  margin: 16px 0px;
}
.w2-from-submit{
  position: relative;
  top: 15%;
}
.instruction--3 label{
  color: var(--ion-color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.w2-from-submit h1{
  text-align: center;
  font-weight: bold;
  line-height: 0;
  letter-spacing: 10px;
  color: var(--ion-color-primary);
  margin-bottom: 10px;
}
.w2-from-submit__div{
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  line-height: 20px;
  margin: 20px 10px;
}

.w2-from-submit__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}