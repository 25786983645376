.opp_filter_header_title{
  color: var(--ion-color-dark);
font-size: 18px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0em;
text-align: center;

}
.opp_filter_header__back_btn{
  --padding-start:0.5em;
}
.opportunity_filter__container {
  margin: 0;
  padding: 0px 16px 20px 16px;
}
.filter_date,
.filter_account_level,
.filter_event_type,
.how_far,
.location_filter,
.min_hour_rate {
  border-bottom: 1px solid var(--ion-color-light-shade);
  padding-bottom: 16px;
  width: 100%;
}
.filter_date__title,
.filter_account_level__title,
.filter_event_type__title,
.how_far__title,
.location_filter__title,
.min_hour_rate__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-dark);
  margin-top: 16px;
  margin-bottom: 8px;
}
.how_far__title__amount,
.min_hour_rate__amount {
  color: var(--ion-color-primary);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 8;
}
.how_far__range,
.min_hour_rate__range {
  padding: 8px 0px 8px 0px;
  --knob-size:20px;
}
.min_hour_rate_label {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-medium-shade);
  position: relative;
  bottom: 20px;
}
.filter_date__items,
.filter_account_level__items,
.filter_event_type__items,
.location_filter__use_current {
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-start: 0;
  --padding-end: 0;
  --min-height:38px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-dark);
  
}
.filter_date__items ion-radio,
.filter_account_level__items ion-checkbox,
.filter_event_type__items ion-checkbox,
.location_filter__use_current ion-checkbox {
  margin-right: 12px;
}
.how_far__label_left {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-medium-shade);
  position: relative;
  bottom: 16px;
}
.how_far__label_right {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: right;
  float: right;
  color: var(--ion-color-medium-shade);
  position: relative;
  bottom: 16px;
}
.filter__no_border{
  border: none;
}
.filter_date__items ion-datetime{
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0px;
text-align: left;
color: var(--ion-color-primary);
}
