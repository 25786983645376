.my_setting_selection_view{
  border-radius: 12px;
  padding: 8px 16px;
  margin: 20px 16px;
}
.my_setting_items{
  --border-color: var(--ion-color-light-tint);
  margin-bottom: 12px;
  --inner-padding-end:0;
  --padding-start:0;
}
.my_setting_items img img {
  margin-right: 16px;
}

.mysetting_label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark-shades);
}

.my_setting_selection_icon {
  font-size: 14px;
}

.my_setting_items_no_border{
  --border-color: white;
  margin-bottom: 12px;
  --inner-padding-end:0;
  --padding-start:0;
}

.mysetting_label_delete {
  color: #FA3D28;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.border_none{
  --border-color: white;
}

