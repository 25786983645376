.password-view-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.password-view-form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header_item {
  background: var(--ion-color-primary) !important;
  padding: 10px 15px;
}

.location_container {
  background: white;
  padding: 8px 10px;
  border-radius: 15px;
  margin: 10px 0px;
}

.fa_icon_location {
  color: var(--ion-color-primary) !important;
  float: left;
  margin-top: 10px;
}

.location_container div {
  margin-left: 20px;
}

.badge_icons {
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 50px;
  border-radius: 15px;
  margin-top: 10px;
  float: right;
  height: 50px;
}

.badge_icons ion-icon {
  opacity: 1 !important;
  color: white !important;
  padding: 0px !important;
}
.badge_icons ion-badge {
  top: 5px;
  left: 25px;
  background: green;
  font-size: 0px;
  width: 2px;
  height: 11px;
}

.lbl_location {
  padding: 0px !important;
  margin: 0px !important;
  color: gray;
  font-size: 12px;
}

.highlight {
  background: var(--ion-color-primary) !important;
  color:white;
}

.changepassword-view__submit {
  margin: auto 10px 20px 10px;
}

.required-field {
  border-color: #FA3D28;
}