.revenu-points {
  margin: 0px 0px 20px 0px;
  padding: 0px;
}
.revenu-points__left_col {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 8px;
  height: 170px;
}
.revenu-points__right_col {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 8px;
  height: 170px;
}
.home-profile-status,
.home-point-status {
  height: 100%;
  border-radius: 12px;
  margin: 0;
}
.home-profile-status__right {
  margin-right: 0px;
}
.home-point-status__left {
  margin-left: 0px;
}
.home-profile-status__info,
.home-point-status__info {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.profile-status__info__img {
  border: 2px solid var(--ion-color-primary);
  border-radius: 50%;
  width: 68px;
  height: 68px;
  display: block;
  text-align: center;
  background: white;
}
.profile-status__info__img img {
  object-fit: cover;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-top: 2px;

}

.profile-status__info__name {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: baseline;
}
.profile-status__info__name h5 {
  height: 28px;
  font-weight: bold;
  color: var(--ion-color-warning-contrast);
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  text-overflow:    ellipsis;   /* IE, Safari (WebKit) */
  overflow:hidden;              /* don't show excess chars */
  white-space:nowrap;           /* force single line */
}
.card_view_left .card_tit {
  font-weight: bold;
  font-size: 4vw;
  color: black;
}
.profile-status__info__progress ion-progress-bar {
  padding: 4px 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 12px;
  margin-top: 5px;
}

.profile-status__info__progress {
  height:24px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.profile-status__info__account {
  display: flex;
  justify-content: space-between;
  height: 28px;
}
.account--progress {
  color: var(--ion-color-secondary-tint);
  font-weight: normal;
  font-size: 13px;
}
.account--elite {
  color: var(--ion-color-secondary-tint);
  font-weight: normal;
  font-size: 13px;
  display: flex;
  align-items: flex-start;
}

.account--elite ion-icon {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  margin-top: 2px;
}

.account--elite > span {
  height: max-content;
}


.profile-status__elite_badge{
  float: left;
  height: 60px;
  width: 60px;
}

.profile-info-point {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 5px 15px;
}
.point-status__info__imgs {
  height: 85px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.point-status__info__imgs img{
  width: 38px;
  height: 38px;
}

.point-status__info__points {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: baseline;
}

.point-status__info__points > div {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media (max-width: 320px) {
  .point-status__info__points__hide-on-low-res {
    display: none;
  }

  .profile-status__info__account__hide-on-lower-res{
    display: none;
  }
}

@media (max-width: 400px) {
  .profile-status__info__account__hide-on-low-res {
    display: none;
  }

  .point-status__info__imgs { 
    justify-content: flex-start;
  }
}

.point-status__info__points > div {
  font-size: 14px;
  font-weight: normal;
  color: var(--ion-color-primary);
  margin-top: 5px;
  text-align: left;
  text-overflow:    ellipsis;   /* IE, Safari (WebKit) */
  overflow:hidden;              /* don't show excess chars */
  white-space:nowrap;           /* force single line */
}

.point-status__info__points > div ion-icon {
  margin-left: 5px;
}

.point-status__info__view_all {
  color: var(--ion-color-primary);
}

.home-revenue-card > ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
}
.home-revenue-card > ion-item ion-title {
  margin-left: 0px !important;
  padding-left: 0px;
}
.home-revenue-card__title {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  color: var(--ion-color-dark);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  height: 30px;
  border-radius: nullpx;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.home-revenue-card__view_all_item {
  color: var(--ion-color-primary);
  font-size: 13px !important;
  margin-left: 10px;
}
@media screen and (max-width: 297px){
  .point-status__info__imgs img{
    width: 30px;
    height: 30px;
  }
  .point-status__info__imgs__remaining-container {
    height:30px !important;
  }

  .point-status__info__imgs__remaining-container__remaining {
    font-size: 12px;
  }
 }

 @media screen and (max-width: 370px){ 
  .profile-status__info__img.profile-status__info__img--elite {
    width: 45px;
    height: 45px;
  }
    .profile-status__info__img.profile-status__info__img--elite img {
      height: 37px;
    width: 37px;
    }

    .profile-status__info__img-elite-badge {
      width: 38px !important;
      height: 38px !important;
    }

    .profile-status__info__img-container {
    }

     .profile-status__info__img-container.profile-status__info__img-container--elite::before {
      top: 20% !important;
      height: 10px !important;
     }

     .profile-status__info__elite-img {
      height: 43px !important;
     }
 }

 .point-status__info__imgs__remaining-container {
  position: relative;
  text-align: center;
  height: 38px;
 }

 .point-status__info__imgs__remaining-container__remaining {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
 }

 .profile-status__info__img-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 5px;
  height: 85px;
  align-items: flex-start;
 }

 .profile-status__info__img-elite-badge {
  width: 50px;
  height: 50px;
  z-index: 2;
 }

.profile-status__info__img-elite-badge--gray{
  filter: grayscale(100%);
}

 .home-point-status__info__see-details {
  color: var(--ion-color-primary);

 }

 .profile-status__info__img-container.profile-status__info__img-container--elite::before {
  content: '';
background-color: #bcd5eb;
height: 15px;
position: absolute;
width: 100%;
z-index: 0;
top: 25%;
  left: 0;
}

.profile-status__info__img.profile-status__info__img--elite {
  z-index: 2
}

.profile-status__info__elite-img{
  height: 68px;
  display: flex;
}

.profile-status__info__elite-img img{
  align-self: center;
}