.edit-profile {
  width: 100%;
  height: 100%;
  padding: 20px 20px 30px 20px;
  max-width: 480px;
}

.measurement__label{
  display: flex !important;
justify-content: center;
align-items: flex-end;
margin-bottom: 10px;
}

.measurement__labels .edit-profile__titles h3 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.edit-profile__titles h3 {
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.2;
  font-size: 22px;
  color: var(--ion-color-dark);
}
.edit-profile__titles #address {
  padding-top: 60px; 
  margin-top: -60px;
}

.edit-profile__about .edit-profile__titles ion-icon {
  vertical-align: middle;
  margin-left: 5px;
}

.edit-profile ion-item {
  --border-width: 0px 0px 1px 0px;
  --inner-border-width: 0px;
  --border-color: #dbd3d3;
  /* margin-right: 45px; */
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --inner-padding-top: 0px;
  /* --inner-padding-bottom:20px; */
}

.edit-profile__header{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.edit-profile__header__title {
  font-weight: 700;
  margin-top: 0;
  color: var(--ion-color-dark)
}

.edit-profile__header__save {
  color: var(--ion-color-primary);
  font-weight: 700 !important;
}

.edit-profile__error--msg {
  text-align: right;
}
/* file upload ################# */

.edit-profile__file-upload-section {
  border-bottom: 1px solid #dbd3d3;
  /* margin-right: 45px; */
}
.ep__file-upload {
  /* margin-top: 20px; */
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
  column-gap: 11px;
}
.edit-profile__headshot_label{
  position: absolute;
  font-size:14px;
font-weight: 600;
letter-spacing: 0px;
text-align: left;
margin-bottom: 13px;
}
.edit-profile__bodyshot_label{
  position: absolute;
  left: 36%;
  font-size:14px;
font-weight: 600;
letter-spacing: 0px;
text-align: left;
margin-bottom: 13px;
}
.ep__file-upload__widgets {
  width: 31%;
  height: 138px;
  position: relative;
  /* margin-left: -5px; */
}
.ep__file-upload__preload {
  /* width: 105px; */
  height:120px;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.ep__file-upload__preload_close {
  color: var(--ion-color-primary);
  position: absolute;
  right: 5px;
  top: 23px;
  font-size: 20px;
}

.ep__file-upload-widget-label {
  padding: 2px 10px;
  position: absolute;
  font-family: var(--baseFontFamily);
  font-size: 12px;
  border-radius: 7px;
  top: 26px;
  text-align: center;
  z-index: 1;
  width: fit-content;
  left: 9px;
  text-align: center;
  width: 100px;
}
.ep__file-upload-widget-label-secondary {
  background-color: #ffffff;
  color: var(--ion-color-medium);
  font-weight: 500;
}

.ep__file-upload-widget-label-primary {
  background-color: var(--ion-color-primary);
  color: white;
}

.basic-info__check-icon {
  width: 16px;
  right: 0px;
  position: relative;
  float: right;
  top: 0px;
  left: 0%;
  margin-left: 5px;
}

.ep__field-required::after {
  content: "*";
  color: var(--ion-color-primary);
}

.ep__file-required::after {
  content: "*";
  font-size: 10px;
}

.ep__file-upload__required {
  width: 100%;
  color: var(--ion-color-primary);
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 25px;
}

.ep__file-upload__widgets
  .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner
  i {
  top: 55%;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 25px;
}
.ep__file-upload__widgets
  .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner::after {
  /* content: "110 X 110"; */
  font-size: 14px;
  position: relative;
  top: 60%;
  color: var(--ion-color-secondary-shade);
}
.ep__file-upload__additional_photo{
  margin-left: 4px;
}
.additional_photo__h3 {
font-size:14px;
font-weight: 600;
letter-spacing: 0px;
text-align: left;
color: var(--ion-color-dark);
margin-top: 24px;
margin-left: -9px;
margin-bottom: -13px;

}
.ep__file-upload__additional_photo .fileuploader.fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item .fileuploader-item-inner {
	border: 0;
	border-radius: 8px;
}
.ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
.ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
  position: relative;
  display: inline-block;
  margin: 16px 0 0 6px;
  padding: 0;
  vertical-align: top;
  width: 118px ;
  height: 127px;
  border-radius: 8px;
  /* padding-top: 20%; */
}
.ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input::after{
  content: "Additional";
  font-size: 12px;
  position: relative;
  top: 9px;
  left: 10px;
  color: var(--ion-color-secondary-shade);
  background-color: white;
  padding: 4px 19px;
  border-radius: 7px;
  text-align: center;
}
.ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items .fileuploader-items-list {
	text-align: left;
}
.ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input-inner i{
  top: 55%;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 25px;
}

.basic-info__input {
  width: 100%;
  text-align: end;
  color: var(--ion-color-medium-tint);
  font-size: 16px;
  border: 0px;
}

.basic-info__label {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
}

.gender__label {
  font-size: 13px;
  color: var(--ion-color-medium-shade);
  margin-left: 20px;
}
.edit-profile__hair-color,
.edit-profile__eye-color,
.edit-profile__skin-color {
  border-bottom: 1px solid #dbd3d3;
  /* margin-right: 45px; */
}
.hair-color__items,
.eye-color__items,
.skin-color__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.hair-color__item,
.eye-color__item,
.skin-color__item {
  width: 49px;
  margin: 5px 2px;
  margin-bottom: 24px;
  text-align: center;
}
.color-item-type {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-bottom: 8px;
  
}
.hair-color__label,
.eye-color__lable,
.skin-tone__lable {
  font-size: 13px;
  color: var(--ion-color-medium);
}
.edit-profile__check img {
	position: relative;
	z-index: 99;
	top: 10px;
	right: 1px;
}
.brown-color {
  background-color: #8b4513;
}
.blonde-color {
  background-color: #e4d070;
}
.red-color {
  background-color: #b06500;
}
.black-color {
  background-color: #222428;
}
.grey-color {
  background-color: #808289;
}
.blue-color {
  background-color: #1c578d;
}
.green-color {
  background-color: #436d53;
}
.fair-color {
  background-color: #f3cfbb;
}
.light-color {
  background-color: #d9a689;
}
.tanned-color {
  background-color: #915839;
}
.light-borwn-color {
  background-color: #8a390c;
}
.light-black-color {
  background-color: #3e2920;
}
.other-color {
  background-color: #e1cece6b;
  border: 1px solid #4854e0;
}
.hazel-color {
  background-color: #c9c789;
}
.dark-brown-color {
  background-color: #542a0e;
}
.edit-profile__measurement {
  width: 100%;
}
.measurement__labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}
.measurement__label {
  font-size: 14px;
  color: var(--ion-color-medium-tint) !important;
  margin-top: 19px;
  margin-right: 10px;
}
.measurement__range-input {
  --knob-background: var(--ion-color-primary);
  --bar-border-radius: 6px;
  --bar-height: 8px;
  --bar-background: #fe61024a;
}
.about__section {
  width: 100%;
  padding-bottom: 20px;
}
.about__textarea ion-textarea {
  border: 1px solid #ddd;
  border-radius: 12px;
  --placeholder-opacity: 0.3;
  padding-left:10px;
}
.experience__title {
  position: relative;
  
  
}

.experience__title div {
  display: flex;
}

.experience__title ion-icon {
  position: relative;
  left: 0;
  top: 4px;
  font-size: 18px;
  width: 18px;
  padding-left: 5px;
}

.experience__title h4 {
  width:325px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--ion-color-dark);
}
.experience__title small {
  color: var(--ion-color-medium-tint);
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  width: 335px;
}
.edit-profile__experience ion-item {
  --padding-bottom: 15px;
}
.edit-profile__experience ion-button {
  --padding-start: 0.1em;
  margin-left: 0px;
  margin-top: 13 px;
}
.edit-profile__experience ion-button h1 {
  padding-right: 6px;
  font-size: 30px;
  margin-bottom: 21px;
  font-weight: normal;
}
.interest__section {
  width: 100%;
}
.interest__section .form-group__input-styles {
  max-width: 100%;
}
.interest__section__list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.edit-profile__interest ion-item {
  --padding-bottom: 15px;
}
.interest-section__input {
  border: 1px solid #ddd;
  border-radius: 12px;
  --padding-start: 20px;
  --placeholder-opacity: 0.3;
}
.interest-section__badge {
	border-radius: 6px;
	--background: var(--ion-color-light-shade);
	--color: var(--ion-color-medium);
	margin: 7px 2px;
	font-size: 11px;
	--padding-bottom: 9px;
	--padding-end: 8px;
	--padding-start: 8px;
	--padding-top: 0px;
	vertical-align: middle;
}
.interest-section__badge img{
  position: relative;
  top: 7px;
  left: 5px;
}


.social-media__items {
  /* margin-right: 29px; */
}
.social-media__items ion-card {
  margin-inline-start: 0px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid rgba(20, 0, 0, 0.12);
  border-radius: 12px;
  margin-bottom: 25px;
  width: 100%;
}
.social-media__items > ion-card > ion-item {
  --border-width: 0px;
  margin-right: 10px;
}
.social-media__items ion-card img {
  background-color: var(--ion-color-light-tint);
  padding: 5px;
  border-radius: 8px;
  margin-right: 8px;
  margin-left: 5px;
}

.social-media__items__twitter-icon {
  width: 34px;
}

.social-media__items__tiktok-icon {
  width: 34px;
}

.social-media__label {
  color: var(--ion-color-dark);
  margin-right: 3px;
}
.social-media__svg_icon {
  background-color: var(--ion-color-light-tint);
  padding: 5px;
  border-radius: 8px;
  margin-right: 8px;
  margin-left: 5px;
}
.social-media__label-orange {
  font-size: 16px;
  color: var(--ion-color-primary);
  vertical-align: middle;
  margin-left: 3px;
  padding: 10px 0px;
}
.social-media__label-grey {
  font-size: 16px;
  color: var(--ion-color-medium-shade);
  vertical-align: middle;
  margin-left: 3px;
  padding: 10px 0px;
}

.video__thumnail img {
  padding-left: 10px;
  padding-right: 10px;
  /* border-radius: 25px;   */
}
@media (min-width: 801px) {
  .ep__file-upload {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .ep__file-upload__widgets {
    width: 118px;
    height: 130px;
  }
 
}
@media screen and (max-width: 390px) {
  .ep__file-upload__preload {
    /* width: 81px; */
    height: 120px;
  }
  .ep__file-upload__widgets {
    width: 31%;
    height: 130px;
  }
  .ep__file-upload-widget-label {
    padding: 2px 3px;
    width: 85px;
  }
  .ep__file-upload__preload_close {
    right: 4px;
    top: 20px;
  }
  .hair-color__item,
  .eye-color__item,
  .skin-color__item {
    width: 40px;
    margin: 5px 2px;
  }
  .color-item-type {
    width: 40px;
    height: 40px;
    
  }
  .edit-profile__check img {
    width: 20px;
  }
  .ep__file-upload__additional_photo {
    margin-left: 9px;
  }
  .ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input::after{
    font-size: 11px;
    padding: 4px 11px;
    left: 5px;
  }
 
  .ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item  {
  width: 31%;
  height: 120px;
}
.experience__title h4 {
  width: 277px;
  font-size: 15px;
}
.experience__title small {
  width: 277px;
}
}
.edit-profile__spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.edit-profile__spinner ion-spinner {
  width: 70px;
  height: 70px;
}
@media screen and (max-width: 320px) {
  .ep__file-upload-widget-label {
    padding: 2px 3px;
    width: 68px;
  }
  .ep__file-upload__widgets {
    width: 31%;
    height: 105px;
  }
  .ep__file-upload__preload {
   
    height: 100px;
  }
}
@media screen and (max-width: 295px) {
 
  .ep__file-upload__preload {
    width: 72px;
    height: 85px;

  }
  .ep__file-upload__widgets {
    width: 75px;
    height: 90px;
  }
  .ep__file-upload__preload_close {
    right: 3px;
    top: 20px;
  }
  .ep__file-upload-widget-label {
    padding: 2px 3px;
    width: 60px;
    font-size: 10px;
  }
  .hair-color__item,
  .eye-color__item,
  .skin-color__item {
    width: 44px;
    margin: 5px 5px;
  }
  .color-item-type {
    width: 35px;
    height: 35px;
  }
  .edit-profile__check img {
    width: 17px;
  }
  .ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input::after{
    font-size: 10px;
    padding: 4px 10px;
  }
  .ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .ep__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
  width: 72px;
  height: 86px;
}
.experience__title h4 {
  width: 245px;
  font-size: 15px;
}
.experience__title small {
  width: 245px;
}
}
