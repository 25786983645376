.info-button-popover {
  --backdrop-opacity:0.7;
  
}

.info-button-popover .popover-content {
  width: 90vw;
  border-radius: 12px !important;
}

.info-button-popover__heading {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
}

.info-button-popover__close { 
  position: absolute;
  right: 9px;
  width: 20px !important;
  height: 20px !important;
}