.deposit_form{
    margin: 0;
    padding: 20px 16px;
}

.deposit_form__account_type{
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-start: 0;
  --padding-end: 0;
  --min-height:38px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-dark);
}
.deposit_form__account_type ion-radio{
  margin-right: 12px;
}

.deposite_from__imp_note  h2{
    font-size: 16px;
    font-weight: bold;
    color: var(--ion-color-dark);
}
.deposite_from__imp_note ion-text{
    text-align: left;
    font-size: 14px;
    color: var(--ion-color-medium-tint);
}


.deposit_form__submit--btn{
    margin-top: 20px;

}

