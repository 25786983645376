.add_location {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.add_location__submit_btn {
  margin-top: auto;
}
.add_location__get_current_loc {
  width: 165px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}
.add_location__get_current_loc img{
    margin-right: 8px;
}

.add_location__list{
  padding: 20px 16px;
  height: 100%;
}
.add_location__list_items ion-item{
  --border-color: #c8c7cc;
}
.add_location__open_form{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 0;
}
.add_location__open_form img{
  margin-right: 8px;
}