.login-input-styles {
  border: 1px solid #ddd;
  border-radius: 12px;
  --padding-start: 20px;
  --placeholder-opacity:0.3;
  /* margin-top: 5px; */
}

.login-input-styles-error {
  border: 1px solid red;
  border-radius: 12px;
  --padding-start: 20px;
}

.item-inner {
  flex-direction: row-reverse !important;
}

.login-sub-text-3 {
  color: var(--ion-color-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 12px;
}
.login-checkbox-style {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.login-remember-style {
  font-weight: 300;
}

.login-check {
  display: flex;
  align-items: center;
}
.login-sub-text-4 {
  color: var(--ion-color-secondary);
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
.login-button ion-button{
  margin-bottom: 12px;
  --border-radius:11px;
}


.login-forgot {
  margin-bottom: 12px;
}

.login__lost-pass-link {
  color: var(--ion-color-primary);
  /* font-size: 12px; */
  /* padding-left: 0px; */
  padding: 0;
  cursor: pointer;
}

.login__lost-pass-link:hover {
  text-decoration: underline;
}

.login-worning-text {
  text-align: center;
  color: var(--ion-color-danger);
  font-size: 14px;
}

.control-label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #231f20;
}

.login-email {
  margin-bottom: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.form-login {
  /* background-color: yellow; */
}

.select-property {
  margin-bottom: auto;
  color: black;
}
.select-property .title {
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  padding-top: 25px;
  padding-bottom: 20px;
  color: black;
}
.select-property ion-label {
  /* color: black !important; */
  font-size: 16px !important;
  line-height: 30px !important;
}

.wrong-credentials {
  text-align: center;
  color: #FA3D28;
}

.check-icon {
  position: absolute;
  top: 47px;
  z-index: 99;
  right: 15px;
  color: #FE6102;
}

.login-headings__login-text {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  line-height: 20px;
  font-weight: 500;
}

.login__section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-headings__login-header {
  font-weight: bold;
}

.login__section__inputs{
  margin-top: 15px;
  margin-bottom: 15px;
}

.password-container{
  display: inline-table;
  width: 100%;
}
.login__section__other_links{
  display:  flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.other_links_align_left{
  text-align: left;
}
.other_links_align_right{
  text-align: right;
}
.one_time_passcode_msg{
  text-align: center;
  color: var(--ion-color-success-tint);
  font-size: 16px;
  margin-top: 40px;
  
}
.other_links__disabled{
  pointer-events: none;
  opacity: 0.5;
}

.login__policy-section {

}

.login__policy-section a {
  color: #808289;
}