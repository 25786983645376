.internal_talent_ref {
  padding: 20px 16px;
  height: 100%;
  width: 100%;
  max-width: 480px;
}
.internal_talent_ref__card {
  margin: 0px 0px 20px 0px;
  border: 1px solid #e9ebf0;
  box-shadow: 0px 2px 4px rgba(55, 18, 0, 0.04);
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  padding: 0;
}

.internal_talent_ref__card__grid {
  padding: 11px 11px 0px 11px;
  position: relative;
  
  
}
.internal_talent_ref__card__grid__row{
	border-bottom: 1px solid #F5F5F7;
}
.internal_talent_ref__card__avatar {
  width: 48px;
  height: 48px;
}
.card__avatar_plus {
  width: 16px;
  height: 16px;
  position: relative;
  bottom: 22px;
  left: 37px;
}
.internal_talent_ref__card__left_col{
	padding-bottom: 0;
}
.internal_talent_ref__card__right_col {
  padding-left: 14px;
  padding-bottom: 0;
}
.internal_talent_ref__card__name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin-top: 0;
  margin-bottom: 8px;
}
.ref_event_list__text {
	padding-left: 16px;
	margin-top: 16px;
font-size: 15px;
font-weight: 500;
letter-spacing: 0em;
text-align: left;

}
.internal_talent_ref__badge {
	--background: #fff0e6;
	--color: var(--ion-color-primary);
	font-weight: normal;
	font-size: 14px;
	--padding-end: 10px;
	--padding-start: 10px;
	--padding-top: 6px;
	--padding-bottom: 6px;
	margin-right: 5px;
	margin-bottom: 8px;
	margin-top: 8px;
	border-radius: 6px;
  }

  .internal_talent_ref__badge__orange {
	--background: #fff0e6;
	--color: var(--ion-color-primary);
  }
  .internal_talent_ref__badge__blue {
	--background: #e6f7ff;
	--color: #20b9fc;
  }

.ref_event_list__grid {
  padding: 11px;
}
.ref_event_list__row {
  margin-bottom: 16px;
  border-bottom: 1px solid #F5F5F7;
  padding-bottom: 8px;
}
.ref_event_list__img {
  height: 48px;
  width: 48px;
  border-radius: 6px;
  object-fit: cover;
}
.ref_event_list__event_name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin-top: 0;
  margin-bottom: 8px;
}

/* .accordion code  */
.internal_talent_ref__accordion {
  cursor: pointer;
  width: 100%;
  transition: 0.4s;
}

.internal_talent_ref__accordion:after {
  content: url("../../../assets/images/common/down_arrow.png");
  position: absolute;
  bottom: 77%;
  left: 93%;
}

.active:after {
  content: url("../../../assets/images/common/up_arrow.png");
}

.internal_talent_ref__panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  
  
}

@media screen and (max-width: 320px) {
   
      .internal_talent_ref__card__badges{
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
      }
  }