.talent-signup {
  padding: 30px 20px;
  height: 100%;
}

.talent-signup form {
  height: 100%;
}

.talent-signup__section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lay__talent-signup--goldsgym1 .talent-signup__section.verification-code {
  height: auto;
  min-height: 100vh;

}

.talent-signup h1 {
  font-weight: bold;
}

.talent-signup__section-title {
  padding-bottom: 15px;
}
.talent-signup__section-description {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  line-height: 20px;
  font-weight: 500;
}
.mobile .talent-signup__section-description {
  padding-top: 40px;
}

.section-footer {
  margin-top: auto;
}

.talent-signup__check-icon {
  width: 16px;
  right: 0px;
  position: relative;
  float: right;
  top: -25px;
  left: 90%;
  /* display: none; */
}

.mobile__terms {
  display: flex;
  flex-direction: row;
}
.talent-signup-checkbox {
  vertical-align: middle;
}
.mobile__terms ion-label {
  font-size: 14px;
  padding-left: 10px;
  color: var(--ion-color-secondary-shade);
}
.section-next-button {
  margin-top: 30px;
}

.take-photo-button {
  margin: 20px 0; 
}

.verification-code__mobile-number {
  color: var(--ion-color-primary);
}
.verification-code__options {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  cursor: pointer;
}

.verification-code__options ion-icon {
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-top: 8px;
}
.verification-code__option {
  color: var(--ion-color-primary);
  font-size: 14px;
  font-weight: 600;
  padding: 0px;
  margin-bottom: 10px;
  width: 210px;
}
.verification-code__option img {
  margin-right: 10px;
  vertical-align: middle;
}
.verification-code__option:active {
  text-decoration: underline;
  cursor: pointer;
}
.verification-code__promotion {
  display: flex;
  flex-direction: row;
}
.promotion__descrition {
  padding-left: 10px;
}
.promotion__descrition-1 {
  color: var(--ion-color-dark);
  padding-bottom: 10px;
  font-size: 0.9rem;
}
.promotion__descrition-2 {
  color: var(--ion-color-medium-tint);
  text-align: left;
  font-size: 13px;
}

.full-name__padding-bottom {
  padding-top: 5px !important;
}
.full-name .talent-signup__section-description span {
  font-weight: bold;
  color: var(--ion-color-medium);
}
.full-name .talent-signup__section-description {
  padding-top: 20px;
}

.basic-information .talent-signup__section-description {
  padding-top: 5px;
}
.basic-information .form-group__input-styles{
text-align: left;
height: 42px;
}
.basic-information__birth--date{
  /* position: absolute; */
  /* z-index: 99;
  top: 28px; */
  transform: translatez(0);
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 12px;
}

.street-address .optional-text {
  color: var(--ion-color-medium-tint);
  font-weight: normal;
  font-size: 13px;
}

.email-subcription {
  display: flex;
  flex-direction: row;
}
.email-subcription .talent-signup__section-description {
  margin-left: 10px;
}

.create-password .talent-signup__section-description {
  padding-top: 5px;
}
.create-password__guidelines {
  margin-top: 10px;
}
.password__guideline {
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-medium);
  --padding-bottom: 7px;
  --padding-end: 7px;
  --padding-start: 7px;
  --padding-top: 7px;
  margin: 3px 10px;
}
.create-password__eye-icon {
  position: absolute;
  top: 38px;
  z-index: 99;
  right: 15px;
  color: #fe6102;
}
.take-selfie__photo-guideline ion-card {
  padding: 5px 15px;
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-medium);
  font-weight: 500;
  font-size: 0.9rem;
}
.take-selfie__file--uploader {
  display: flex;
margin: 60px auto;
justify-content: center;
border: solid 3px var( --ion-color-primary);
padding: 3px 6px;
height: 269px;
width: 269px;
border-radius: 50%;
background-color: #d462284f;
}
.take-selfie__file--uploader .fileuploader-theme-avatar{
  margin: 0px;
  padding-left: 0px;
}
.zip-code__use-location-btn {
  margin: 0px;
  padding: 0px;
}
.zip-code__use-location-btn ion-button {
  --padding-end: 0px;
  --padding-start: 0px;
  margin-left: 0px;
  font-size: 16px;
  font-weight: normal;
}
.zip-code__use-location-btn ion-button img {
  margin-right: 10px;
}
.birth_date{
  height: 53px;
  width: 100%;
  background-color: white;
  background: transparent;
  text-align: left;
  text-align: -webkit-left;
  display: -webkit-inline-flex;
  
}

.talent-signup-container .header-md.header-collapse-none {
  padding: 0 18px;
}

ion-content.talent-signup-container {
  --keyboard-offset: 0 !important;
}

.talent-signup .fileuploader-menu {
  display: none !important;
}

.lay__talent-signup--container--goldsgym1 {
  /* --background: white; */
  color: white;
  font-family: 'Alt Gothic ATF';
}

.lay__talent-signup--goldsgym1 {
  /* margin-top: 50px; */
  height: auto;
  min-height: 100vh;
  background-image: url("../../../assets/consumer/goldsgym/background.png");
  background-size: 100% 100%;    /* Scale to 100% of the container's height */
  background-position: center;   /* Horizontally center the image */
  background-repeat: no-repeat;  /* Prevent the image from repeating */
  display: flex;
  flex-direction: column;
}

.lay__talent-signup--goldsgym1 .sn__id1 {
  margin-top: 98px;
}

.lay__talent-signup--container--goldsgym1.talent-signup-step-2 {
  --background: white;
}

.lay__talent-signup--container--goldsgym1.step-share-selfie {
  --background: white;
}

.lay__talent-signup--container--goldsgym1.step-upload-photo {
  --background: white;
}

.lay__talent-signup--container--goldsgym1 .talent-signup h1 {
  color: white;
}


.lay__talent-signup--container--goldsgym1 ion-button.actionlink__cta,
.lay__talent-signup--container--goldsgym1 ion-button.actionlink__cta:hover {
  text-transform: none;
  --background: none !important;
  --background-hover: none !important;
  --background-focused: none !important;
  --background-activated: none !important;
  color: #ffd600;
  font-family: 'Alt Gothic ATF';
  text-decoration: underline;
}

.lay__talent-signup--container--goldsgym1 .lay__logo {
  color: white;
  height: 60px;
  top: 25px;
}

.lay__talent-signup--container--goldsgym1 .form-group__label,
.lay__talent-signup--container--goldsgym1 .take-test-question__label {
  color: white !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 26px;
  font-family: 'BebasNeue-Regular';
}

.lay__talent-signup--container--goldsgym1 .form-group__input-styles,
.lay__talent-signup--container--goldsgym1 .login-input-styles-error,
.lay__talent-signup--container--goldsgym1 .form-input-styles-error {
  border-radius: 5px;
  border-color: #ffd600;
  background-color: transparent;
}

.lay__talent-signup--container--goldsgym1.talent-signup-step-2 .form-group__input-styles,
.lay__talent-signup--container--goldsgym1.talent-signup-step-2 .login-input-styles-error,
.lay__talent-signup--container--goldsgym1.talent-signup-step-2 .form-input-styles-error {
  background-color: white;
}


.lay__talent-signup--container--goldsgym1 .sn__content2 {
  color: #ffd600;
  text-transform: uppercase;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.lay__talent-signup--container--goldsgym1 ion-button,
.lay__talent-signup--container--goldsgym1 ion-button:hover {
  --background: #ffd600 !important;
  --background-hover: #ffd600 !important;
  --background-focused: #ffd600 !important;
  --background-activated: #ffd600 !important;
  color: black;
  width: 200px;
  /* margin: 0 auto; */
  margin-left: auto;
  margin-right: auto;
  font-family: 'BodoniStdPoster';
  font-size: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  letter-spacing: 2px;
  --border-radius: 7px;
}

.lay__talent-signup--container--goldsgym1 .error-message {
  color: #ffd600;
}

.lay__talent-signup--container--goldsgym1 a {
  color: #ffd600;
  text-decoration: underline;
}

.lay__talent-signup--container--goldsgym1 .form-group input[type="text"],
.lay__talent-signup--container--goldsgym1 .form-group input[type="password"],
.lay__talent-signup--container--goldsgym1 .form-group input[type="tel"],
.lay__talent-signup--container--goldsgym1 .form-group input[type="number"],
.lay__talent-signup--container--goldsgym1 input[type="tel"].form-group__input-styles,
.lay__talent-signup--container--goldsgym1i nput[type="text"].form-group__input-styles {
  color: #ffd600;
  font-size: 20px;
}

.lay__talent-signup--container--goldsgym1 svg.checkbox-icon {
  border-color: #ffd600 !important;
  background: #ffd600 !important;
}

.lay__talent-signup--container--goldsgym1 ion-checkbox {
  --background: transparent;
  --border-color: #ffd600;
  --background-checked: #ffd600 !important;
  --border-color-checked: #ffd600 !important;
  --border-width: 3px;
}

.lay__talent-signup--container--goldsgym1 .input-type__checkbox {
  accent-color: #ffd600;
}

.lay__talent-signup--container--goldsgym1 .take-test-question__radio__container span {
  color: white;
}

.lay__talent-signup--container--goldsgym1 ion-radio, 
.lay__talent-signup--container--goldsgym1 ion-radio:checked {
  --color: #ffd600; /* Checked button color */
  --color-checked: #ffd600; /* Checked button color */
  --border-color: #ffd600; /* Unchecked button outline */
  --inner-border-width: 2px; /* Circle thickness */
}
.lay__talent-signup--container--goldsgym1.lay__taketest .take-test-question__label {
  color: #ffd600 !important;
  text-align: left;
}

.lay__talent-signup--container--goldsgym1 .verification-code__mobile-number {
  color: #ffd600;
}

.lay__talent-signup--container--goldsgym1 .verification-code__option {
  color: #ffd600;
}

.lay__talent-signup--container--goldsgym1 .verification-code__option img {
  display: none;
}

.lay__talent-signup--container--goldsgym1 .take-test-question {
  border-top: 0px;
}

.lay__talent-signup--container--goldsgym1 .take-test-question .form-group__input-styles {
  border: 1px solid white;
  --placeholder-color: white;
}

.lay__talent-signup--container--goldsgym1 .take-test-question .form-group__input-styles label {
  color: white;
}

.lay__talent-signup--container--goldsgym1 .take-test-question__checkbox__container {
  color: white;
}

.lay__talent-signup--container--goldsgym1 .take-test-question .take-test-question__label {
  font-family: 'Alt Gothic ATF';
  font-size: 22px;
  text-transform: unset;
  z-index: 10;
  position: relative;
}

.lay__talent-signup--goldsgym1.step-upload-photo--final-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.lay__talent-signup--container--goldsgym1 .section-footer,
.lay__talent-signup--container--goldsgym1 .take-tast-content {
  z-index: 10;
}

.lay__talent-signup--container--goldsgym1 .mobile__terms {
  flex-direction: column;
  align-items: center;
}

.lay__talent-signup--container--goldsgym1 .mobile__terms ion-label {
  color: #ffd600;
  padding-left: 0px;
  font-size: 17px;
  text-align: justify;
}

.lay__talent-signup--container--goldsgym1 .mobile__terms ion-checkbox {
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.lay__talent-signup--container--goldsgym1 ion-button.upload-photo__submit-btn,
.lay__talent-signup--container--goldsgym1 ion-button.upload-photo__submit-btn:hover {
  --background: #ece614 !important;
  --background-hover: #ece614 !important;
  --background-focused: #ece614 !important;
  --background-activated: #ece614 !important;
  color: #1fc19a;
}

.lay__talent-signup--container--goldsgym1 .image-received {
  color: #ece614;
  font-size: 40px;
}

.lay__talent-signup--container--goldsgym1 .talent-signup__section-title {
  max-width: 350px;
  width: 85%;
  margin: 0 auto;
}

.lay__talent-signup--container--goldsgym1.step-share-selfie .consumer-uploaded-photo {
  position: absolute;
  /* transform: rotate(4deg); */
  margin-top: 67px;
  object-fit: cover;
  object-position: top;
  width: 209px;
  max-height: 233px;
  margin-left: -7px;
}