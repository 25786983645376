.chat-container {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.no-user-chats {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  margin-top: 50%;
}
.no-user-chats__img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.no-user-chats__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
  margin-bottom: 10px;
}
.no-user-chats__text {
  color: var(--ion-color-medium-shade);
}
.chate-list-item {
  margin-top: 20px;
  box-shadow: 0px 2px 4px 0px rgba(55, 18, 0, 0.04);
  border: 1px solid rgba(233, 235, 240, 1);
  border-radius: 12px;
  --inner-padding-start: 0px;
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 8px;
  --padding-bottom: 8px;
}
.chate-list-item__img {
  height: 48px;
  width: 48px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
}
.chate-list-item__detail {
}
.chate-list-item__detail__items {
  display: flex;
  width: 100%;
  text-align: start;
}
.chate-list-item__detail__name {
  font-size: 16px;
  color: var(--ion-color-dark);
  /* width: 60%; */
}
.chate-list-item__detail__badge {
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-medium-shade);
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  margin-left: 5px;
  border: 6px;
  --padding-bottom: 0px;
  --padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chate-list-item__detail__msg {
  color: var(--ion-color-dark);
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.chate-list-item__detail__dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--ion-color-primary);
  margin-left: 6px;
}

