.opportunity-view__header{
  max-height: 500px;
  overflow: hidden;
}

.opportunity-view__header__top-toolbar {
  z-index: 99;
  position: absolute;
  top: 0;
}

.opportunity-view__header__bottom-toolbar {
  z-index: 99;
  position: absolute;
  bottom: 5px;
}

.opportunity-view__header__waitlist-badge {
  background-color: #afb1b1;
  border: 2px solid white;
  float: right;
  line-height: 15px;
  margin-right: 5px;
  
}

.opportunity-view__header > img {
  max-width: 100%;
  border: 0;
  width: 100vw;
  object-fit: cover;
}
.opportunity-view__header__toolbar__back-btn {
  background: white;
  border-radius: 50px;
 
}

.opportunity-view__header .auto-book-placeholder {
  float: right;
  margin-right: 5px;
}

.opportunity-view__header__menu-btn {
  --padding-start: 0px;
  --padding-end: 0px;
  border-radius: 50%; 
  background: white;
  width: 48px;
  height: 48px;
}

.opportunity-view__header__menu-btn.opportunity-view__header__menu-btn--virtual-casting,
.opportunity-view__header__toolbar__back-btn.opportunity-view__header__toolbar__back-btn--virtual-casting {
  background: transparent;
}

.opportunity-view__header__menu-btn img {
  width: 24px;
  height: 24px;
}

.opportunity-view-content ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
}
.opportunity-view-content ion-text {
  color: var(--ion-color-medium-shade);
}
.opportunity-view__event {
  padding-top: 0px !important;
}
.opportunity-view__event__booked {
  margin-top: 16px;
  display: grid;
  grid-template-columns:min-content min-content min-content ;
  grid-row-gap: 7px;
}
.opportunity-view__event__booked ion-badge {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-dark);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  margin-right: 5px;
}
.opportunity-view__event__booked ion-badge span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 3px;
}

.opportunity-view__event__name h2 {
  font-weight: bold;
  --color: var(--ion-color-dark);
  font-size: 20px;
}
.opportunity-view__event__status img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 15px;
}
.opportunity-view__event__status ion-text {
  font-size: 18px;
  color: var(--ion-color-dark);
}
.opportunity-view__notice ion-card {
  --background: #fff0e6;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  padding: 12px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
}
.opportunity-view__notice ion-title {
  font-weight: 700;
  color: var(--ion-color-primary);
  padding-inline-start: 0;
  padding-bottom: 10px;
}
.opportunity-view__notice ion-text {
  color: var(--ion-color-primary);
  margin-bottom: 15px;
}
.opportunity-view__notice__timing {
  margin-bottom: 15px;
}
.opportunity-view__notice__timing__date {
  font-weight: bold;
  margin-bottom: 10px;
  --color: var(--ion-color-dark-shade) !important;
}
.opportunity-view__notice__timing__time {
  --color: var(--ion-color-medium) !important;
}
.opportunity-view__notice__timing__pay {
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
  font-size: 18px !important;
  font-weight: bold;
}

.html-editor-field p,
.html-editor-field ul  {
  margin: 0;
}

.html-editor-field ul br {
  height: 0;
  display: none;
}

.opportunity-view__notice__timing__points-multiplied {
  width: 56px;
  height: auto;
}

.opportunity-view__types ion-badge {
  --color: var(--ion-color-primary-contrast);
  --padding-bottom: 8px;
  --padding-end: 13px;
  --padding-start: 13px;
  --padding-top: 8px;
  border-radius: 6px;
}
.opportunity-view__shift__est-total {
  color: var(--ion-color-primary);
  font-size: 17px;
  font-weight: bold;
}

.opportunity-view__shift__hr-rate ion-badge{
  --color: var(--ion-color-dark);
  --padding-bottom: 5px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 5px;
  border-radius: 4px;
  --background: var(--ion-color-success-contrast);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.12) !important;
}
.opportunity-view__shift__grid{
  padding: 0;
}
.opportunity-view__shift__grid ion-col{
  padding-left: 0;
  padding-right: 0;
}
.opportunity-view__upgrade{
  padding-top: 16px;
  padding-bottom: 16px;
}
.opportunity-view__accpets {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  column-gap: 16px;
}
.opportunity-view__accpets ion-button {
  --padding-start: 3rem;
  --padding-end: 3rem;
  width: 100%;
}
.opportunity-view__accpects_decline {
  --background: var(--ion-color-light-shade);
  --color: var(--ion-color-dark);
}
.opportunity-view__accpects_apply {
 width: 100%;
}
.opportunity-view__event,
.opportunity-view__notice,
.opportunity-view__types,
.opportunity-view__requrement {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.opportunity-view__additional-info {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--ion-color-light-shade);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.opportunity-view__types ion-title,
.opportunity-view__requrement ion-title {
  font-weight: bold;
  --color: var(--ion-color-dark);
  padding-left: 0px;
  margin-left: 0xp;
}
.opportunity-view__requrement__read_more {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-primary);
}
.opportunity-view__requrement__read_more ion-icon {
  font-size: 13px;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 320px){
  .opportunity-view__shift__hr-rate ion-badge{
    font-size: 13px;
  }
  .opportunity-view__shift__date ion-label{
    font-size: 15px; 
    margin-left: 5px;
  }
  .opportunity-view__shift__grid .form-group__checkbox-styles{
    --size:18px;
  }
}
@media screen and (max-width: 290px){
  .opportunity-view__shift__hr-rate ion-badge{
    font-size: 11px;
  }
  .opportunity-view__shift__date ion-label{
    font-size: 11px; 
    margin-left: 5px;
  }
 
}

.opportunity-view__shift__decline-info {
  display: flex;
  align-items: flex-start;
  column-gap: 7px;

}

.opportunity-view__shift__decline-info img {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}

.opportunity-view--disabled { 
  filter: grayscale(100%);
}

.opportunity-view-content .orange-border {
  font-size: inherit;
}

.opportunity-view-content__content--margin {
  margin-left: 16px;
  margin-right: 16px;
}

.opportunity-view-content ion-tab-bar {
  position: sticky;
  bottom:0 ;
}

.opportunity-view__types__container ion-badge {
  margin-right: 5px;
}

.opportunity-view__notice__timing__pay span p {
  font-size: inherit;
}

/**
* The first paragaph from the text editor should not include the margins
*/
.opportunity-view__requrement ion-text div > p {
  margin: 0;
} 

.opportunity-view__referral-buttons {
  flex-direction: column;
}

.opportunity-view__notice__timing {
  display: flex;
  align-items: flex-start;
}