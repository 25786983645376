.landing__container {
  background-color: #282c2f;
  grid-template-columns: auto;
  grid-template-rows: 50px 5em min-content;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: scroll;
}
.landing__container-items{
  width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: space-between;
padding-top: 20px;
}

.landing__container--sliders {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px);
  width: 100%;
}

.landing__container-inner-shadow {
  position: absolute;
  left: 5.26%;
  right: -41.34%;
  top: -16%;
  bottom: 42.62%;

  background: linear-gradient(
    357.15deg,
    #f8661b 50.41%,
    rgba(248, 102, 27, 0) 91.18%
  );
  opacity: 0.05;
  transform: matrix(0.94, 0.34, -0.34, 0.94, 0, 0);
}

.landing__container ion-grid,
.landing__container ion-col {
  padding-left: 0px;
  padding-right: 0px;
}


.landing__container-item-2 {
  padding: 15px 30px 0px 30px;
  display: flex;
  align-items: center;
}

.landing__slider_logo {
  display: flex;
  width: 74px;
  padding-top: 6px;
  margin-left: -10px;
  margin-right: 15px;
}

.landing__container-item-3 {
  padding-bottom: 20px;
}

.landing__s2 img {
  display: block;
}

.landing__container .splide__pagination {
  justify-content: left;
  margin: 0px;
  padding: 0px;
}
.btn-landing-group {
  margin: 15px 10px !important;
}

/* inifinity auto scrollber */
.landing__containner--slider1 {
  height: 246px;
  /* max-width: 800px; */
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.landing__containner--slider1 > div {
  height: 246px;
  width: 2526px;
  background: url("../../assets/images/landing/allbrand_pre.png");
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

.landing__containner--slider1 .slider1__mover-1 {
  animation: moveSlideshow 30s linear infinite;
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}

@supports (-webkit-touch-callout:none) {
  .landing__container-items {
    padding-top: 70px;
  }
}
