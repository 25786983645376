/* File uploader / cropping tool */
.fileuploader-popup {
  /* Hide the zoom bar, it was confusing */
  /* Hide the photo metadata, not needed */
  background-color: rgba(0, 0, 0, 0.5);
}
.fileuploader-popup .fileuploader-popup-preview {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background: #ffffff !important;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
@media (min-width: 768px) {
  .fileuploader-popup .fileuploader-popup-preview {
    max-width: 65%;
    max-height: 70%;
  }
}
.fileuploader-popup .fileuploader-popup-preview .fileuploader-popup-node.node-image {
  padding-bottom: 100px;
}
.fileuploader-popup .fileuploader-cropper {
  background: rgba(255, 255, 255, 0.65) !important;
}
.fileuploader-popup .fileuploader-popup-content ul li.fileuploader-popup-zoomer,
.fileuploader-popup .fileuploader-popup-footer .fileuploader-popup-tools li [data-action] {
  display: none;
}
.fileuploader-popup .fileuploader-popup-header {
  background-image: none !important;
}
.fileuploader-popup .fileuploader-popup-header ul.fileuploader-popup-meta {
  visibility: hidden;
}
.fileuploader-popup .fileuploader-popup-footer {
  background-image: none !important;
}
.fileuploader-popup .fileuploader-popup-footer .fileuploader-popup-tools li a {
  color: #003858 !important;
}
.fileuploader-popup .fileuploader-popup-footer .fileuploader-popup-buttons {
  padding-bottom: 15px !important;
  padding-right: 15px !important;
}

.fileuploader-popup .fileuploader-popup-header  .fileuploader-popup-buttons {
  padding-right: 15px;
}


.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area.moving.has-grid:before,
.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area.moving.has-grid:after,
.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area.moving.has-grid,
.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area.moving.has-grid {
  border: 0px !important;
}
.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area .point:after {
  background-color: #e6e7e8 !important;
  background: #e6e7e8 !important;
}
.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area.moving:before,
.fileuploader-popup .fileuploader-cropper .fileuploader-cropper-area.moving:after {
  opacity: 0 !important;
}
/* .fileuploader-popup .fileuploader-cropper .area-image {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  color: #fff !important;
} */

.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-buttons {
  position: fixed;
  left: 0;
  bottom: 16px;
  width: 100%;
  margin: 0;
  margin-right: 5px;
  text-align: right;
}

/* cropping tool buttons*/
.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-button.button-success {
  background-color: var(--ion-color-primary);
  background: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
  color: #ffffff;
  border-radius: 0.3rem;
  font-family: "Open Sans Regular", sans-serif !important;
  font-size: 16px;
  padding: 8px 17px 8px 17px;
}

.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-button[data-action="cancel"] {
  background-color: transparent;
  border: 2px solid #6d6e71;
  border-radius: 0px;
  color: #6d6e71;
  padding: 7px 17px 7px 17px;
}

.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-button.button-success:hover,
.fileuploader-popup .fileuploader-popup-header .fileuploader-popup-button[data-action="cancel"]:hover {
  transform: none;
}
/*  end of cropping tool buttons */
/* End of File uploader / cropping tool */