.assignment-recap-confirmation{
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assignment-recap-confirmation__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  
  padding: 0px 16px;
}

.assignment-recap-confirmation__content__success-icon {
  margin-bottom: 10px;
}

.assignment-recap-confirmation__content h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
  margin-bottom: 50px;
}

.assignment-recap-confirmation__content ion-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color:var(--ion-color-medium-shade);
  margin-bottom: 20px;
}
.assignment-recap-confirmation__content ion-text label{
  font-size: 18px;
  font-weight: 500;
}
