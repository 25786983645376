.nofitication{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 10px);
  width: 100%;
}
.nofitication__image {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.nofitication__title {
  text-align: center;
  margin: 30px 0px;
}
.nofitication__title h1 {
  font-weight: bold;
  color: var(--ion-color-dark);
}
.nofitication__description {
  text-align: center;
  font-size: 0.9rem;
  color: var(--ion-color-medium-shade);
  margin: 20px 15px;
  line-height: 22px;
}

.nofitication__buttons{
  margin-top: auto;
  margin-bottom: 10px;
  /* height: 30px; */
}

.nofitication__buttons>ion-button{
  margin-bottom: 20px;
}