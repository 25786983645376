.modal-toolbar {
    --background: white;
}

.image-wrapper-modal {
    /* The image used */
    width: 40px;
    /* height: 50px; */
    background-position: center center;
    background-size: cover;
  }

  .confirm-popup__title,
  .modal-content h4 {
    text-transform: uppercase;
    font-size: 30px;
    color: black;
  }

  .small-modal-title {
    margin: 0;
  }

  .modal-description {
    font-size: 20px;
  }

  .confirm-popup__text {
    font-size: 20px;
    color: #3a3a3a;
    line-height: 25px;
    margin: 10px 20px;
    text-align: center;
  }

  .confirm-popup__button {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    min-width: 130px;
    min-height: 30px;
    border-radius: 1px;
    border-width: 2px;;
    margin: 10px auto;
    outline: 0 none;
    white-space: nowrap;
  }

  .my-custom-class .modal-wrapper {
    width:330px;
    height: 230px;
  }
  .large-modal-wrapper .modal-wrapper{
    width: 600px;
    height: 280px;
  }

 .large-modal-wrapper .confirm-popup__buttons-wrapper {
   text-align: center;
 }

  .large-modal-wrapper.lock-modal .modal-wrapper {
    height: 540px;
    max-height: 80%;
  }

  .large-modal-wrapper.lock-modal .modal-wrapper .image-wrapper-modal {
    margin-top: 10px;
  }

  .large-modal-wrapper.lock-modal .confirm-popup__title {
    text-align: center;
    margin: 15px 0;
    text-transform: none;
    color: #1C75BC;
    font-weight: bold;
    font-size: 25px;
    max-width: 370px;
  }

  .large-modal-wrapper.lock-modal .confirm-popup__text {
    text-align: center;
    margin: 0 20px;
  }

  .large-modal-wrapper .close-button {
    position: absolute;
    right: 25px;
    top: 5px;
    cursor: pointer;
  }

  .modal-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
  }
  .modal-content div {
    text-align: center;
  }
  .modal-description{
    font-size: 16px;
    text-align: center;
    color: #6d6e71;
  }
  @media (max-width: 320px) {
  
    .my-custom-class .modal-wrapper {
      width:262px;
      height: 250px;
    }
    .large-modal-wrapper .modal-wrapper{
      width:95%;
      height: 350px;
    }
    
  }