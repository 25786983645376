.ambassador {
  display: flex;
  flex-direction: column;
  min-height: 80%;
  width: 100%;
  justify-content: center;
}
.ambassador__image {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.ambassador__title {
  text-align: center;
}
.ambassador__title h1 {
  font-weight: bold;
  line-height: 1.5 !important;
  color: var(--ion-color-dark);
}
.ambassador__title h1 span {
  color: var(--ion-color-primary);
}
.ambassador__description {
  text-align: center;
  font-size: 0.9rem;
  color: var(--ion-color-medium-shade);
  margin: 20px 15px;
  line-height: 22px;
}
.ambassador__description span {
  font-weight: bold;
  color: var(--ion-color-medium);
}

.ambassador__additional-info-text > p {
  text-align: center;
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: bold;
}

div.ambassador__additional-info {
  text-align: center;
  margin-left: -42px;
}
.ambassador__additional-info ul {
  list-style: none;
  display: inline-block;
  text-align: left;
}
.ambassador__additional-info li {
  margin-bottom: 10px;
  color: var(--ion-color-dark);
}

.ambassador__additional-info img {
  margin-right: 10px;
}
.ambassador__buttons {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  height: 20%;
  justify-content: flex-end;
}
.ambassador__buttons > ion-button {
  margin: 0px 20px 20px 20px;
}


/* #################################### ambassador form css start  */
.ambassador-form__form {
  /* padding: 20px 16px; */
}
.ambassador-form__section-1,
.ambassador-form__section-2,
.ambassador-form__section-3,
.ambassador-form__section-4 {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 87vh;
}
.ambassador-form__title h1 {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin-top: 0;
  margin-bottom: 20px;
}
.ambassador-form__description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-medium-shade);
  margin-bottom: 20px;
}

.ambassador-form__card {
  width: 100%;
  height: 58px;
  max-width: 450px;
  border-radius: 12px;
  margin: 10px 0px;
  padding: 0;
}
.ambassador-form__card__label{
  font-size: 16px ;
  font-weight: 500;
  /* margin-bottom: 6px; */
  color: var(--ion-color-medium-shade) !important;
}
.ambassador-form__buttons {
  margin-top: auto;
  padding-top: 25px;
  padding-bottom: 20px;
}

.ambassador-form__checkbox--label {
  font-weight: bold;
  color: var(--ion-color-warning-contrast) !important;
}

.ambassador-form__section-4
  .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner::after {
  content: "";
  font-size: 14px;
  position: relative;
  top: 60%;
  color: var(--ion-color-secondary-shade);
}
.ambassador-form__section-4 >form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 87vh;
}
.af__file-upload {
  display: grid;
  grid-template-columns: 115px 115px 115px;
  justify-content: space-between;
  align-items: left;
  margin-left: -6px;
}
.af__file-upload__widgets {
  width: 110px;
  height: 130px;
  position: relative;
}
.af__file-required::after {
  content: "*";
  font-size: 10px;
}
.af__file-upload-widget-label-primary {
  background-color: var(--ion-color-primary);
  color: white;
}
.af__file-upload-widget-label {
  padding: 2px 10px;
  position: absolute;
  font-family: var(--baseFontFamily);
  font-size: 12px;
  border-radius: 7px;
  top: 26px;
  text-align: center;
  z-index: 1;
  width: fit-content;
  left: 9px;
  text-align: center;
  width: 86px;
}
.af__file-upload-widget-label-secondary {
  background-color: #ffffff;
  color: var(--ion-color-medium);
  font-weight: 500;
}
.af__file-upload__additional_photo{
margin-left: 5px;
}

.af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
.af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
  position: relative;
  display: inline-block;
  margin: 16px 0 0 6px;
  padding: 0;
  vertical-align: top;
  width: 105px;
  height: 125px;
  /* padding-top: 20%; */
}
.af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input::after{
  content: "Additional";
  font-size: 12px;
  position: relative;
  top: 9px;
  left: 5px;
  color: var(--ion-color-secondary-shade);
  background-color: white;
  padding: 4px 19px;
  border-radius: 7px;
  text-align: center;
}
.af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items .fileuploader-items-list {
	text-align: left;
}
.af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input-inner i{
  top: 55%;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 25px;
}

@media (min-width: 801px) {
  .af__file-upload {
    display: inline-grid;
    grid-template-rows: 150px;
    grid-template-columns: 150px 150px 150px 150px 150px;
    grid-gap: 3px;
    justify-items: left;
  }

  
}
@media screen and (max-width: 320px) {
  .af__file-upload {
    grid-template-columns: 100px 100px 100px;
  }
  .af__file-upload__widgets {
    width: 100px;
    height: 120px;
  }
  .af__file-upload-widget-label {
    padding: 2px 5px;
    width: 83px;
  }
  .af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input::after{
    font-size: 11px;
    padding: 4px 11px;
  }
  .af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item  {
  width: 86px;
  height: 107px;
}

}
@media screen and (max-width: 295px) {
  .af__file-upload {
    grid-template-columns: 85px 85px 85px;
  }
  .af__file-upload__widgets {
    width: 85px;
    height: 105px;
  }
  .af__file-upload-widget-label {
    padding: 2px 5px;
    width: 70px;
    font-size: 10px;
  }
  .af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input::after{
    font-size: 10px;
    padding: 4px 10px;
  }
  .af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .af__file-upload__additional_photo .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
  width: 74px;
  height: 86px;
}

}
