.checkin-photo-container {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
  margin-top: 20px;
}
.checkin-photo_title h1 {
  color: var(--ion-color-dark);
  font-size: 26px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}
.checkin-photo_title ion-text {
  color: var(--ion-color-medium-tint);
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.checkin-photo__img_demo {
  text-align: center;
  margin: 20px 0px;
}

.checkin-photo__selfie ion-card {
  margin: 20px auto;
  padding: 0;
  border-radius: 12px;
  width: 300px;
  height: 350px;
}
.checkin-photo__selfie img {
  object-fit: cover;
  width: 300px;
  height: 350px;
}
.checkin-photo__submit_btn {
  position: absolute;
  bottom: 20px;
  left: 16px;
  right: 16px;
}
