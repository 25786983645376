.become-influencer {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.become-influencer__image {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.become-influencer__image img{
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
.become-influencer__title {
  text-align: center;
}

.become-influencer__title h1 {
  font-weight: bold;
  color: var(--ion-color-dark);
  line-height: 1.5 !important;
}
.become-influencer__title h1 span {
  color: var(--ion-color-primary);
}
.become-influencer__description {
  text-align: center;
  font-size: 0.9rem;
  color: var(--ion-color-medium-shade);
  margin: 20px 15px;
  line-height: 22px;
}
.become-influencer__description span {
  font-weight: bold;
  color: var(--ion-color-medium);
}
.become-influencer__additional-info-text > p {
  text-align: center;
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: bold;
}
.become-influencer__additional-info {
  text-align: center;
  display: flex;
  justify-content: center;
}

.become-influencer__additional-info ul {
  list-style: none;
  display: inline-block;
  text-align: left;
  padding: 0;
}
.become-influencer__additional-info li {
  margin-bottom: 10px;
  color: var(--ion-color-dark);
}


.become-influencer__additional-info img {
  margin-right: 10px;
}
.become-influencer__additional-info li span{
  font-size: 0.8rem;
  color: var(--ion-color-medium-tint);
  margin-left: 33px;   
}

.become-influencer__buttons {
  height: 20%;
  justify-content: flex-end;
}
.become-influencer__buttons>ion-button{
  margin: 0px 0px 10px 0px;
}

.influencer-form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 100vh; */
}

.influencer-form__title h1{
  margin: 20px 0px 20px 16px;
  font-weight: bold;
  color: var(--ion-color-dark);
}

.influencer-form ion-card{
  border-radius: 12px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid rgba(20, 0, 0, 0.12);
  margin-bottom: 9px;
}
.influencer-form ion-card ion-item{
  --min-height:60px;
}
.influencer-form img{
  background-color: var(--ion-color-light-tint);
  padding: 5px;
  border-radius: 8px;
  margin-right: 8px;
}
.influencer-form__label{
color: var(--ion-color-dark);
font-size: 16px;
vertical-align: middle;
}
.influencer-form__label-orange{
font-size: 16px;
color: var(--ion-color-primary);
vertical-align: middle;
padding: 6px 0px;

}
.influencer-form__label-green{
  font-size: 16px;
  color: var(--ion-color-success);
  vertical-align: middle;
  padding: 6px 0px;
  }

  .influencer-form__footer::before {
    background-image: none !important;
  }

  .influencer-form__footer {
    margin-top: auto;
    padding: 0px 10px 20px 10px;
  }

  .influencer-form ion-card {
    margin: 5px 20px;
  }
.influencer-form__social_icon{
  margin-right: 10px;
padding: 6px;
background: #F5F5F7;
border-radius: 6px;
}
  @media screen and (max-width: 320px){
    
    .influencer-form__label{
    margin-right: 10px;
      }
      .influencer-form__label-green,
      .influencer-form__label-orange{
        margin-left: 3px;
      }
  }
  @media screen and (max-width: 297px){
    
   }

.influencer-form__twitter-icon {
  width: 34px;
}

.influencer-form__tiktok-icon {
  width: 34px;
}