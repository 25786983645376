.push-aggrement-view{
  margin: 20px 16px;
}
.push-aggrement-view__main-header h1 {
  font-size: 26px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: var(--ion-color-dark);
}

.push-aggrement-view__header {
  margin: 15px 0px;
  font-weight: bold;
  color: var(--ion-color-dark);
  font-size: 20px;
}

.push-aggrement-view__paragraph {
  margin: 15px 0px;
  color:var(--ion-color-dark);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.push-aggrement-view__section-footer {
  margin-top: 50px;
}

.push-agreement-view__agreecheck {
  margin-top: 6px;
}

.push-aggrement-view__mobile_terms {
  display: flex;
  flex-direction: row;
}

.push-aggrement-view__mobile_terms ion-label {
  font-size: 14px;
  padding-left: 10px;
}

.push-aggrement-check_required-field {
  --border-color: #FA3D28;
}

.push-aggrement-view__paragraph__signature {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
}

.push-aggrement-view__paragraph__signature hr {
  height: 0px;
  border: 1px solid var(--ion-color-dark);
  margin-top: 3px;
  margin-bottom: 3px;
}

