.slider-card-header {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
}

.slider-card-header > ion-title {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  color: var(--ion-color-dark);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  height: 30px;
  border-radius: nullpx;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ass-slider-card {
  padding: 0px;
  margin: 0px;
}

.ass-slider-card___all-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  color: var(--ion-color-primary);
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
}
.ass-slider-card___all-item ion-icon {
  position: relative;
  top: 2px;
  font-size: 13px;
}

.ass-slider-card__title ion-icon {
  vertical-align: middle;
  margin-left: 5px;
}

.ass-slider-card.ass-slider-card--assignments-with-cta .slider_view__card {
  height: auto;
  height:348px;
} 

.ass-slider-card.ass-slider-card--assignments-with-cta .assignment-view__take_test {
  padding: 0px;
}

.ass-slider-card.ass-slider-card--assignments-with-cta .slider_view__image__content {
  justify-content: space-between;
  height: 178px;
}