.cta-slider-preview {
  word-spacing: 3px;
  padding-right: 22px;
  padding-left: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;
  overflow: hidden; 
}

.cta-slider-preview__accordion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 5px;
    column-gap: 5px;
  }
  
  .cta-slider-preview__colapse-icon-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .cta-slider-preview__action-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .cta-slider-preview__colapse-icon-container ion-icon,
  .virtual_casting__container__bottom-right ion-icon {
    font-size: 19px
  }
  
  .cta-slider-preview__action-items__centering-placeholder {
    width: 19px;
  }
  
  .virtual_casting__container {
    width:100%;
    position: relative;
    text-align: center;
    overflow: hidden;
    border-radius: 12px;
  }

  .virtual_casting__container .virtual_casting__container__banner {
    width:100%;
  }
 

  .virtual_casting__container__bottom-right {
    position: absolute;
    bottom: 11px;
    right: 16px;
    display: flex;
  }

  .virtual_casting__container__centered {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .virtual_casting__container__bottom-right img {
    width: 25px;
  }


  .virtual_casting__container__centered ion-button {
    --padding-top: 10px !important;
    --padding-bottom: 10px !important;
    font-weight: normal;
  }

  .cta-slider-preview__buttons ion-button {
    font-weight: normal;
  }

  .virtual_casting {
    margin-bottom: 15px;
  }

  .cta-slider-preview,
  .virtual_casting__container {
    max-width: 500px;
  }

  #home__cta-slider .swiper-wrapper,
  #home__cta-slider .virtual_casting__container,
  #home__cta-slider .cta-slider-preview {
     aspect-ratio: 2/1;
  }

  @media (max-width: 380px) {
    #home__cta-slider .cta-slider-preview {
      padding: 15px;
   }
  }

  @media (max-width: 350px) {
    #home__cta-slider .cta-slider-preview {
      padding: 10px;
   }
  }