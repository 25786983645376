.new-referral {
  padding: 20px 16px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
}
.new-referral__title {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin-top: 0;
  margin-bottom: 16px;
}
.new-referral__text {
  font-size: 14px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
color: #575757;
margin-bottom: 24px;
}
.new-referral__searchbar {
  --background: white;
  --box-shadow: 0px 2px 4px 0px rgba(55, 18, 0, 0.04);
  border: 1px solid #e9ebf0;
  border-radius: 6px;
  height: 45px;
  width: 100%;
  margin-top: 20px;
}
.new-referral__items__list ion-item:not(.new-referral__items__list_contact-item) {
  --padding-top: 8px;
  --padding-bottom: 8px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 12px;
  box-shadow: 0px 2px 4px rgba(55, 18, 0, 0.04);
  border-radius: 12px;
  border: 1px solid #E9EBF0;
  font-weight: normal;
}

.new-referral__items__list_contact-first-letter {
  padding-left: 20px;
  color: var(--ion-color-light-shade);
}

.new-referral__items__list_contact-item label {
  font-weight: normal;
}

.new-referral__items__list_contact-item ion-checkbox {
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 0px;
}

.new-referral__items__list_detail__points-already-push-account {
  text-align: right;
  
}

.new-referral__items__list_detail__points-already-push-account span {
  color:white;
  background-color: var(--ion-color-medium-tint);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 7px;
 }

.new-referral__items__list ion-list-header:not(.new-referral__items__list_contact-first-letter) {
  padding-left: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  margin-bottom: 8px;
  margin-top: 12px;
}
.new-referral__items__list__img {
  height: 48px;
  width: 48px;
}
.new-referral__items__list_detail h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark);
  margin: 0;
}
.new-referral__items__list_detail ion-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-primary);
}

.new-referral__submit_btn {
  margin: 0px 16px 20px 16px
}

.new-referral__submit_btn.new-referral__submit_btn--signup {
  margin: 0px 20px 10px 20px;
}

.new-referral__footer.new-referral__footer--signup {
  position: absolute;
bottom: 10px;
}

.new-referral__items__list__header-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.new-referral__items__list__header-container ion-list-header {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: auto;
}

.new-referral__items__list__header-container .new-referral__items__list__header-container__earn-points,
.new-referral-contact__items__list__header-container__earn-points {
  margin-bottom: 8px;
  margin-top: 12px;
  padding: 5px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: #FE6102;
  background-color:#ffeeea;
  height: fit-content;
}

@media (max-width: 400px) {
  .new-referral__items__list__header-container__hide-on-small-width {
    display: none;
  }
}

.new-referral__items__list_detail__points-text {
  color: #FE6102;
}
.new-referral__items__list__no-contact__refresh {
  display: flex;
  justify-content: center;
  align-content:center;
  align-items: center;
  font-weight: bold;
  margin-top: 16px;
}

.new-referral__items__list__no-contact__refresh ion-icon {
  margin-left: 5px;
}


ion-modal.external-talent-referral-options{
  --ion-background-color: transparent;
}

.external-talent-referral-options__share-referral-link {
  margin-bottom: 15px;
}

.external-talent-referral-options__heading {
  margin-bottom: 20px;
}

.external-talent-referral-options-modal-background {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ),url(../../../assets/images/refer-friend/referral-page-photo.jpg);; /* Adjust the path according to your project structure */
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

.external-talent-referral-options__stars-icon {
  width: 85px;
}

.external-talent-referral-options * {
  color:white;
}

ion-modal.external-talent-referral-options ion-toolbar {
  --background: transparent;
}

.external-talent-referral-options ion-button {
  --border-color: white !important;
}

.external-talent-referral-options__offer-until {
  height: 16px;
  margin-bottom: 25px;
  margin-top:15px;
}

.application-confirm__content{
  height: 100%;
  display: flex;
  align-items: center;
}
.opportunities-survey__application-confirm {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  padding: 0px 16px;
  margin-bottom: 50px;
}

.opportunities-survey__success-icon {
  margin-bottom: 10px;
}

.opportunities-survey__application-confirm h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
  margin-bottom: 10px;
}

.opportunities-survey__application-confirm ion-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color:var(--ion-color-medium-shade);
  margin-bottom: 20px;
}
.opportunities-survey__application-confirm ion-text label{
  font-size: 18px;
  font-weight: 500;
}

.opportunities-survey__refer-friend {
  width: 250px;
  --border-radius: 12px;
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
.opportunities-survey__refer-friend-text {
  width: 250px;
}


