.assign-decline__upload-btn .file-upload-add-more {
    width: 200px;
  /* max-width: 170px; */
    margin-top:8px
    /* height: 88px;
    color: var(--ion-color-dark);
    font-weight: bold;
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
    border-radius: 12px;
    border: 1px solid var(--ion-color-light-shade); */
}
.assign-decline__upload-btn .file-upload-add-more .fileuploader {
  /* padding: 0px; */
  /* margin-top: 24px; */
  /* margin-bottom: 10px; */
  height: 88px;
  background: white;
  border-radius: 12px;
  border: 1px solid var(--ion-color-light-shade);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important; 
}
.assign-decline__upload-btn .file-upload-add-more .fileuploader-input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
}

.assign-decline__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-caption {
  margin-right: 0px;
  align-self: center !important;
  box-shadow: none;
  border: 0px;
  padding: 0px 5px;
}

.assign-decline__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button {
  background: white;
  background-position-x: 0%;
  background-position-y: 0%;
  background-position: center;
  color: #136c9e;
  box-shadow: none;
  padding: 0px 5px !important;
}

.assign-decline__upload-btn
  .fileuploader-items
  .fileuploader-item
  .fileuploader-action-remove {
  color: var(--ion-color-primary);
}

.assign-decline__upload-btn
  .fileuploader-items
  .fileuploader-item
  .fileuploader-action.fileuploader-action-remove:hover {
  background-color: transparent;

}

.assign-decline__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button
  span {
  display: none;
}
.assign-decline__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button::after {
  content: "Upload Photo or Video";
  color: var(--ion-color-dark);
  font-weight: bold;
}

.assign-decline__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-caption
  span {
  display: none;
}
.assign-decline__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-caption::after {
  content: url("../../../assets/images/common/cloud-upload.png");
  align-self: center !important;
  padding: 0px !important;
  border: 0px;
}

.assign-decline__upload-btn .fileuploader-items .fileuploader-items-list {
  margin: 20px 0px 0px 0px  !important;
  
}



.assign-decline__upload-btn .fileuploader-items .fileuploader-item {

    position: relative;
    margin: 0;
    padding: 10px 5px 10px 0px;
    border-bottom: 1px solid #ebeef1;
    animation-duration: .6s;
    width: auto;

}
.assign-decline__upload-btn .fileuploader-items .fileuploader-item .column-title{
  flex: 1 1;
  padding-left: 8px;
  padding-right: 0px;
  color: #74809d;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.assign-decline__upload-btn .fileuploader-items .fileuploader-item .column-thumbnail{
  position: relative;
    width: 32px;
    height: 32px;
}
.assign-decline__upload-btn .fileuploader-items .fileuploader-item .column-title div {
	width: 100%;
	/* font-weight: 700; */
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 11px;
}
.assign-decline__upload-btn .fileuploader-items .fileuploader-item .column-actions {
	margin: 0px;
}
.assign-decline__upload-btn .fileuploader-items {
  height: 158px;
  overflow: scroll;
  position: absolute;
  margin-top: 20px;
  z-index: 11;
}

/* css start for UploadEventPhotosWithoutRecap  and AssignmentDecline.tsx*/
.assign-decline__upload-btn ion-card {
  /* max-width: 170px; */
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-dark);
  font-weight: bold;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  border: 1px solid var(--ion-color-light-shade);
  cursor: pointer;
}
.assign-decline__upload-btn ion-card img {
  width: 24px;
}
.assign-decline__upload-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}
.add_min_height{
 min-height: 295px;
}

.assign-decline_take-camera {
  width: 230px;
}
.assign-decline_photo-items {
  height: 158px;
  overflow: scroll;
  position: absolute;
  width: 160px;
  text-align: right;
}
.assign-decline_photo-items .img_file {
  width: 32px;
  margin-right: 5px;
  border-radius: 5px;
  height: 32px;
  object-fit: fill;
}
.assign-decline_photo-items .img_name {
 
  margin-right: 5px;
  width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;

}

.assign-decline_photo-items .remove-color {
  color: var(--ion-color-primary);
}
.assign-decline_photo-list {
  float: right;
  right: 30px;
  margin-bottom: 10px;
  position: relative;
}
.assign-decline_camera-img {
  position: relative;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
  bottom: 8px;
}
.assign-decline_camera-img button {
  background-color: transparent;
}
@media screen and (max-width: 320px) {
  .assign-decline__upload-btn .fileuploader-items {
    margin-top: 40px;
  }
 
}
@media screen and (max-width: 290px) {
 
  .assign-decline__upload-btn .file-upload-add-more .fileuploader-input {
    margin-top: 0px;
  }
}
/* css end for  UploadEventPhotosWithoutRecap and AssignmentDecline.tsx*/