.profile-header {
  --background: url(../../assets/images/profile-view/img/BG.png) no-repeat;
  background: linear-gradient(180deg, #131218 0%, #141118 100%);
  width: 100%;
  color: var(--ion-color-light);
}

.profile-view {
  background-image: url(../../assets/images/profile-view/img/BG.png);
  background: linear-gradient(180deg, #131218 0%, #141118 100%);
  min-height: 100%;
  min-width: 100%;
}
.profile-view__container {
  padding: 20px 16px;
}
.rating-star {
  font-size: 20px;
  margin: 0px;
  margin-right: 5px;
}
.rating-highlight {
  color: var(--ion-color-primary) !important;
}
.profile-header__like_btn {
  height: 32px;
  width: 66px;
  border-radius: 16px;
  padding: 7px 12px 5px 12px;
  border: 1px solid #ffffff;
  background: transparent;
  color: var(--ion-color-primary);
  display: flex;
  column-gap: 5px;
  align-content: center;
  margin-right: 8px;
}
.profile-header__share_btn {
  height: 32px;
  width: 44px;
  top: 56px;
  border-radius: 16px;
  border: 1px solid #ffffff;
  padding: 6px 12px 6px 12px;
  background: transparent;
  color: #ffffff;
  margin-right: 8px;
}
.profile-header__icon {
  font-size: 20px;
  text-align: left;
  margin-top: -3.5px;
}

.profile-level__talent_info {
  text-align: center;
}
.profile-level__talent_info__img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: block;
  margin: 20px auto 10px auto;
}
.profile-level__talent_info__img img {
  height: 90px;
  width: 90px;
  display: block;
  border: 2px solid var(--ion-color-primary);
  padding: 2px 2px 2px 2px;
}
.profile-level__talent_info__statu {
  background-color: var(--ion-color-primary);
  color: white;
  height: 22px;
  width: 48px;
  border-radius: 6px;
  padding: 2px 6px 2px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  z-index: 99;
  position: relative;
  display: inline-block;
  bottom: 16px;
}
.profile-level__talent_info__name {
  --color: var(--ion-color-danger-contrast);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  padding: 0;
}
.profile-level__talent_info__level {
  color: var(--ion-color-light-shade);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  display: block;
}
.profile-level__talent_info__location {
  color: var(--ion-color-light-shade);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  display: inline-block;
  height: 32px;
  width: 156px;
  border-radius: 16px;
  padding: 6px 12px 6px 12px;
  border: 1px solid #ffffff;
}
.profile-level__talent_info__location ion-icon {
  font-size: 16px;
  margin-right: 5px;
}

.model__age-height-hire {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.model__age-height-hire__hire,
.model__age-height-hire__age,
.model__age-height-hire__height {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.model__age-height-hire__hire,
.model__age-height-hire__height {
  border-left: 1px solid #9d9fa647;
}

.age-height-hire__number {
  color: var(--ion-color-light);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  /* text-align: center; */
}
.age-height-hire__text {
  color: var(--ion-color-medium-shade);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  /* text-align: center; */
}

.booked-me-btn {
  --border-radius: 12px;
  margin: 6px 0px;
}
.quater-result {
  margin-top: 20px;
}
.quater-result__qauter,
.quater-result__qauter ion-col {
  padding: 0;
}
.quater-result__qauter__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-light);
  position: relative;
  bottom: 8px;
}
.quater-result__qauter__left_sep {
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(to left, rgb(81 81 81) 1%, rgb(0 0 0 / 2%) 70%)
    100% 0 100% 0/3px 0 3px 0 stretch;
}
.quater-result__qauter__right_sep {
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(to right, rgb(81 81 81) 1%, rgb(0 0 0 / 2%) 70%)
    100% 0 100% 0/3px 0 3px 0 stretch;
}
.quater-result__grid {
  margin-top: 16px;
}
.talent-measurements__grid ion-col {
  padding-left: 0;
}

.quater-result__grid__right_col {
  padding-right: 0;
}
.quater-result__grid__left_col {
  padding-left: 0;
}
.quater-result__grid ion-grid {
  padding: 0;
}
.quater-result__grid__card {
  background-color: transparent;
  height: 98px;
  width: 167px;
  border-radius: 16px;
  border: 1px solid var(--ion-color-medium-shade);
  margin: 0;
  padding: 0px 0px 0px 20px;
}
.quater-result__grid__card__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-light);
  margin-top: 12px;
  margin-bottom: 6px;
  margin-left: -5px;
}

.quater-result__grid__card__num h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-light);
  margin: 0px;
}

.quater-result__grid__card__lable {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-medium-shade);
}
.talent-review__user_feedback__user,
.talent-review,
.talen-assign-photos,
.talen-videos,
.talen-crew,
.talent-interest,
.talent-about,
.talent-experiences,
.talent-measurements,
.talen-photos {
  margin-top: 20px;
}
.talent-review__user_feedback__user,
.talent-review ion-item,
.talen-assign-photos ion-item,
.talen-videos ion-item,
.talen-crew ion-item,
.talent-interest ion-item,
.talent-about ion-item,
.talent-experiences ion-item,
.talent-measurements ion-item,
.talen-photos ion-item {
  --background: transparent;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-start: 0;
  --border-color: transparent;
}
.talent-review__title,
.talen-assign-photos__title,
.talen-videos__title,
.talen-crew__title,
.talent-interest__title,
.talent-about__title,
.talent-experiences__title,
.talent-measurements__title,
.talen-photos__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-light);
  margin: 0;
}
.talen-assign-photos__see_all,
.talen-videos__see_all,
.talen-crew__see_all,
.talen-photos__see_all {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  color: var(--ion-color-primary);
}
.talen-photos__images img {
  object-fit: cover;
  max-height: 140px;
  max-width: 140px;
  min-height: 140px;
  min-width: 140px;
  border-radius: 12px;
  /* height: 100%; */
}

.talen-photos__videos video {
  object-fit: fill;
  height: 140px;
  max-width: none;
  border-radius: 12px;
}

.talen-photos__videos .plyr.plyr--video {
  background: transparent;
  background-color: transparent;
}

.talen-photos__videos .plyr--video .plyr__video-wrapper {
  background: transparent;
  background-color: transparent;
}

.talen-photos__videos .plyr--video .plyr__controls {
  background: transparent;
  background-color: transparent;
}

.talen-photos__images {
  width: 100%;
}

.talent-measurements ion-grid {
  padding: 0;
}

.talent-measurements__grid__item {
  display: flex;
  justify-content: space-between;
  height: 44px;
  /* width: 171px; */
  border-radius: 6px;
  background: linear-gradient(90deg, #201e24 0%, #16141a 100%);
  color: var(--ion-color-light);
  border-left: 3px solid var(--ion-color-primary);
  margin: 0;
  padding: 0px 5px;
}
.talent-measurements__grid__item ion-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-medium) !important;
}
.talent-measurements__grid__item p {
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: right;
  color: var(--ion-color-light);
}
.talent-experiences__name h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-light);
  margin-bottom: 6px;
  margin-top: 10px;
}
.talent-experiences__name ion-text {
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-medium);
}
.exp-dot {
  padding: 0px 5px;
}
.talent-about__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-light-tint);
}
.talent-interest ion-badge {
  --background: transparent;
  --color: var(--ion-color-medium);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  margin-right: 6px;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}
.talen-crew__images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.talen-crew__image {
  min-height: 124px;
  min-width: 124px;
  height: 124px;
  width: 124px;
  border-radius: 50%;
  object-fit: cover;
}
.talen-crew__image_label {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-light);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.talen-crew__image img {
  min-height: 124px;
  min-width: 124px;
  height: 124px;
  width: 124px;
  border-radius: 50%;
  object-fit: cover;
}
.talen-videos__thumnail {
  height: 92px;
  width: 140px;
  min-height: 92px;
  min-width: 140px;
  border-radius: 12px;
}
.talen-videos__thumnail img {
  height: 92px;
  width: 140px;
  min-height: 92px;
  min-width: 140px;
  border-radius: 12px;
  object-fit: cover;
}
.talen-assign-photos__images {
  height: 140px;
  width: 140px;
  min-height: 140px;
  min-width: 140px;
  left: 20px;
  border-radius: 12px;
}
.talen-assign-photos__images img {
  height: 140px;
  width: 140px;
  min-height: 140px;
  min-width: 140px;
  left: 20px;
  border-radius: 12px;
  object-fit: cover;
}

.talent-review__overview {
  height: 140px;
  width: 100%;
  max-width: 400px;
  border-radius: 16px;
  margin: 0;
  border: 1px solid var(--ion-color-medium);
  background: transparent;
}
.talent-review__overview ion-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.talent-review__overview__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-light);
  margin: 6px 0px;
}
.talent-review__overview__total_rating {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-light) !important;
}
.talent-review__user_feedback__user__name ion-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-light);
}
.talent-review__user_feedback {
  border-bottom: 1px solid var(--ion-color-medium);
  padding-bottom: 10px;
}
.talent-review__user_feedback__user__name__rating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.talent-review__user_feedback__user__name__rating .rating-star {
  font-size: 14px !important;
  margin: 0px;
  margin-right: 5px;
  height: 16px;
  line-height: 16px;
}
.talent-review__user_feedback__user__name__rating ion-label {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-light) !important;
  height: 16px;
}
.talent-review__user_feedback__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-light);
}
.talent-review__user_feedback__user ion-avatar ion-icon {
  position: relative;
  z-index: 99;
  bottom: 22px;
  left: 22px;
  font-size: 16px;
}
.talent-review__more {
  width: 100%;
  text-align: center;
  line-height: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.talent-review__more ion-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 20px;
  letter-spacing: 0px;
  color: var(--ion-color-primary);
}
.talent-review__more ion-icon {
  font-size: 14px;
  padding-top: 4px;
  margin: 0px 5px;
}
.profile_photo_slider {
  --height: 80vh;
  --background: var(--ion-color-dark);
  padding: 0 16px;
}
.profile_photo_slider ion-content {
  --background: transparent;
}

.profile_photo_slider_image img {
  max-height: 80vh;
  max-width: 100%;
  min-height: 80vh;
  min-width: 100%;
  object-fit: contain;
}
.profile_photo_slider_icon {
  position: relative;
  z-index: 999;
  top: 6px;
  left: 94%;
}
@media screen and (max-width: 320px) {
  .quater-result__grid__card {
    width: 140px;
    padding: 0px 0px 0px 12px;
  }
  .quater-result__grid__card__title {
    font-size: 15px;
  }
  .talen-photos__images img {
    min-height: 133px;
    min-width: 120px;
  }
  .talen-photos__videos video {
    height: 133px;
    max-width: none;
  }
  .talen-crew__image img {
    min-height: 110px;
    min-width: 110px;
    height: 110px;
    width: 110px;
  }

  .talen-videos__thumnail {
    width: 100px;
    min-width: 100px;
  }
  .talen-videos__thumnail img {
    width: 100px;
    min-width: 100px;
  }

  .talen-assign-photos__images {
    height: 133px;
    width: 120px;
    min-height: 133px;
    min-width: 120px;
  }
  .talen-assign-photos__images img {
    height: 133px;
    width: 120px;
    min-height: 133px;
    min-width: 120px;
  }
  .talent-review__overview {
    height: 134px;
  }
  .talent-review__overview__title {
    font-size: 26px;
    line-height: 36px;
  }
  .rating-star {
    font-size: 18px;
  }
}
@media screen and (max-width: 290px) {
  .quater-result__grid__card {
    width: 120px;
    padding: 0px 0px 0px 5px;
  }
  .quater-result__grid__card__num h2 {
    font-size: 18px;
  }
  .quater-result__grid__card__title {
    font-size: 14px;
  }
  .talen-photos__images img {
    min-height: 120px;
    min-width: 108px;
  }
  .talen-photos__videos video {
    height: 120px;
    max-width: none;
  }

  .talen-crew__image img {
    min-height: 100px;
    min-width: 100px;
    height: 100px;
    width: 100px;
  }
  .talen-videos__thumnail {
    height: 80px;
    min-height: 80px;
    width: 80px;
    min-width: 80px;
  }
  .talen-videos__thumnail img {
    height: 80px;
    min-height: 80px;
    width: 80px;
    min-width: 80px;
  }

  .talen-assign-photos__images {
    height: 120px;
    width: 108px;
    min-height: 120px;
    min-width: 108px;
  }
  .talen-assign-photos__images img {
    height: 120px;
    width: 108px;
    min-height: 120px;
    min-width: 108px;
  }

  .talent-review__overview {
    height: 124px;
  }

  .talent-review__overview__title {
    font-size: 24px;
    line-height: 30px;
  }
  .talent-measurements__grid__item ion-label {
    font-size: 13px;
    line-height: 25px;
  }
  .talent-measurements__grid__item p {
    padding-right: 3px;
    font-size: 11px;
    line-height: 36px;
  }
}

.profile-view__container .talent-intro-video-container .plyr.plyr--video {
  box-shadow: 0px 0px 7px 3px rgba(255,255,255,1);
} 

.profile-view__container .talent-intro-video-container .plyr.plyr--video video {
  box-shadow: 0px 0px 18px 10px rgba(255,255,255,1);
} 

