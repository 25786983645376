.referal_content {
  padding: 20px 16px;
}
.referal_content ion-grid {
  padding: 0px;
}
.referal_col__left {
  padding: 0px 8px 8px 0px;
}
.referal_col__right {
  padding: 0px 0px 8px 8px;
}
.referal_card {
  margin: 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
}
.referal_card img{
  height: 80px;
  width: 80px;
  margin-bottom: 16px;
}
.referal_card ion-text{
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--ion-color-dark);
    
}
