@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  src: url("fa-regular-400.eot");
  src: url("fa-regular-400.eot?#iefix") format("embedded-opentype"),
       url("fa-regular-400.woff2") format("woff2"),
       url("fa-regular-400.woff") format("woff"),
       url("fa-regular-400.ttf") format("truetype"),
       url("fa-regular-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;  
  font-weight: 900;
  src: url("fa-solid-900.eot");
  src: url("fa-solid-900.eot?#iefix") format("embedded-opentype"),
       url("fa-solid-900.woff2") format("woff2"),
       url("fa-solid-900.woff") format("woff"),
       url("fa-solid-900.ttf") format("truetype"),
       url("fa-solid-900.svg#fontawesome") format("svg");
}
.fa,
.far {
  font-family: 'FontAwesome';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fas {
  font-family: 'FontAwesome';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}
.fa-xs {
  font-size: .75em;
}
.fa-sm {
  font-size: .875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-cog:before {
  content: "\f013";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-comments:before {
  content: "\f086";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-th:before {
  content: "\f00a";
}
.fa-times:before {
  content: "\f00d";
}
.fa-user:before {
  content: "\f007";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-search:before {
  content: "\f002";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-home:before {
  content: "\f015";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-camera:before {
  content: "\f030";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-phone:before {
  content: "\f095";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-check:before {
  content: "\f00c";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-download:before {
  content: "\f019";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-bars:before {
  content: "\f0c9";
}

.fa-reply:before {
    content:"\f3e5"
}

.fa-reply-all:before {
    content:"\f122"
}
.fa-newspaper:before {
    content: "\f1ea";
}
.fa-comment:before {
    content: "\f075";
}

.fa-map-marker-alt:before {
    content: "\f3c5";
}
.fa-phone-alt:before {
    content: "\f879";
}
.fa-lock:before {
    content: "\f023";
}