.form-forgot {
  flex: 0 0 50%;
}
.subtitle {
  font-family: "Prata", Sans-serif;
  color: #1c75bc;
  font-size: 2rem;
  text-align: center;
  margin: 1rem 3rem 3rem;
}

.login-sub-text-5 {
  color: var(--ion-color-secondary);
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
}

@media (max-width: 768px) {
  .login-sub-text-5 {
    display: block;
  }
}

.forgot-main-content {
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.login-headings__login-text {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  line-height: 20px;
  font-weight: 500;
}

.login__section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-headings__login-header {
  font-weight: bold;
}

.login__section__inputs{
  margin-top: 15px;
  margin-bottom: 15px;
}