.assign-recap-container {
  padding: 20px 20px;
  min-height:100% ;
  
  
}
.recap__welcome-text h1 {
  font-weight: bold;
  font-size: 28px;
  word-spacing: 5px;
  color: var(--ion-color-dark);
}
.recap__welcome-text ion-text {
  color: var(--ion-color-medium-shade);
  font-size: 15px;
  line-height: 24px;
  margin-top: 18px;
}
.recap__welcome-text ion-text span {
  font-weight: bold;
  color: var(--ion-color-dark);
  font-size: 15px;
}
.recap__event__details {
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 12px;
}
.recap__event__details ion-badge {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-dark);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 5px;
}
.recap__event__details ion-badge span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 3px;
}
.recap__event__details h2 {
  font-weight: 700;
  color: var(--ion-color-dark);
  font-size: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.recap__event__details ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
}
.recap__event__details ion-item img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 15px;
}
.assignment-view__event__status ion-item ion-text {
  font-size: 18px;
  color: var(--ion-color-dark);
}
.recap-test__info {
  padding-top: 16px;
  padding-bottom: 16px;
}
.recap-test__info__border {
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.recap-test__info ion-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: var(--ion-color-dark);
  padding-inline-start: 0px;
  padding-bottom: 13px;
}
.recap-test__status {
  padding-bottom: 13px;
}
.recap-test__status ion-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--ion-color-medium-shade);
}
.recap-test__status .fa_icon_left {
  color: #2ed573;
  padding-left: 10px;
}
.recap-test__info ion-button {
  --padding-start: 3em;
  --padding-end: 3em;
  --border-radius: 6px;
  margin-left: 0px;
  /* --padding-top:8px; */
  /* --padding-buttom:8px; */
}
.assign-recap-container__btn {
  margin-top: auto;
}

/* css for assignment recap test (AssignmentRecapTest.tsx) */
.assign-recap-content {
  /* margin-top: 20px; */
}
.assign-recap-content__title {
  font-size: 20px;
  font-weight: 700;
  color: var(--ion-color-dark);
  padding-inline-start: 0px;
  padding-bottom: 13px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}
/* css for assignment recap receipts (AssignmentRecapReceipts.tsx) */
.upload-receipt-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--ion-color-medium-shade);
}
.assign-recap__from {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.recap-test__field-required {
  color: var(--ion-color-primary);
}

.upload-receipt .file-upload-add-more .fileuploader-input {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-dark);
  font-weight: bold;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
  border: 1px solid var(--ion-color-light-shade);
  cursor: pointer;
  padding: 16px;
}
.file-upload-add-more .fileuploader-input .fileuploader-input-caption {
  flex: inherit;
}
.file-upload-add-more .fileuploader-input .fileuploader-input-button::after {
  content: "Upload Receipts";
  color: var(--ion-color-dark);
  font-weight: bold;
}
.assign-recap-content__event_photos{
  margin-left: 10px;
}
.assign-recap-content__event_photos
  .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input,
.assign-recap-content__event_photos
  .fileuploader-theme-thumbnails
  .fileuploader-items-list
  .fileuploader-item {
  position: relative;
  display: inline-block;
  margin: 0px 0 0 6px;
  margin-bottom: 6px;
  padding: 0;
  vertical-align: top;
  width: 105px;
  height: 125px;
}

.assign-recap-content__event_photos
  .fileuploader-theme-thumbnails
  .fileuploader-items
  .fileuploader-items-list {
  text-align: left;
}
.assign-recap-content__event_photos
  .fileuploader-theme-thumbnails
  .fileuploader-thumbnails-input-inner
  i {
  top: 50%;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 25px;
}
.assign-recap-receipt-container {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 20px;
}
.assign-recap-container .assign-recap__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  
}
.assign-recap__input_label {
  background: #e9ebf070;
  color: #8e93a2;
  height: 43px;
  line-height: 43px;
  width: 80px;
  text-align: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: 14px;
  position: absolute;
  right: 0px;
  top: 27px;
}

.recap-test__scondary {
  --background: var(--ion-color-light-shade);
  --color: var(--ion-color-dark);
}
.assign-recap-content__exsting_img {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  column-gap: 5px;
  row-gap: 5px;
  margin-bottom: 15px;
}
.assign-recap-content__exsting_img .react_exsting_image {
  max-width:none;
  height: 125px;
  border-radius: 12px;
  position: relative;
}
.assign-recap-content__exsting_img .react_exsting_image img,
.assign-recap-content__exsting_img .react_exsting_image video{
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.assign-recap-content__exsting_img .react_exsting_image ion-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  z-index: 999;
}

@media screen and (max-width: 320px) {
  .assign-recap-content__event_photos
    .fileuploader-theme-thumbnails
    .fileuploader-thumbnails-input,
  .assign-recap-content__event_photos
    .fileuploader-theme-thumbnails
    .fileuploader-items-list
    .fileuploader-item {
    width: 86px;
    height: 107px;
  }
}
@media screen and (max-width: 295px) {
  .assign-recap-content__event_photos
    .fileuploader-theme-thumbnails
    .fileuploader-thumbnails-input,
  .assign-recap-content__event_photos
    .fileuploader-theme-thumbnails
    .fileuploader-items-list
    .fileuploader-item {
    width: 74px;
    height: 86px;
  }
}

.recap-test__info__receipt-not-uploadable {
  font-style: italic;
  color: var(--ion-color-medium-shade);
}

.upload-receipt__error-message.error-message { 
  padding-left: 0px;
}

.assign-recap-content__exsting_img .plyr--video {
  max-width: none;
  height: 125px;
  border-radius: 12px;
  position: relative;
}

.assign-recap-container .assign-recap__upload-btn .file-upload-add-more {
  margin-top: 0px;
}

.assign-recap-container .assign-recap__upload-btn .fileuploader {
  margin: 0px;
  height: auto;
  border: 0;
  box-shadow: unset !important;
  padding: 0;
}

.assign-recap-container .assign-recap__upload-btn .assign-decline_take-camera ion-card {
  margin-top: 0px;
}

.assign-recap-container
.assign-recap__upload-btn
  .file-upload-add-more
  .fileuploader-input
  .fileuploader-input-button::after {
  content: "Upload Photo";
  color: var(--ion-color-dark);
  font-weight: bold;
}

.assign-recap-container
.assign-recap__upload-btn
 .fileuploader-items {
  height: auto;
  position: static;
  margin-top: 0;
 }

.assign-recap-container .assign-recap__upload-btn .file-upload-add-more .fileuploader-input {
  margin: 0;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid var(--ion-color-light-shade);
}

.assign-recap-container .assign-recap__upload-btn .assign-decline_photo-items {
  position: static;
  height: auto;
  text-align: left;
  margin-left: 0px;
  width: auto;
}

.assign-recap-container .assign-recap__upload-btn .assign-decline_take-camera ion-card {
  margin: 0;
}

.assign-recap-container .assign-recap__upload-btn .assign-decline_take-camera ion-label {
  font-weight: bold;
}

.assign-recap-container .assign-recap__upload-btn .assign-decline_photo-list {
  float: none;
  position: static;
  padding: 9px 5px 8px 0px;
  margin: 0;
  border-bottom: 1px solid #ebeef1;
}

.assign-recap-container .assign-recap__upload-btn.assign-decline__upload-btn {
  column-gap: 15px;
}

.assign-recap-content__fileupload-container {
  padding-top:15px;
}

.assign-recap-container .assign-recap__upload-btn.assign-decline__upload-btn .file-upload-add-more,
.assign-recap-container .assign-recap__upload-btn.assign-decline__upload-btn .assign-decline_take-camera {
  width: auto;
  flex-basis: 50%;
  flex-shrink: 1;
}

.assign-recap-container .assign-recap__upload-btn .file-upload-add-more .fileuploader-input .fileuploader-input-button {
  padding: 0 !important;
}

.assign-recap-container .assign-recap__upload-btn ion-card {
  height: 85px;
}


.assign-recap-container .assign-recap__upload-btn .fileuploader-items .fileuploader-item .column-title div,
.assign-recap-container .assign-recap__upload-btn .assign-decline_photo-items .img_name {
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 75px;
}

.assign-recap-container .assign-recap__upload-btn .assign-decline_photo-items,
.assign-recap-container .assign-recap__upload-btn .fileuploader-items .fileuploader-items-list
{
  margin-top: 0px !important;
}

.assign-recap-content__bottom-section {
  display: flex;
  flex-direction: column;
  height: 20%;
  justify-content: flex-end;
  margin-top: 30px;
}

.assign-recap-container {
  display: flex;
  flex-direction: column;
}

.assign-recap-container .assign-recap-container__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.assign-recap-container .assign-recap-container__form > .assign-recap-container__form__question {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}


/* .assign-recap-container .assign-recap__upload-btn.assign-decline__upload-btn .file-upload-add-more .fileuploader-input {
  padding: 0;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-start;
}

.assign-recap-container .assign-recap__upload-btn.assign-decline__upload-btn .file-upload-add-more {
  width: auto;
} */

.assign-recap-container .take-test-question{
  border-top: 0;
}

/* .assign-recap-container .assign-recap__upload-btn {
  display: block !important;
} */

.assign-recap-container .assign-recap-content__title { 
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.assign-recap__upload-btn--required .fileuploader-input::after {
  content: "*";
  color: var(--ion-color-primary);
  font-size: 24px;
}

.assign-recap-content__fileupload-description--required .assign-recap-content__fileupload-description__description::after {
  content: " *";
  color: var(--ion-color-primary);
}



.assign-recap-container .assign-decline__upload-btn .file-upload-add-more .fileuploader-input .fileuploader-input-caption {
  padding-left: 0px;
}

.assign-recap-content__footer-description,
.assign-recap-content__fileupload-description {
  color: var(--ion-color-medium-shade);
}

.assign-recap-content__fileupload-container__label,
.assign-recap-container__form__question__head-label {
  font-weight: bold;
}

.assign-recap-content__bottom-section__buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  grid-column-gap: 16px;
  column-gap: 16px;
}

.assign-recap-content__bottom-section__buttons ion-button {
  --padding-start: 1.5rem;
  --padding-end: 1.5rem;
  width: 100%;
}

.assign-recap-content__bottom-section__buttons__exit {
  background: #fff0e6;
  color: var(--ion-color-primary);
  padding: 5px 8px;
  border-radius: 10px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  height: 44px;
}

ion-content.assign-recap{
  --keyboard-offset: 0 !important;
}

.assign-recap-content__fileupload-container__example-container img {
  max-height: 75px;
  max-width:100%;
  width: auto;
}