.lock_field_card {
  height: 300px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin: 16px 0px;
  padding: 20px 16px;
}
.lock_field_card > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lock_field_card__text {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
}
.lock_field_card__btn {
  width: 100%;
  margin-top: auto;
}
