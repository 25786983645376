.experience-header__header-item {
    background-color: white;
    color: black;
    padding: 20px 0px 0px 0px;
    justify-content: space-between;
  }
  
  .experience-header-item__header-toolbar-title {
    font-weight: 500;
    text-align: center;
    margin: 0px;
    padding: 0px;
    font-weight: bold;
  }
  
  /* .experience-container{
    --overflow: auto !important;
  } */
  
  .experience-container__title {
    font-size: 26px;
    font-weight: bold;
    margin: 10px 0px;
    font-family: var(--baseFontFamily);
  }
  
  .experience-container {
   padding: 20px 16px;
   height: 100%;
  }
  
  .experience-container__content {
    color: var(--ion-color-medium-tint);
    margin: 5px;
    font-size: 16px;
  }
  
  .experience-container__txt-area::placeholder {
    color: #9e9e9eb5;
  }
  
  .experience-container__txt-area {
    width: 100%;
    height: 250px;
    margin: 20px 0px;
    border-radius: 20px;
    padding: 15px 20px;
    font-size: 21px;
    color: #9e9e9eb5;
    border: none;
    box-shadow: rgb(214 214 214) 0 0 11px;
  }
  
  .experience__btn-submit {
    --background: #fa3d28;
    margin-bottom: 10px !important;
  }
  .experience-footer{
      padding: 10px 20px;
  }

.experience__submit {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}