

.slider_view__card {
  margin-left: 0;
  margin-right: 0;
  border-radius: 12px;
  height: 300px;
}
.slider_view__image__content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.slider_view__image {
  width: 100%;
}
.slider_view__date {
  background-color: var(--ion-color-primary);
  border: 3px solid var(--ion-color-light) !important;
  border-radius: 6px;
  color: var(--ion-color-light) !important;
  position: absolute;
  top: -15px;
  height: 28px;
  line-height: 10px;
  padding: 6px 10px 6px 10px;
  
}

.slider_view__points {
  background-color: #ffeeea;
  border: 3px solid var(--ion-color-light) !important;
  border-radius: 6px;
  color: var(--ion-color-primary) !important;
  position: absolute;
  top: -15px;
  height: 28px;
  line-height: 10px;
  padding: 6px 10px 6px 10px;
  right: 16px;
  text-transform: uppercase;
}

.slider_view__points__img {
  position: absolute;
  right: 0;
  width: 40px;
  top: -35px;
  right: -16px;
}

.slider_view__title {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0px;
	text-align: left;
	color: var(--ion-color-dark);
	margin-top: 10px;
	margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.slider_view__address,
.slider_view__cta {
font-size: 14px;
font-weight: 400;
letter-spacing: 0px;
text-align: left;
color: var(--ion-color-medium-shade);

}

.slider_view__cta--lesser-line-height {
  line-height: 1 !important;
}

.slider_view__est_payment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 13px;
}

.slider_view__est_payment ion-text {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: left;
}

.slider_view__est_payment ion-text {
  color: var(--ion-color-primary) !important;
}

.slider_view__est_payment label {
  background: #FFF0E6;
  border-radius: 6px;
  color: var(--ion-color-primary);
  font-weight: normal;
  padding: 3px 6px;
  display: inline-flex;
}
.slider_view__est_payment label img {
  margin-right: 3px;
  width: 14px;
}


.slider_view__no_item_found{
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

 @media screen and (max-width: 280px){
  .slider_view__date{
    left: 0px;
  }

  .slider_view__points {
    right: 0px;
  }
 }

 .slider_view__card__toolbar-bottom {
  position: absolute;
  bottom: 12px;
  right: 10px;
  z-index: 99;
  background: transparent;
}

.slider_view__card__toolbar-bottom__label {
  background: white;
  color: var(--ion-color-primary);
  padding: 5px;
  font-weight: bold;
  border: 2px solid var(--ion-color-primary);
  float: right;
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary);
  --padding-bottom: 8px;
  --padding-end: 14px;
  --padding-start: 10px;
  --padding-top: 8px;
  border-radius: 8px;
}

.slider_view .assignment-view__action_btn ion-button{
  margin-top: 0px;
  margin-bottom: 0px;
}