.small_modal_container {
  --height: 350px;
  --width: 320px;
  --border-radius: 12px;
  overflow: hidden;
}

.small_modal__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.small_modal__content__img {
    margin-top: 16px;
    margin-bottom: 16px;
  background-position: center center;
  background-size: cover;
}
.small_modal__content__text{
font-size: 20px;
font-weight: 600;
line-height: 32px;
letter-spacing: 0px;
text-align: center;
color: var(--ion-color-dark);

}
.small_modal__content__btn{
    width: 100%;
    margin-top: auto;
}