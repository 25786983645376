.assign-decline__header__back {
  color: black;
}
.assign-decline {
  padding: 20px 16px;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  position: relative;
  height: 100%;
}

.assign-decline__submit-btn {
  margin-top: auto;
}

.assign-decline__confirm {
  margin-top: 0px;
  margin-bottom: 16px;
}
.assign-decline__confirm h1 {
  font-weight: bold;
  font-size: 28px;
  word-spacing: 5px;
  color: var(--ion-color-dark);
  margin-bottom: 20px;
  margin-top: 0px;
}
.assign-decline__confirm ion-text,
.assign-decline__verification ion-text,
.assign-decline__confirm-text ion-text,
.assign-decline__checkbox ion-text
 {
  color: var(--ion-color-medium-shade);
  font-size: 14px;
}
.assign-decline__reason {
  margin-bottom: 23px;
}
.assign-decline__vrification {
  margin-top: 16px;
  margin-bottom: 16px;
}
.assign-decline__verification ion-label,
.assign-decline__reason ion-label,
.assign-decline__checkbox ion-label {
  color: black;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.assign-decline__checkbox ion-label {
  padding-left: 10px;
}

.assign-decline__cancel-confirm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 100%;
  padding: 20px 16px;
}
.assign-decline__sadFace-icon {
  margin-bottom: 10px;
  margin-top: 30%;
}
.assign-decline__confirm-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--ion-color-dark);
}
.assign-decline__confirm-subTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--ion-color-medium-shade);
}
.assign-decline__confirm-subTitle label {
  font-size: 18px;
}
.assign-decline_view-scroeboard-btn {
  margin-top: 40px;
  width: 60%;
}

@media screen and (max-width: 320px) {
 
  .assign-decline__confirm h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 290px) {
  .assign-decline {
    height: 118vh;
  }
  .assign-decline__confirm h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
 
}

.assign-decline__confirm-text {
  /* margin-top is equal to the fileuploader view photos max height */
  margin-top: 158px;
  margin-bottom: 23px;
}

.assign-decline__checkbox {
  display: flex;
  flex-direction: row;
}




.assign-decline_camera-img {
  position: relative;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
  bottom: 8px;
}
.assign-decline_camera-img button {
  background-color: transparent;
}
