.assignment-view__header {
  max-height: 500px;
  overflow: hidden;
}


.assignment-view__header .assignment-view__header__toolbar {
  z-index: 99;
  position: absolute;
  top: 0;
}
.assignment-view__header img {
  max-width: 100%;
  border: 0;
  width: 100vw;
  object-fit: cover;
}
.assignment-view__header__toolbar__back-btn{
  background: white;
  border-radius: 50px;
}
.assignment-view__header__toolbar__menu-btn{
  --padding-start:0px;
  --padding-end:0px;
}
.assignment-view__header__toolbar__menu-btn img{
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.assignment-view__header .assignment-view__header__toolbar-bottom {
  z-index: 99;
  position: absolute;
  bottom: 5px;
}

.assignment-view__header__toolbar-bottom__label {
  background: white;
  border-radius: 10px;
  color: var(--ion-color-primary);
  padding: 5px;
  font-weight: bold;
  border: 2px solid var(--ion-color-primary);
  float: right;
}

.assignment-view__notice__timing__pay {
  --color: var(--ion-color-primary) !important;
  font-size: 18px !important;
  font-weight: bold;
}

.assignment-view__notice__backup-bonus-placeholder {
  width: 56px;
  height: 56px;
  margin-right: 32px;
}

.assignment-view__notice__timing__backup-bonus-container {
  display: flex !important;
  align-items: center;
  column-gap: 5px;
}

.assignment-view-content{
  margin-left: 16px;
  margin-right: 16px;
}
.assignment-view-content ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
}
.assignment-view-content ion-text{
  color: var(--ion-color-medium-shade);
}
.assignment-view__event{
  padding-top: 0px !important;
}
.assignment-view__event__booked ion-badge {
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-dark);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
}
.assignment-view__event__booked ion-badge span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 1px;
  margin-right: 3px;
}
.assignment-view__event__name h2 {
  font-weight: bold;
  --color: var(--ion-color-dark);
  font-size: 20px;
}
.assignment-view__event__status img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}
.assignment-view__event__status ion-text {
  font-size: 18px;
  color: var(--ion-color-dark);
}
.assignment-view__notice ion-card {
  --background: #fff0e6;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  padding:12px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
}
.assignment-view__notice ion-title {
  font-weight: 700;
  color: var(--ion-color-primary);
  padding-inline-start: 0;
  padding-bottom: 10px;
}
.assignment-view__notice ion-text {
  color: var(--ion-color-primary);
  margin-bottom: 15px;
}
.assignment-view__notice__btns{
  
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  
}
.assignment-view__notice__btns ion-button {
  --padding-start: 2em;
  --padding-end: 2em;
}
.assignment-view__notice__timing{
  margin-bottom: 15px;
}
.assignment-view__notice__timing__date {
  font-weight: bold;
  margin-bottom: 10px;
  --color: var(--ion-color-dark-shade) !important;
}
.assignment-view__notice__timing__time {
  --color: var(--ion-color-medium) !important;
}

.assignment-view__notice__timing__points-multiplied {
  width: 56px;
  height: auto;
}

.assignment-view__notice__link{
  margin-left: 87px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.assignment-view__notice__link a{
 text-decoration: none;
 font-weight: bold;
}
.assignment-view-content .assignment-view__take_test,
.assignment-view__event,
.assignment-view__notice,
.assignment-view__accpets,
.assignment-view__types,
.assignment-view__attirs,
.assignment-view__location,
.assignment-view__medical-req,
.assignment-view__parking,
.assignment-view__meeting,
.assignment-view__contact,
.assignment-view__contact,
.assignment-view__expect,
.assignment-view__doing,
.assignment-view__photos,
.assignment-view__recap,
.assignment-view__modelcheckin,
.assignment-view__cancel-assignment
{
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--ion-color-light-shade);
}
.assignment-view__accpets{
  display: flex;
  justify-content: space-between;
}
.assignment-view__accpets ion-button{
  --padding-start: 3.2em;
  --padding-end: 3.2em;
}
.assignment-view__break_resume {
  --padding-start: 1.5em !important;
  --padding-end: 1.5em !important;
}
.assignment-view__types ion-title,
.assignment-view__attirs ion-title,
.assignment-view__medical-req ion-title,
.assignment-view__location ion-title,
.assignment-view__parking ion-title,
.assignment-view__meeting ion-title,
.assignment-view__contact ion-title,
.assignment-view__contact ion-title,
.assignment-view__expect ion-title,
.assignment-view__doing ion-title,
.assignment-view__photos ion-title,
.assignment-view__recap ion-title,
.assignment-view__cancel-assignment ion-title {
  font-weight: bold;
  --color: var(--ion-color-dark);
  padding-left: 0px;
  margin-left: 0xp;
}
.assignment-view__types ion-badge {
  --color: var(--ion-color-primary-contrast);
  --padding-bottom: 8px;
  --padding-end: 13px;
  --padding-start: 13px;
  --padding-top: 8px;
  border-radius: 6px;
}
.assignment-view__photos_each{
  width: 100%;
  margin-top: 16px;
}
.assignment-view__photos_each img,
.assignment-view__photos_each video {
  object-fit: fill;
  height: 140px;
  max-width: none;
  border-radius: 12px;

  /* height: 100%; */
}

.assignment-view__location__img img{
  width: 100%;
  object-fit: cover;
}
.assignment-view__location__add ion-text{
  display: block;
}
.assignment-view__location__add ion-item{
  /* margin-top: 16px; */
}
.assignment-view__location__add ion-item img{
  margin-right: 10px;
}
.assignment-view__location__add  a{
  text-decoration: none;
  font-weight: bold;
}
.assignment-view__parking ion-item img{
  margin-right: 10px;
}
.assignment-view__parking  a{
  text-decoration: none;
  font-weight: bold;
}
.assignment-view__meeting ion-item img{
  margin-right: 10px;
}
.assignment-view__meeting  a{
  text-decoration: none;
  font-weight: bold;
}

.assignment-view__contact .contact__name{
  font-weight: bold;
  --color: var(--ion-color-dark-shade) !important;
  margin-bottom: 10px;
}
.assignment-view__contact .contact__no{
  --color: var(--ion-color-medium) !important;
}
.assignment-view__doing__download{
  color: var(--ion-color-primary);
  position: relative;
}
.assignment-view__doing__doc img{
  margin-right: 10px;
}
.assignment-view__submit-btn{
  margin-top: 20px;
  margin-bottom: 16px;
}
.assignment-view__requrement__read_more {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--ion-color-primary);
}
.assignment-view__upload_photos{
  margin-top: 16px;
  --color:var(--ion-color-dark);
  --border-color:#E9EBF0;
}
@media screen and (max-width: 320px){
  .assignment-view__accpets ion-button{
    --padding-start: 2.5em;
    --padding-end: 2.5em;
  }  
  .assignment-view__break_resume {
    --padding-start: 0.8em !important;
    --padding-end: 0.8em !important;
  }
  .assignment-view__photos_each img,
  .assignment-view__photos_each video {
    min-height: 133px;
    min-width: 120px;
  }
}
@media screen and (max-width: 295px){

.assignment-view__notice__btns ion-button {
  --padding-start: 1.5em;
  --padding-end: 1.5em;
}
.assignment-view__accpets ion-button{
  --padding-start: 1.5em;
  --padding-end: 1.5em;
}
.assignment-view__break_resume {
  --padding-start: 0.5em !important;
  --padding-end: 00.5em !important;
}
.assignment-view__photos_each img,
.assignment-view__photos_each video {
  min-height: 120px;
  min-width: 108px;
}
}

.assignment-view__videos__heading {
  margin-top: 15px;
}

.assignment-view__photos__receipts-not-uploadable {
  font-style: italic;
}

.assignment-view__failed-test {
  color: #FA3D28;
  font-weight: bold;
}

.assignment-view__p-card-required-bttn {
  margin-top: 10px;
  width: 100%;
}

.assignment-view__p-card-completed-icon { 
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.assignment-view__p-card-completed {
  display: flex;
}

.booked-talent-referral-link__title {
  font-size: 20px;
  color: var(--ion-color-primary);
  font-weight: bold;
  padding-inline-start: 0;
  padding-bottom: 10px;
}

.booked-talent-referral-link__link {
  text-decoration: underline;
}

.assignment-view__photos .plyr .plyr--video {
  background: white;
} 

.assignment-view__photos .plyr--video .plyr__video-wrapper {
  background: white;
}

.assignment-view__cancel-assignment__cancel-button-container {
  width: 90%;
  margin: 0 auto;
}

.assignment-view__cancel-assignment__cancel-button-container__button {
  width: 100%;
  --background: var(--ion-color-light-shade);
  --color: var(--ion-color-dark);
}