.assign-confirm__header__back {
  color: black;
}
.assign-confirm {
  margin-left: 16px;
  margin-right: 16px;
}
.assign-confirm__event-info {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--ion-color-light-shade);
}
.assign-confirm__event-info div h1 {
  font-weight: bold;
  font-size: 28px;
  word-spacing: 5px;
  color: var(--ion-color-dark);
}
.assign-confirm__event-info ion-grid,
.assign-confirm__event-info ion-col {
  padding-left: 0px;
  padding-right: 0px;
}
.assign-confirm__event-info ion-grid ion-label {
  color: var(--ion-color-medium-shade);
  padding: 0px 10px 0px 0px;
}
.assign-confirm__event-info ion-grid ion-text {
  color: var(--ion-color-dark);
  /* font-weight: bold; */
}

.assign-confirm__event-container {
  max-width: fit-content;
}

.assign-confirm__talent-role,
.assign-confirm__event-container__event_name,
.assign-confirm__backup-instructions,
.assign-confirm__event-container__multiday-date-times {
  padding-top: 5px;
}

.assign-confirm__event-container__multiday-date-times__date-time {
  margin-bottom: 2px;
}

.assign-confirm__backup-instructions-label {
  border-radius: 8px;
  color: var(--ion-color-primary);
  font-weight: bold;
  border: 2px solid var(--ion-color-primary);
  text-transform: uppercase;
  padding-right: 2px;
  padding-left: 2px;
  margin-left: 10px;
}

.assign-confirm__checkboxs {
  padding-top: 16px;
  padding-bottom: 16px;
}
.assign-confirm__checkboxs__note,
.assign-confirm__event-datetime__note {
  color: var(--ion-color-dark);
  font-weight: bold;
}
.assign-confirm__checkboxs ion-item {
  --padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  color: var(--ion-color-dark);
}
.assign-confirm__checkboxs ion-item> div {
  width: 25px;
  max-width: 25px;
}
.assign-confirm__note ion-card {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) !important;
  --background: var(--ion-color-light-tint);
  --color: var(--ion-color-medium);
}
.assign-confirm__note ion-card ion-card-content {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-inline-start: 13px;
  padding-inline-end: 13px;
}

.assign-confirm__submit-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
}
.assign-confirm__submit-btn ion-button {
  --padding-start: 3.2em;
  --padding-end: 3.2em;
}
.assign-confirm__event-datetime__date {
  position: relative;
  z-index: 99;
  top: 69px;
  transform: translatez(0);
  width: 100%;
}
.assign-confirm__event-datetime .form-group{
  margin-top: -15px;
  margin-bottom: 0px;
}

.assignment-view__p-card-required-bttn {
  margin-top: 10px;
  width: 100%;
}

.assignment-view__p-card-completed-icon { 
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.assignment-view__p-card-completed {
  display: flex;
}

.assign-confirm__event-container__auto-book-img {
  height: 23px;
  width: auto;
}