.input-type__checkbox {
  accent-color: var(--ion-color-primary);
  width: 21px;
  height: 21px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-medium-tint);
}

.take-test-question{
  border-top: 1px solid var(--ion-color-light-shade);
}

.input-type-percentage__optionlist{
  margin-top: 15px;
}
.input-type-percentage__optionlist-child{
  margin: 10px 0px;
}
.input-type-range__measurement{
  width: 100%;
}

.input-type-range__range-input{
  --knob-background: var(--ion-color-primary);
  --bar-border-radius: 6px;
  --bar-height: 8px;
  --bar-background: #fe61024a;
  padding: 0px;
}

.input-type-range__label{
  color: var(--ion-color-medium-tint) !important;
    margin-top: 19px;
    text-overflow: auto !important;
    overflow: visible !important;
}

.input-type-range__range-input .range-knob {
  border-radius:none !important;
}

.input-type-range__options {
  display: flex;
  justify-content: space-between;
}

.input-type-counter__value {
  border: 1px solid var(--ion-color-primary);
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.input-type-counter__buttons { 
  margin: 0px;
}

.input-type-counter__value,
.input-type-counter__buttons {
  height: 45px;
}

.input-type-counter__buttons {
  --border-radius: 0px
}

.input-type-counter__buttons--decrease button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-type-counter__buttons--increase button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.take-test-question__label { 
  margin-bottom: 15px;
  font-weight: bold;
}

.take-test-question__label:not(.take-test-question__label--blank)::after {
  content: " *";
  color: var(--ion-color-primary);
}

.input-type-counter__value--disabled {
  opacity: 0.5;
}

.take-test-question__radio__container,
.take-test-question__checkbox__container {
  margin-bottom: 15px;
}

.take-test-question__radio__container,
.take-test-question__checkbox__container {
  display: flex;
  column-gap: 15px;
  align-content: center;
  align-items: center;
}

.take-test-question__radio__container ion-radio {
  min-width: 20px;
}