.my-profile-card {
  border-radius: 12px;
  margin: 20px 16px;
  padding: 0;
}
.my-profile-card__talent_info {
  text-align: center;
}
.my-profile-card__talent_info__img {
  width: 90px;
  height: 90px;
  display: block;
  margin: 20px auto 10px auto;
}
.my-profile-card__talent_info__img img {
  object-fit: cover;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: block;
  border: 2px solid var(--ion-color-primary);
  padding: 2px;
}
.my-profile-card__talent_info__statu {
  background-color: var(--ion-color-primary);
  color: white;
  height: 22px;
  border-radius: 6px;
  padding: 2px 6px 2px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  z-index: 99;
  position: relative;
  display: inline-block;
  bottom: 16px;
}
.my-profile-card__talent_info__name {
  --color: var(--ion-color-dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  padding: 0;
}
.my-profile-card__talent_info__level {
  color: var(--ion-color-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
}

.my-profile-card__profile_items{
  margin: 0px 16px;
}
.my-profile-card__profile_item {
  --border-color: var(--ion-color-light-tint);
  margin-bottom: 12px;
  --inner-padding-end:0;
  --padding-start:0;
}
.my-profile-card__profile_item img {
  margin-right: 16px;
}

.my-profile-card__profile_item__refer {
  width: 44px;
  min-width: 44px;
  height: 44px; 
  background: #F5F5F7;
  margin-right: 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.my-profile-card__profile_item__refer img {
  margin: 0 auto;
}

.my-profile-card__profile_item ion-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--ion-color-dark-shades);
}
.my-profile-card__profile_item ion-icon {
  font-size: 14px;
}

.generateIcon {
    font-size: 20px !important;
    color: black;
    border: #f5f5f5 0px solid;
    background: #f5f5f5;
    border-radius: 7px;
    padding: 12px;
    margin: 0px 0px 5px 0px;
}

.generateLabel {
  margin-left: 12px !important;
  margin-bottom: 5px !important;
}

.profile_item__no_border{
  --border-color: white;
}

.my-profile-card__profile_item .my-profile-card__profile_item__earn-points {
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: #FE6102;
  background-color:#ffeeea;
  font-size: 13px;
}

.my-profile-card__profile_item__refer__img {
  width: 44px;
  height: 44px;
}

@media (max-width: 320px) {
  .my-profile-card__profile_item .my-profile-card__profile_item__earn-points {
    display: none;
  }
}

.my-profile-card__profile_item__intro-video {
  width: 44px;
  height: 44px;
}