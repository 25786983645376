.your_location {
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(240, 240, 240, 0.25),0px 2px 20px rgba(169, 169, 169, 0.24);
  border-radius: 12px;
  height: 48px;
  width: 100%;
  position: relative;
}

.your_location__text{
margin-left: 8px;
margin-top: -8px;

}
.your_location_text__placeholder{
font-size: 12px;
font-weight: 400;
letter-spacing: 0px;
color: #8E93A2;
display: block;
}
.your_location_text__location_name{
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color:var(--ion-color-dark);
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.your_location__icon{
  position: absolute;
  top: 8px;
}
.chat_notification,
.notifcation_bell{
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.chat_notification__noti_badge,
.notifcation_bell__noti_badge {
  background: #2ed573;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  top: 12px;
  right: 12px;
}
