.redeem_point__card {
  /* height: 244px; */
  width: 264px;
border-radius: 12px;
margin: 0px 0px 10px 0px;
position: relative;
height: 300px;

}

.redeem_point__card_img-container {
  position: relative;
  max-height: 300px;
  min-height: 220px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.redeem_point__card_img {
  margin: 0 auto;
  object-fit: cover;
  max-height: 220px;
}
.redeem_point__card_badge {
  background-color: var(--ion-color-primary);
  height: 28px;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  color: var(--ion-color-primary-contrast);
  line-height: 16px;
  font-size: 13px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
}

.faStar-icon {
  margin-right: 5px;
}
.redeem_point__card_title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: var(--ion-color-dark);
  margin-bottom: 5px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.redeem_point__card_text{
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--ion-color-medium-shade);
}
.redeem_point__card_btn{
  font-size: 14px;
  width: fit-content;
  color: var(--ion-color-primary);
  font-weight: bold;
  cursor: pointer;
}


.redeem_point__card--sold-out ion-card-content {
  opacity: 0.5 !important;
}

.redeem_point__card_img-container--sold-out  *:not(.my_reward_list__card_img__sold-out) {
  opacity: 0.5 !important;
}

.redeem_point__card_img__sold-out {
  position: absolute;
  top: 25%;
  left: 0;
  pointer-events: none; /* Prevents the overlay from blocking interactions with the product */
}

.redeem_point__card_btn.redeem_point__card_btn--disabled{
  color: #7f7f7f !important;
}