.paid-revenue-container {
  padding: 0px 16px 20px 16px;
}
.paind-revenue-card {
  padding: 0;
  margin: 20px 0px 0px 0px;
  /* height: 88px; */
  border-radius: 12px;
  /* box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12) */
}
.paind-revenue-card ion-grid {
  --ion-grid-padding: 0;
}
.paind-revenue-card ion-col {
  /* --ion-grid-column-padding: 0; */
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
}
.paind-revenue-card__dates h1 {
  color: var(--ion-color-dark);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 24px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: 'break-spaces';
  width: 250px;
}
.paind-revenue-card__dates__date,
.paind-revenue-card__dates__time {
  --color: var(--ion-color-medium-tint);
  font-size: 13px;
  line-height: 18px;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 160px;
}
.paind-revenue-card__amounts h1 {
  color: var(--ion-color-primary);
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.paind-revenue-card__amounts__date,
.paind-revenue-card__amounts__amount {
  --color: var(--ion-color-medium-tint);
  font-size: 13px;
  line-height: 18px;
  display: block;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
 
}
@media screen and (max-width: 320px) {
  .paind-revenue-card__dates h1 {
    width: 180px;
  }
  .paind-revenue-card__dates__date,
  .paind-revenue-card__dates__time {
    width: 130px;
    font-size: 11px;
  }
  .paind-revenue-card__amounts__date,.paind-revenue-card__amounts__amount{
    width: 115px;
    font-size: 11px;
  }
}
@media screen and (max-width: 295px) {
  .paind-revenue-card__dates h1 {
    width: 150px;
  }
  .paind-revenue-card__dates__date,
  .paind-revenue-card__dates__time {
    width: 110px;
    font-size: 11px;
  }
  .paind-revenue-card__amounts__date,.paind-revenue-card__amounts__amount{
    width: 110px;
    font-size: 11px;
    text-align: left;
  }
}
